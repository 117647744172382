import { FC, useEffect, useState } from "react";

import { FolderIcon } from "@heroicons/react/24/solid";
import { ConfirmationDialog, EditableHeading, Paragraph, useToast, Heading } from "@hightouchio/ui";
import { useParams, useNavigate } from "react-router-dom";
import { Text } from "theme-ui";

import { DraftCircle } from "src/components/drafts/draft-circle";
import { EditingDraftWarning } from "src/components/drafts/draft-warning";
import { isQueryDraft } from "src/components/drafts/model-draft";
import { MoveFolder } from "src/components/folders/move-to-folder";
import { useFolder } from "src/components/folders/use-folder";
import { EditLabels } from "src/components/labels/edit-labels";
import { Labels } from "src/components/labels/labels";
import { Page } from "src/components/layout";
import { Crumb } from "src/components/layout/header/breadcrumbs";
import { OverageModal } from "src/components/modals/overage-modal";
import { SaveWarning } from "src/components/modals/save-warning";
import { ColumnSelect } from "src/components/models/column-select";
import { ColumnSettings } from "src/components/models/column-settings";
import { Query } from "src/components/models/query";
import { Syncs } from "src/components/models/syncs";
import { Permission } from "src/components/permission";
import { ResourceActivityTimeline } from "src/components/resource-activity/timeline";
import { DisplaySlug } from "src/components/slug/display-slug";
import { Warning } from "src/components/warning";
import { DraftProvider, useDraft } from "src/contexts/draft-context";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  MinimalModelQuery,
  ModelDraft,
  ModelQuery,
  ResourcePermissionGrant,
  ResourceToPermission,
  useDeleteModelMutation,
  useMinimalModelQuery,
  useModelQuery,
  useUpdateModelMutation,
} from "src/graphql";
import useHasPermission from "src/hooks/use-has-permission";
import * as analytics from "src/lib/analytics";
import { Avatar } from "src/ui/avatar";
import { SquareBadge } from "src/ui/badge";
import { Row, Column } from "src/ui/box";
import { Button } from "src/ui/button";
import { Field } from "src/ui/field";
import { ChevronDownIcon, LookerIcon, PlusIcon } from "src/ui/icons";
import { PageSpinner } from "src/ui/loading";
import { Message } from "src/ui/message";
import { Popout } from "src/ui/popout";
import { Tabs } from "src/ui/tabs";
import { useIncrementalQuery } from "src/utils/incremental-query";
import { QueryType, useModelState } from "src/utils/models";
import { SourceTile } from "src/utils/sources";
import { formatDate } from "src/utils/time";

import { useLabels } from "../../components/labels/use-labels";
import { modelActivityMappers } from "./model-activity";
import { useModelSort } from "./use-model-sort";

enum Tab {
  QUERY = "Query",
  CONFIGURATION = "Configuration",
  SYNCS = "Syncs",
  COLUMNS = "Columns",
  ACTIVITY = "Activity",
}

export const ModelWrapper: FC = () => {
  const { model_id: id } = useParams<{ model_id?: string }>();
  const { columnsOrderBy, syncsOrderBy } = useModelSort();

  const fullModelQuery = useModelQuery({ id: id ?? "", syncsOrderBy, columnsOrderBy }, { enabled: Boolean(id) });

  const incrementalModels = useIncrementalQuery<MinimalModelQuery, ModelQuery>(
    useMinimalModelQuery(
      {
        id: id ?? "",
      },
      {
        enabled: Boolean(id),
      },
    ),
    fullModelQuery,
  );

  if (!id) {
    return <PageSpinner />;
  }

  return (
    <DraftProvider
      initialResourceIsDraft={incrementalModels.data?.segments_by_pk?.draft || false}
      resourceId={id}
      resourceType={ResourceToPermission.Model}
    >
      <Model
        modelData={incrementalModels.data}
        modelLoading={incrementalModels.isFetching}
        refetchModel={incrementalModels.fullQueryRefetch}
      />
    </DraftProvider>
  );
};

interface ModelProps {
  modelData: Partial<ModelQuery> | undefined;
  modelLoading: boolean;
  refetchModel: () => void;
}

const Model: FC<ModelProps> = ({ modelData, modelLoading, refetchModel }: ModelProps) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { user, workspace } = useUser();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [tab, setTab] = useState<Tab>(Tab.QUERY);
  const { modelState, setPrimaryKey, initModelState } = useModelState();
  const [tableLoading, setTableLoading] = useState(false);
  const [isEditLabelModalOpen, setIsEditLabelModalOpen] = useState(false);
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);

  const {
    setSubmitDraftModalOpen,
    updateResourceOrDraft,
    editingDraft,
    draft,
    editingDraftChanges,
    mergeResourceWithDraft,
    setEditingDraft,
    onViewDraft,
  } = useDraft();
  const [model, setModel] = useState<ModelQuery["segments_by_pk"] | undefined>();

  const { columnsOrderBy, curriedOnSort, syncsOrderBy } = useModelSort();

  const id = modelData?.segments_by_pk?.id;

  const { labels } = useLabels();

  const { mutateAsync: updateModel, isLoading: updating } = useUpdateModelMutation();
  const { mutateAsync: deleteModel } = useDeleteModelMutation();

  const currentLabels = model?.tags ?? {};
  const labelKeys = Object.keys(currentLabels);
  const type = model?.query_type;
  const syncs = model?.syncs;
  const source = model?.connection;

  const draftResource = draft?.new_resource ? (draft.new_resource as ModelDraft) : undefined;
  const columns = editingDraft ? draftResource?.modelColumns ?? model?.columns ?? [] : model?.columns ?? [];

  useEffect(() => {
    const model = modelData?.segments_by_pk;
    if (draft && model) {
      const copy = mergeResourceWithDraft(model);
      setModel(copy as ModelQuery["segments_by_pk"]);
      initModelState(copy);
    } else {
      setModel(model);
      initModelState(model);
    }
  }, [modelData, editingDraft]);

  const onUpdate = () => {
    analytics.track("Model Updated", {
      model_id: model?.id,
      model_type: type,
      model_name: model?.name,
      source_id: source?.id,
      source_type: source?.type,
    });

    toast({
      id: "update-model",
      title: "Model was updated",
      variant: "success",
    });

    refetchModel();
  };

  const [name, setName] = useState(model?.name ?? "");

  useEffect(() => {
    setName(model?.name ?? "");
  }, [model?.name]);

  const updatePermission = useHasPermission(
    workspace?.approvals_required
      ? undefined
      : [{ resource: "model", grants: [ResourcePermissionGrant.Update], resource_id: model?.id }],
  );

  const updateName = async () => {
    if (!model) {
      return;
    }

    await updateModel({
      id: model.id,
      input: {
        name,
        updated_by: user?.id != null ? String(user?.id) : undefined,
      },
    });

    onUpdate();
  };

  const updateLabels = async (labels: Record<string, string | number>) => {
    if (!id) {
      return;
    }

    try {
      await updateModel({
        id: id,
        input: {
          tags: labels,
        },
      });

      onUpdate();
      setIsEditLabelModalOpen(false);
    } catch (error) {
      toast({
        id: "update-model-labels",
        title: "Couldn't update labels",
        message: error.message,
        variant: "error",
      });
    }
  };

  const update = async () => {
    if (!model) {
      return;
    }

    const updatePayload = {
      primary_key: modelState?.primaryKey,
      updated_by: user?.id != null ? String(user?.id) : undefined,
      // we null the draft id, it gets added on the backend and we want to be consistent
      // if a workspace turns off approvals again =
      approved_draft_id: null,
    };

    updateResourceOrDraft &&
      (await updateResourceOrDraft(
        { _set: updatePayload },
        onUpdate,
        async () => {
          await updateModel({
            id: model?.id,
            input: updatePayload,
          });
        },
        model.draft,
      ));
    setSubmitDraftModalOpen(true);
  };

  const folder = useFolder({ folderId: model?.folder?.id, folderType: "models", viewType: "models" });

  const dirty = modelState?.primaryKey !== model?.primary_key;

  useEffect(() => {
    setTableLoading(true);
  }, [columnsOrderBy, syncsOrderBy]);

  useEffect(() => {
    setTableLoading(false);
  }, [model]);

  const hasPrimaryKeyIssue = Boolean(model?.columns?.length && !model?.columns.some((c) => c.name === model?.primary_key));

  const TABS = [
    editingDraft && draftResource?._set && isQueryDraft(draftResource?._set)
      ? {
          render: () => (
            <Row sx={{ alignItems: "center" }}>
              Query <DraftCircle sx={{ ml: 2 }} />
            </Row>
          ),
          value: Tab.QUERY,
        }
      : Tab.QUERY,
    editingDraft && draftResource?._set?.primary_key
      ? {
          render: () => (
            <Row sx={{ alignItems: "center" }}>
              Configuration <DraftCircle sx={{ ml: 2 }} />
            </Row>
          ),
          value: Tab.CONFIGURATION,
        }
      : Tab.CONFIGURATION,
    {
      render: () => (
        <Row sx={{ alignItems: "center" }}>
          <Text>Syncs</Text>
          {Array.isArray(syncs) && syncs.length > 0 && <SquareBadge sx={{ ml: 2 }}>{syncs.length}</SquareBadge>}
        </Row>
      ),
      value: Tab.SYNCS,
    },
    {
      render: () => (
        <Row sx={{ alignItems: "center" }}>
          <Text>Activity</Text>
        </Row>
      ),
      value: Tab.ACTIVITY,
    },
    editingDraft && draftResource?.modelColumns
      ? {
          render: () => (
            <Row sx={{ alignItems: "center" }}>
              Columns <DraftCircle sx={{ ml: 2 }} />
            </Row>
          ),
          value: Tab.COLUMNS,
        }
      : Tab.COLUMNS,
  ].filter(Boolean);

  if (modelLoading) {
    return <PageSpinner />;
  }

  if (!modelLoading && (!modelData || !modelData.segments_by_pk)) {
    return <Warning subtitle="It may have been deleted" title="Model not found" />;
  }
  const updatedByUsername = model?.updated_by_user?.name || model?.created_by_user?.name;

  const crumbs: Crumb[] = [{ label: "Models", link: "/models" }];

  if (folder?.path) {
    folder.path.split("/").forEach((path) => {
      crumbs.push({
        label: path,
        link: `/models?folder=${folder.id}`,
      });
    });
  }

  crumbs.push({
    label: model?.name ?? "",
  });

  return (
    <>
      <PermissionProvider
        permissions={
          workspace?.approvals_required
            ? []
            : [{ resource: "model", grants: [ResourcePermissionGrant.Update], resource_id: model?.id }]
        }
      >
        <Page
          crumbs={crumbs}
          outsideTopbar={
            draft && (
              <EditingDraftWarning
                draft={draft}
                editingDraft={editingDraft}
                resourceType={ResourceToPermission.Model}
                setEditingDraft={setEditingDraft}
                onViewDraft={onViewDraft}
              />
            )
          }
          title={`${modelData?.segments_by_pk?.name ?? "Unnamed model"} - Models`}
        >
          <Column sx={{ width: "100%", height: "100%" }}>
            <Column sx={{ mb: 6 }}>
              <Row sx={{ alignItems: "center", justifyContent: "space-between" }}>
                <EditableHeading
                  isDisabled={!updatePermission.hasPermission}
                  size="lg"
                  value={name}
                  onChange={setName}
                  onSubmit={updateName}
                />

                <Row gap={2} sx={{ alignItems: "center", ml: 8 }}>
                  <Permission permissions={[{ resource: "model", grants: [ResourcePermissionGrant.Delete] }]}>
                    <Button
                      label="Delete"
                      variant="secondary"
                      onClick={() => {
                        setDeleteModal(true);
                      }}
                    />
                  </Permission>
                  <Permission permissions={[{ resource: "model", grants: [ResourcePermissionGrant.Create] }]}>
                    <Button
                      label="Clone"
                      variant="secondary"
                      onClick={() => {
                        navigate(`/models/${model?.id}/clone`);
                      }}
                    />
                  </Permission>
                  <Permission permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Create] }]}>
                    <Button variant="secondary" onClick={() => navigate(`/syncs/new?model=${id}`)}>
                      Add sync
                    </Button>
                  </Permission>
                </Row>
              </Row>
              <Row sx={{ mt: 2 }}>
                <Row sx={{ alignItems: "center", mr: 4, pr: 4, borderRight: "small" }}>
                  {source && <SourceTile iconSx={{ width: "20px" }} source={source} />}
                </Row>
                <Row sx={{ alignItems: "center" }}>
                  <Text sx={{ mr: 1, color: "base.7" }}>Last updated:</Text>
                  <Text sx={{ mr: 1 }}>
                    {formatDate(model?.updated_at || model?.created_at)}
                    {updatedByUsername && " by"}
                  </Text>
                  {updatedByUsername && <Avatar name={updatedByUsername} />}
                </Row>
                <Row sx={{ borderLeft: "small", ml: 4, pl: 4, alignItems: "center" }}>
                  <Text sx={{ mr: 1, color: "base.7" }}>Slug:</Text>
                  <DisplaySlug currentSlug={model?.slug} />
                </Row>
                {!editingDraftChanges &&
                  (labelKeys.length > 0 ? (
                    <Row sx={{ height: "100%", alignItems: "center", pl: 4, ml: 4, borderLeft: "small" }}>
                      <Popout
                        content={({ close }) => (
                          <>
                            <Labels labels={currentLabels} sx={{ maxWidth: "200px" }} />
                            <Permission permissions={[{ resource: "model", grants: [ResourcePermissionGrant.Update] }]}>
                              <Button
                                sx={{ mt: 4 }}
                                variant="secondary"
                                onClick={() => {
                                  setIsEditLabelModalOpen(true);
                                  close();
                                }}
                              >
                                Edit labels
                              </Button>
                            </Permission>
                          </>
                        )}
                        contentSx={{ p: 3, minWidth: "90px" }}
                      >
                        <Text sx={{ mr: 1 }}>Labels</Text>
                        <SquareBadge>{Object.keys(labels || {}).length}</SquareBadge>
                        <ChevronDownIcon size={16} sx={{ ml: 2 }} />
                      </Popout>
                    </Row>
                  ) : (
                    <Row sx={{ pl: 4, ml: 4, borderLeft: "small", gap: 2 }}>
                      <Button
                        iconBefore={<PlusIcon color="gray.700" size={14} />}
                        size="small"
                        variant="secondary"
                        onClick={() => {
                          setIsEditLabelModalOpen(true);
                        }}
                      >
                        Add labels
                      </Button>
                      <Button
                        iconBefore={<FolderIcon color="gray.700" width={14} />}
                        size="small"
                        variant="secondary"
                        onClick={() => {
                          setIsFolderModalOpen(true);
                        }}
                      >
                        Move
                      </Button>
                    </Row>
                  ))}
              </Row>
            </Column>

            <Tabs setTab={(tab) => setTab(tab as Tab)} sx={{ mb: 8 }} tab={tab} tabs={TABS} />

            {hasPrimaryKeyIssue && (
              <Message sx={{ width: "100%", maxWidth: "100%", mb: 8 }} variant="warning">
                <Field label="Looks like your primary key is set to an undefined column.">
                  As a result, your syncs may fail or undefined behavior may occur, go to the Configuration tab to select a new
                  primary key.
                </Field>
              </Message>
            )}

            {tab === Tab.QUERY && (
              <Column sx={{ gap: 6 }}>
                <Row sx={{ alignItems: "center", justifyContent: "space-between", flex: 1 }}>
                  <Heading>
                    <Row>
                      {type === QueryType.Dbt || type === QueryType.DbtModel
                        ? "dbt Model"
                        : type === QueryType.LookerLook
                        ? "This model is based on a Look"
                        : "Query"}
                      {type === QueryType.LookerLook && <LookerIcon size={24} />}
                    </Row>
                  </Heading>
                  <Permission>
                    <Button
                      variant="secondary"
                      onClick={() => navigate(editingDraft ? `/models/${id}/query?editing=true` : `/models/${id}/query`)}
                    >
                      Edit
                    </Button>
                  </Permission>
                </Row>
                <Query model={model} />
              </Column>
            )}

            {tab === Tab.CONFIGURATION && (
              <Row sx={{ justifyContent: "space-between" }}>
                <Column sx={{ flex: 1, maxWidth: "500px" }}>
                  <Text sx={{ fontWeight: "semi", fontSize: 3, mb: 6 }}>Configuration</Text>
                  <Field label="Primary key">
                    {model && (
                      <ColumnSelect columns={columns} model={model} value={modelState?.primaryKey} onChange={setPrimaryKey} />
                    )}
                  </Field>
                </Column>
                <Permission
                  permissions={
                    workspace?.approvals_required ? [] : [{ resource: "model", grants: [ResourcePermissionGrant.Update] }]
                  }
                >
                  <Button disabled={!dirty} label="Save changes" loading={updating} onClick={update} />
                </Permission>
              </Row>
            )}

            {tab === Tab.SYNCS && (
              <Syncs
                isAudience={false}
                loading={modelLoading || tableLoading}
                orderBy={syncsOrderBy}
                syncs={syncs}
                onAdd={() => {
                  navigate(`/syncs/new?model=${id}`);
                }}
                onSort={curriedOnSort("syncs")}
              />
            )}
            {tab === Tab.ACTIVITY && id && (
              <ResourceActivityTimeline mappers={modelActivityMappers} resource="Model" resourceId={String(id)} />
            )}

            {tab === Tab.COLUMNS && (
              <ColumnSettings
                columns={columns}
                isDraft={model?.draft || false}
                loading={modelLoading || tableLoading}
                modelId={id}
                orderBy={columnsOrderBy}
                onSort={curriedOnSort("columns")}
              />
            )}
          </Column>
        </Page>
      </PermissionProvider>

      <ConfirmationDialog
        confirmButtonText="Delete model"
        isOpen={deleteModal}
        title="Delete model"
        variant="danger"
        onClose={() => {
          setDeleteModal(false);
        }}
        onConfirm={async () => {
          if (!id) {
            return;
          }

          await deleteModel({
            id,
          });

          analytics.track("Model Deleted", {
            model_id: model?.id,
            model_type: type,
            model_name: model?.name,
            source_id: source?.id,
            source_type: source?.type,
          });

          toast({
            id: "delete-model",
            title: "Model was deleted",
            variant: "success",
          });

          navigate("/models");
        }}
      >
        <Paragraph>Are you sure you want to delete this model? You won't be able to undo this.</Paragraph>
      </ConfirmationDialog>

      <EditLabels
        description="You can label models that have similar properties"
        existingLabelOptions={labels}
        hint="Example keys: team, project, region, env."
        isOpen={isEditLabelModalOpen}
        labels={currentLabels ?? {}}
        loading={updating}
        saveLabel="Save"
        title="Edit labels"
        onClose={() => setIsEditLabelModalOpen(false)}
        onSave={updateLabels}
      />

      {model && isFolderModalOpen && (
        <MoveFolder
          folder={model.folder}
          folderType="models"
          modelIds={model.id}
          viewType="models"
          onClose={() => setIsFolderModalOpen(false)}
        />
      )}

      <OverageModal />

      <SaveWarning dirty={dirty && !deleteModal} />
    </>
  );
};
