import { FC, Fragment, ReactNode, useState } from "react";

import { FilterMenu, FilterMenuButton, FilterMenuList, FilterMenuGroup, FilterMenuOption, useToast } from "@hightouchio/ui";
import immutableUpdate from "immutability-helper";
import pluralize from "pluralize";
import { Text, ThemeUIStyleObject } from "theme-ui";

import { AudienceTraitForm } from "src/components/audiences/audience-trait-form";
import { useRelatedAudiencesQuery, useUpdateAudienceMutation } from "src/graphql";
import {
  OrCondition,
  Condition,
  ConditionType,
  initialPropertyCondition,
  initialEventCondition,
  initialSetCondition,
  PropertyCondition,
  initialNumberOfCondition,
  NumberOfCondition,
  EventCondition,
  SegmentSetCondition,
  isTraitCondition,
  AdditionalColumn,
  TraitCondition,
  Audience,
  AudienceParent,
  AndCondition,
  RootCondition,
} from "src/types/visual";
import { Column, Row, Box } from "src/ui/box";
import { Button } from "src/ui/button";
import { Heading } from "src/ui/heading";
import { SegmentIcon, PlusIcon, PropertyIcon, UsersIcon, EventIcon, WarningIcon } from "src/ui/icons";
import { Link } from "src/ui/link";
import { Spinner } from "src/ui/loading";
// eslint-disable-next-line no-restricted-imports
import { Menu } from "src/ui/menu";
import { Modal } from "src/ui/modal";

import { AudienceEventTraitForm } from "../audiences/audience-event-trait-form";
import { ConditionField } from "./visual/condition";

enum SetupLinks {
  RelatedModel = "/audiences/setup/related-models/new",
  Event = "/audiences/setup/events/new",
  Audience = "/audiences/new",
}

type Requirement = { condition: "related model" | "event" | "audience"; to: string };

export interface QueryBuilderProps {
  audience?: Audience;
  parent: AudienceParent | undefined | null;
  filter: OrCondition<AndCondition | OrCondition> | AndCondition<AndCondition | OrCondition> | undefined;
  setConditions: (filter: AndCondition | OrCondition | null) => void;
  limit?: number;
  setLimit?: (limit: number) => void;
}

export const QueryBuilder: FC<Readonly<QueryBuilderProps>> = ({
  audience,
  parent,
  filter = { type: ConditionType.And, conditions: [] as RootCondition[] },
  setConditions,
}) => {
  /* top level conditions may only have one element. There the type may be defined so that the query builder
    is aware of whether the conditions should be `AND`'d or `OR`'d */
  const { toast } = useToast();
  const [requirementMissing, setRequirementMissing] = useState<Requirement | undefined>();

  const { mutateAsync: updateAudience } = useUpdateAudienceMutation({
    onSuccess: () => {
      // prevents audience query from invalidating
    },
  });

  const { data: relatedAudiencesData, isRefetching } = useRelatedAudiencesQuery(
    {
      parentModelId: parent?.id,
      modelId: audience?.id,
    },
    {
      enabled: Boolean(parent),
      refetchOnWindowFocus: true,
      staleTime: 1000 * 60, // 1 minute
    },
  );

  // Used for adding additional columns
  const [selectedCondition, setSelectedCondition] = useState<TraitCondition | EventCondition | null>(null);

  const relationships = parent?.relationships;
  const traits = parent?.traits;
  const columns = parent?.filterable_audience_columns;
  const events = relationships?.filter(({ to_model: { event } }) => Boolean(event));

  const addAdditionalColumn = async (additionalColumn: AdditionalColumn) => {
    if (!audience) {
      return;
    }

    await updateAudience({
      id: audience.id,
      input: {
        visual_query_filter: {
          ...(audience.visual_query_filter || {}),
          additionalColumns: [additionalColumn, ...(audience.visual_query_filter?.additionalColumns || [])],
        },
      },
    });

    toast({
      id: "add-trait",
      title: "Trait was added",
      variant: "success",
    });
  };

  const addInitialCondition = (condition: Condition) => {
    setConditions({
      ...filter,
      conditions: [...filter.conditions, { type: ConditionType.Or, conditions: [condition] }],
    });
  };

  const addOrCondition = (condition: Condition, index: number) => {
    setConditions({
      ...filter,
      conditions: immutableUpdate(filter.conditions, { [index]: { conditions: { $push: [condition] } } }),
    });
  };

  const addCondition = (condition: Condition, index?: number) => {
    if (typeof index === "undefined") {
      addInitialCondition(condition);
    } else {
      addOrCondition(condition, index);
    }
  };

  const updateCondition = (index: number) => (subIndex: number) => (updates: any) => {
    setConditions({
      ...filter,
      conditions: immutableUpdate(filter.conditions, { [index]: { conditions: { [subIndex]: { $merge: updates } } } }),
    });
  };

  const removeCondition = (index: number) => (subIndex: number) => () => {
    let updatedConditions = immutableUpdate(filter.conditions, { [index]: { conditions: { $splice: [[subIndex, 1]] } } });

    const conditionAtIndex = updatedConditions?.[index];

    if (
      (conditionAtIndex?.type === ConditionType.And || conditionAtIndex?.type === ConditionType.Or) &&
      conditionAtIndex?.conditions.length === 0
    ) {
      updatedConditions = immutableUpdate(filter.conditions, { $splice: [[index, 1]] });
    }

    if (updatedConditions.length === 0) {
      setConditions(null);
      return;
    }

    setConditions({ ...filter, conditions: updatedConditions });
  };

  const setOuterCondition = (conditionType: ConditionType.Or | ConditionType.And) =>
    setConditions({ ...filter, type: conditionType });

  const resetConditionToAdd = () => {
    setRequirementMissing(undefined);
  };

  const addConditionOptions = (index?: number) => [
    {
      label: "Have a property",
      description: "Filter by a column in the table",
      onClick: () => addCondition(initialPropertyCondition as PropertyCondition, index),
      icon: PropertyIcon,
    },
    {
      label: "Have a relation",
      description: "Filter by a property in a related model",
      onClick: () => {
        if (!relationships || relationships.length === 0) {
          return setRequirementMissing({
            condition: "related model",
            to: SetupLinks.RelatedModel,
          });
        }

        addCondition(initialNumberOfCondition as NumberOfCondition, index);
      },
      icon: UsersIcon,
    },
    {
      label: "Performed an event",
      description: "Filter by whether they have an associated event",
      onClick: () => {
        if (!events || events.length === 0) {
          return setRequirementMissing({
            condition: "event",
            to: SetupLinks.Event,
          });
        }

        addCondition(initialEventCondition as EventCondition, index);
      },
      icon: EventIcon,
    },
    {
      label: "Part of an audience",
      description: "Filter by whether they are included or not in an audience",
      onClick: () => {
        if (!relatedAudiencesData?.segments || relatedAudiencesData.segments.length === 0) {
          return setRequirementMissing({
            condition: "audience",
            to: SetupLinks.Audience,
          });
        }

        addCondition(initialSetCondition as SegmentSetCondition, index);
      },
      icon: SegmentIcon,
    },
  ];

  return (
    <>
      <Box sx={{ height: "100%" }}>
        <VStack gap={8} sx={{ pb: 12, pr: 3, fontWeight: "bold", fontSize: 0 }}>
          {filter.conditions.map((condition, index) => (
            <Row key={index} sx={{ width: "100%" }}>
              <Column sx={{ position: "relative" }}>
                <Row sx={{ alignItems: "center", pt: 4 }}>
                  {filter.conditions.length < 2 ? (
                    <Row
                      sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        height: "36px",
                        width: "44px",
                        flexShrink: 0,
                        bg: "forest",
                        borderRadius: 2,
                      }}
                    >
                      <Text sx={{ color: "white", fontSize: 1, fontWeight: "bold" }}>ALL</Text>
                    </Row>
                  ) : (
                    <Row
                      sx={{
                        flexShrink: 0,
                        width: "44px",
                        "&>button": {
                          padding: 0,
                          width: "100%",
                        },
                      }}
                    >
                      <FilterMenu>
                        {/* 
                          // eslint-ignore-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore primary variant exists but is not in type */}
                        <FilterMenuButton rightIcon={undefined} variant="primary">
                          {filter.conditions.length < 2 ? "ALL" : filter.type === ConditionType.And ? "AND" : "OR"}
                        </FilterMenuButton>

                        <FilterMenuList>
                          <FilterMenuGroup
                            title="filter type"
                            type="radio"
                            value={filter.type}
                            onChange={(value) => setOuterCondition(value as ConditionType.Or | ConditionType.And)}
                          >
                            <FilterMenuOption value={ConditionType.And}>AND</FilterMenuOption>
                            <FilterMenuOption value={ConditionType.Or}>OR</FilterMenuOption>
                          </FilterMenuGroup>
                        </FilterMenuList>
                      </FilterMenu>
                    </Row>
                  )}
                  <Box sx={{ height: "2px", bg: "forest", width: "24px" }} />
                </Row>
                <Box sx={{ position: "absolute", top: "52px", height: "100%", bg: "forest", width: "2px", ml: "20px" }} />
              </Column>
              <VStack gap={4}>
                {condition.conditions.map((condition, subIndex) => {
                  return (
                    <Fragment key={`${index}-${subIndex}`}>
                      {subIndex > 0 && <Text sx={{ fontSize: 0, fontWeight: "bold", color: "base.4" }}>OR</Text>}
                      <VStack
                        gap={4}
                        sx={{
                          p: 4,
                          bg: "secondaries.0",
                          borderRadius: 1,
                          border: "small",
                          borderColor: "secondaries.1",
                          minWidth: "100%",
                          position: "relative",
                          width: undefined,
                        }}
                      >
                        {audience && (isTraitCondition(condition) || condition.type === ConditionType.Event) && (
                          <Button
                            size="small"
                            sx={{ position: "absolute", top: 4, right: 4 }}
                            variant="gray"
                            onClick={() => setSelectedCondition(condition)}
                          >
                            Add trait
                          </Button>
                        )}
                        <ConditionField
                          audience={audience}
                          columns={columns}
                          condition={condition}
                          events={events}
                          parent={parent}
                          relationships={relationships}
                          traits={traits}
                          onChange={updateCondition(index)(subIndex)}
                          onRemove={removeCondition(index)(subIndex)}
                        />
                      </VStack>
                    </Fragment>
                  );
                })}

                <Menu disabled={isRefetching} options={addConditionOptions(index)} width="480px">
                  <Button
                    propagate
                    disabled={isRefetching}
                    iconBefore={!isRefetching && <PlusIcon size={14} />}
                    size="small"
                    variant="gray"
                  >
                    {isRefetching ? <Spinner /> : "OR"}
                  </Button>
                </Menu>
              </VStack>
            </Row>
          ))}
          <Row sx={{ alignItems: "center" }}>
            {filter.conditions.length === 0 && (
              <Text sx={{ fontWeight: "semi", color: "base.5", mr: 4, fontSize: 2 }}>All rows that...</Text>
            )}
            <Menu disabled={isRefetching} options={addConditionOptions()} width="480px">
              <Button
                propagate
                disabled={isRefetching}
                iconBefore={!isRefetching && <PlusIcon color="white" size={14} />}
                size="small"
                sx={{ position: "relative" }}
                variant="primary"
              >
                {isRefetching ? <Spinner /> : "Add condition"}
              </Button>
            </Menu>
          </Row>
        </VStack>
        {selectedCondition &&
          (isTraitCondition(selectedCondition) ? (
            <AudienceTraitForm
              alias=""
              conditions={selectedCondition.property.column.conditions}
              parent={parent}
              title="Add trait"
              trait={traits?.find((t) => t.id === selectedCondition.property.column.traitDefinitionId)}
              onClose={() => {
                setSelectedCondition(null);
              }}
              onSubmit={addAdditionalColumn}
            />
          ) : (
            <AudienceEventTraitForm
              alias=""
              condition={selectedCondition}
              parent={parent}
              title="Add event trait"
              onClose={() => {
                setSelectedCondition(null);
              }}
              onSubmit={addAdditionalColumn}
            />
          ))}
      </Box>

      <Modal
        bodySx={{ pb: 6 }}
        footer={
          <>
            <Button variant="secondary" onClick={resetConditionToAdd}>
              Go back
            </Button>
            <Link newTab to={requirementMissing?.to}>
              <Button onClick={resetConditionToAdd}>Add {requirementMissing?.condition}</Button>
            </Link>
          </>
        }
        header={
          <Row sx={{ alignItems: "center" }}>
            <WarningIcon color="yellow" sx={{ mr: 4 }} />
            <Heading variant="h2">{`No ${pluralize(requirementMissing?.condition ?? "")} found`}</Heading>
          </Row>
        }
        isOpen={Boolean(requirementMissing)}
        sx={{ maxWidth: "400px" }}
        onClose={resetConditionToAdd}
      >
        <Text as="p">
          In order to use this condition you need set up {requirementMissing?.condition === "event" ? "an" : "a"}{" "}
          {requirementMissing?.condition}. Would you like to create one now?
        </Text>
      </Modal>
    </>
  );
};

export const VStack: FC<{ children: ReactNode; sx?: ThemeUIStyleObject; gap: number }> = ({ children, gap, sx = {} }) => (
  <Column sx={{ width: "100%", "& > *:not(:last-child)": { mb: gap }, alignItems: "flex-start", ...sx }}>{children}</Column>
);
