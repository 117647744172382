import { FC, ReactNode } from "react";

import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from "@heroicons/react/24/solid";
import { Box, Column } from "@hightouchio/ui";

import { useHeaderHeight } from "src/contexts/header-height-context";
import { usePersistedState } from "src/hooks/use-persisted-state";

export const PageSidebar: FC<Readonly<{ children: ReactNode }>> = ({ children }) => {
  const { headerHeight } = useHeaderHeight();
  const { value: open, set: setOpen } = usePersistedState<boolean>("sidebar-open", true);

  return (
    <>
      {open && (
        <Column
          bg="gray.100"
          borderRight="1px"
          borderRightColor="gray.300"
          flexShrink={0}
          gap={6}
          height={`calc(100vh - ${headerHeight}px)`}
          overflowY="auto"
          overscrollBehavior="none"
          position="sticky"
          py={6}
          top={`${headerHeight}px`}
          width="280px"
        >
          {children}
        </Column>
      )}
      <Box
        bg="gray.100"
        border="1px"
        borderColor="gray.300"
        borderLeft={0}
        borderRadius="0px 6px 6px 0px"
        cursor="pointer"
        display="flex"
        flexShrink={0}
        height={30}
        ml={open ? 280 : 0}
        position="fixed"
        px={3}
        top={`${headerHeight + 28}px`}
        zIndex={1}
        onClick={() => setOpen(!open)}
      >
        {open ? <ChevronDoubleLeftIcon width="10" /> : <ChevronDoubleRightIcon width="10" />}
      </Box>
    </>
  );
};
