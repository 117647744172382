import { Bars3BottomRightIcon, FolderIcon, TableCellsIcon, ViewColumnsIcon } from "@heroicons/react/24/solid";
import { Code, Text } from "@hightouchio/ui";

import { commonActivityMappings } from "src/components/resource-activity/common-mappings";
import { ResourceActivityMapper } from "src/components/resource-activity/timeline";

export const modelActivityMappers: ResourceActivityMapper[] = [
  ...commonActivityMappings,
  {
    accessor: "primary_key",
    parser: (activity, { parsedDiff, oldValue }) => {
      if (parsedDiff.type !== "value") {
        return null;
      }

      return {
        message: (
          <Text>
            {activity.metadata.user_name} updated the primary key from <Code>{oldValue}</Code> to{" "}
            <Code>{parsedDiff.value}</Code>
          </Text>
        ),
        icon: <ViewColumnsIcon />,
      };
    },
  },
  {
    accessor: "query_table_name",
    parser: (activity, { parsedDiff }) => {
      if (parsedDiff.type !== "value") {
        return null;
      }

      return {
        message: (
          <Text>
            {activity.metadata.user_name} updated the query source table to <Code>{parsedDiff.value}</Code>
          </Text>
        ),
        icon: <TableCellsIcon />,
      };
    },
  },
  {
    accessor: "query_raw_sql",
    parser: (activity, { parsedDiff }) => {
      if (parsedDiff.type !== "value") {
        return null;
      }

      return {
        message: <Text>{activity.metadata.user_name} updated the model SQL query</Text>,
        icon: <Bars3BottomRightIcon />,
      };
    },
  },
  {
    accessor: "folder_id",
    parser: (activity, { parsedDiff }) => {
      if (parsedDiff.type !== "value") {
        return null;
      }

      return {
        message: <Text>{activity.metadata.user_name} updated the model folder</Text>,
        icon: <FolderIcon />,
      };
    },
  },
];
