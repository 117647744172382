import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { FolderOpenIcon, TagIcon, TrashIcon } from "@heroicons/react/24/outline";
import {
  Heading,
  Row,
  SearchInput,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuDivider,
  MenuItem,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import { capitalize } from "lodash";
import pluralize from "pluralize";
import { Image, Text } from "theme-ui";

import modelPlaceholder from "src/assets/placeholders/model.svg";
import searchPlaceholder from "src/assets/placeholders/search.svg";
import { DraftBadge } from "src/components/drafts/draft-badge";
import { DraftIcon } from "src/components/drafts/draft-icon";
import {
  createdByFilterConfig,
  Filters,
  labelFilterConfig,
  modelQueryTypeFilterConfig,
  useFilters,
} from "src/components/folders/filters";
import { Folders } from "src/components/folders/folder-list";
import { MoveFolder } from "src/components/folders/move-to-folder";
import { useFolderState } from "src/components/folders/use-folder-state";
import { EditLabels } from "src/components/labels/edit-labels";
import { LabelsCell } from "src/components/labels/labels-cell";
import { Page } from "src/components/layout";
import { BulkDeleteConfirmationModal } from "src/components/modals/bulk-delete-confirmation-modal";
import { PageAlert } from "src/components/page-alert";
import placeholderSource from "src/components/permission/source.svg";
import { PermissionedLinkButton } from "src/components/permissioned-button";
import { SyncsCell } from "src/components/syncs/syncs-cell";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  MinimalModelsQuery,
  ModelsQuery,
  ResourcePermissionGrant,
  SegmentsBoolExp,
  SegmentsOrderBy,
  useAddLabelsToModelsMutation,
  useDeleteModelsMutation,
  useDraftsQuery,
  useMinimalModelsQuery,
  useModelFiltersQuery,
  useModelsCountQuery,
  useModelsQuery,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import useHasPermission from "src/hooks/use-has-permission";
import useQueryState from "src/hooks/use-query-state";
import * as analytics from "src/lib/analytics";
import { Pagination, Table, TableColumn, useTableConfig } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { Placeholder } from "src/ui/table/placeholder";
import { useRowSelect } from "src/ui/table/use-row-select";
import { TextWithTooltip } from "src/ui/text";
import { useDestinations } from "src/utils/destinations";
import { useIncrementalQuery } from "src/utils/incremental-query";
import { QueryType } from "src/utils/models";
import { useNavigate } from "src/utils/navigate";
import { abbreviateNumber } from "src/utils/numbers";
import { openUrl } from "src/utils/urls";

import { useLabels } from "../../components/labels/use-labels";

enum SortKeys {
  Name = "name",
  NumSyncs = "syncs_aggregate.count",
  QueryType = "query_type",
  UpdatedAt = "updated_at",
}

export const Models: FC = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [search, setSearch] = useQueryState("search");
  const [confirmingDelete, setConfirmingDelete] = useState<boolean>(false);
  const { selectedRows, onRowSelect } = useRowSelect();
  const { mutateAsync: bulkDelete } = useDeleteModelsMutation();
  const [addingLabels, setAddingLabels] = useState(false);
  const { selectedFolder, setSelectedFolder, setMovingToFolder, movingToFolder, header, refetchFolders } = useFolderState({
    search,
    resourceType: "models",
    folderType: "models",
  });

  const { hasPermission: userCanDelete } = useHasPermission([{ resource: "model", grants: [ResourcePermissionGrant.Delete] }]);
  const { hasPermission: userCanUpdate } = useHasPermission([{ resource: "model", grants: [ResourcePermissionGrant.Update] }]);

  const { limit, offset, orderBy, page, setPage, onSort } = useTableConfig<SegmentsOrderBy>({
    defaultSortKey: "updated_at",
    sortOptions: Object.values(SortKeys),
  });

  const { labels } = useLabels();

  const { mutateAsync: addLabels, isLoading: loadingAddLabels } = useAddLabelsToModelsMutation();

  const { data: modelFilters } = useModelFiltersQuery(undefined, {
    select: (data) => data.segments,
  });

  const filterDefinitions = useMemo(() => {
    return {
      filters: {
        type: { options: modelQueryTypeFilterConfig(modelFilters || []), title: "Query type" },
        created_by: { options: createdByFilterConfig(modelFilters || []), title: "Created by" },
        labels: { options: labelFilterConfig(modelFilters || []), title: "Labels" },
      },
    };
  }, [modelFilters]);

  const { state: filterState, data: filterData, resetFilters } = useFilters(filterDefinitions);

  const hasuraFilters = useMemo(() => {
    const labelsFilters: SegmentsBoolExp["_or"] = filterState["labels"].selected.map((filter) => {
      const key = filter.id.split(":")[0];
      const value = filter.id.split(":")[1];
      const obj = {};
      obj[key!] = value;
      return {
        tags: { _contains: obj },
      };
    });

    const hasuraFilters: SegmentsBoolExp = {
      _and: [
        { is_schema: { _eq: false }, folder_id: { _eq: selectedFolder?.id }, query_type: { _neq: "visual" } },
        !filterState["type"].isAllSelected
          ? {
              query_type: { _in: filterState.type.selected.map((f) => f.id) },
            }
          : {},
        !filterState["labels"].isAllSelected
          ? {
              _or: labelsFilters,
            }
          : {},
        !filterState["created_by"].isAllSelected
          ? {
              _or: [
                {
                  created_by: { _in: filterState.created_by.selected.map((f) => f.id) },
                },
                {
                  created_by: { _is_null: true },
                },
              ],
            }
          : {},
      ],
    };

    if (search) {
      hasuraFilters._and!.push({ name: { _ilike: `%${search}%` } });
    }

    return hasuraFilters;
  }, [search, selectedFolder, filterState]);

  const fullModelsQuery = useModelsQuery(
    {
      offset,
      limit,
      filters: hasuraFilters,
      orderBy,
    },
    {
      notifyOnChangeProps: "tracked",
      keepPreviousData: true,
    },
  );

  const incrementalModels = useIncrementalQuery<MinimalModelsQuery, ModelsQuery>(
    useMinimalModelsQuery(
      {
        offset,
        limit,
        filters: hasuraFilters,
        orderBy,
      },
      {
        notifyOnChangeProps: "tracked",
        keepPreviousData: true,
      },
    ),
    fullModelsQuery,
  );

  const { data: allModelsCount } = useModelsCountQuery({
    filters: {
      query_type: { _neq: "visual" },
      is_schema: { _eq: false },
    },
  });

  const { data: drafts } = useDraftsQuery({
    resourceType: "model",
    status: "pending",
  });

  const models = incrementalModels?.data?.segments;
  const modelsCount = incrementalModels?.data?.segments_aggregate?.aggregate?.count ?? 0;

  const { data: entitlementsData, isLoading: _loadingEntitlements } = useEntitlements(true);
  const { overageLockout, destinationOverageText } = entitlementsData.overage;
  const overageText = destinationOverageText + " To create a model, upgrade your plan.";

  const {
    data: { definitions: destinationDefinitions },
  } = useDestinations();

  const bulkAddLabels = async (labels: Record<string, string>) => {
    const labelCount = Object.keys(labels).length;

    try {
      await addLabels({ ids: selectedRows.map(String), labels });
      setAddingLabels(false);

      toast({
        id: "bulk-add-model-labels",
        title: `Added ${labelCount} ${pluralize("label", labelCount)} to ${selectedRows.length} ${pluralize(
          "model",
          selectedRows.length,
        )}`,
        variant: "success",
      });

      onRowSelect([]);
    } catch (error) {
      toast({
        id: "bulk-add-model-labels",
        title: "Couldn't update labels",
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  const bulkDeleteModels = async () => {
    if (userCanDelete) {
      await bulkDelete({ ids: selectedRows.map(String) });
      onRowSelect([]);
    }
  };

  const columns = useMemo(
    (): TableColumn[] => [
      {
        name: "Name",
        sortDirection: orderBy?.name,
        onClick: () => onSort(SortKeys.Name),
        cell: ({ id, name, connection: source, tags, draft: isInitialDraft }) => {
          const draft = drafts?.drafts.find((d) => String(d.resource_id) === String(id));

          return (
            <Tooltip isDisabled={Boolean(source)} message="This destination is only visible to some users">
              <Row sx={{ alignItems: "center", mt: 1 }}>
                <Image
                  alt={source?.definition?.name ?? "Private source"}
                  src={source?.definition?.icon ?? placeholderSource}
                  sx={{ width: "20px", maxHeight: "100%", objectFit: "contain", mr: 2, flexShrink: 0 }}
                />
                <TextWithTooltip disabled={!name} sx={{ fontWeight: "semi", maxWidth: "350px" }} text={name}>
                  {name ?? "Private source"}
                </TextWithTooltip>
                {isInitialDraft && <DraftBadge sx={{ ml: 2 }} />}
                {draft && <DraftIcon draft={draft} sx={{ ml: 2 }} />}

                <LabelsCell labels={tags} />
              </Row>
            </Tooltip>
          );
        },
      },
      {
        name: "Folder",
        cell: ({ folder }) => {
          if (folder) {
            return folder.name;
          }

          return "--";
        },
      },
      {
        name: "Size",
        key: "query_runs.[0].size",
        max: "max-content",
        cell: (size) => (size ? <Text>{abbreviateNumber(size)}</Text> : <Text sx={{ color: "base.4" }}>--</Text>),
      },
      {
        name: "Syncs",
        sortDirection: orderBy?.syncs_aggregate?.count,
        onClick: () => onSort(SortKeys.NumSyncs),
        max: "max-content",
        min: "232px",
        disabled: ({ syncs }) => Boolean(syncs?.length),
        cell: ({ syncs }) => {
          return <SyncsCell definitions={destinationDefinitions ?? []} syncs={syncs} />;
        },
      },
      {
        name: "Type",
        sortDirection: orderBy?.query_type,
        onClick: () => onSort(SortKeys.QueryType),
        max: "max-content",
        cell: ({ query_type, custom_query }) => (
          <Text sx={{ fontWeight: "semi", color: "base.6" }}>
            {query_type === QueryType.Table
              ? "Table"
              : query_type === QueryType.Dbt
              ? "dbt"
              : query_type === QueryType.DbtModel
              ? "dbt Model"
              : query_type === QueryType.Custom
              ? custom_query?.["type"]
                ? capitalize(custom_query["type"])
                : "Custom"
              : "SQL"}
          </Text>
        ),
      },
      {
        ...LastUpdatedColumn,
        sortDirection: orderBy?.updated_at,
        onClick: () => onSort(SortKeys.UpdatedAt),
      },
    ],
    [destinationDefinitions, orderBy, drafts],
  );

  const onRowClick = useCallback(({ id }, event) => openUrl(`/models/${id}`, navigate, event), [navigate]);

  const placeholder = useMemo(
    () => ({
      image: searchPlaceholder,
      title: "No models found",
      error: "Models failed to load, please try again.",
    }),
    [],
  );

  useEffect(() => {
    setPage(0);
  }, [hasuraFilters]);

  useEffect(() => {
    onRowSelect([]);
  }, [page]);

  return (
    <>
      <PermissionProvider permissions={[{ resource: "model", grants: [ResourcePermissionGrant.Update] }]}>
        <Page
          sidebar={
            <>
              <Row px={5}>
                <SearchInput
                  placeholder="Search all models..."
                  value={search ?? ""}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </Row>
              <Folders
                modelsCount={allModelsCount?.segments_aggregate.aggregate?.count}
                modelsRootName="All models"
                refetchFolders={refetchFolders}
                rootFolder="models"
                selectedFolder={selectedFolder}
                setRootFolder={() => undefined}
                setSelectedFolder={setSelectedFolder}
                viewType="models"
              />
              <Filters filters={filterData} resetFilters={resetFilters} />
            </>
          }
          title="Models"
        >
          <Row sx={{ alignItems: "center", justifyContent: "space-between", mb: 4, px: 4, gap: 4 }}>
            <Row ml={16} overflow="hidden" sx={{ h2: { overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" } }}>
              <Heading size="xl">{header}</Heading>
            </Row>
            <Row flexShrink={0} gap={3}>
              {selectedRows.length > 0 && (
                <Row align="center" gap={2}>
                  <Text as="span" sx={{ color: "base.5" }}>{`${pluralize("model", selectedRows.length, true)} selected`}</Text>

                  <Menu>
                    <MenuButton>Actions</MenuButton>

                    <MenuList>
                      {userCanUpdate && (
                        <>
                          <MenuItem
                            icon={FolderOpenIcon}
                            onClick={() => {
                              setMovingToFolder(true);
                            }}
                          >
                            Move to folder
                          </MenuItem>

                          <MenuItem
                            icon={TagIcon}
                            onClick={() => {
                              setAddingLabels(true);
                            }}
                          >
                            Add labels
                          </MenuItem>
                        </>
                      )}

                      {userCanUpdate && userCanDelete && <MenuDivider />}

                      {userCanDelete && (
                        <MenuItem
                          icon={TrashIcon}
                          variant="danger"
                          onClick={() => {
                            setConfirmingDelete(true);
                          }}
                        >
                          Delete
                        </MenuItem>
                      )}
                    </MenuList>
                  </Menu>
                </Row>
              )}
              <PermissionedLinkButton
                href="/models/new"
                isDisabled={overageLockout}
                permissions={[{ resource: "model", grants: [ResourcePermissionGrant.Create] }]}
                tooltip={overageLockout && overageText}
                variant="primary"
                onClick={() => {
                  analytics.track("Add Model Clicked");
                }}
              >
                Add model
              </PermissionedLinkButton>
            </Row>
          </Row>
          <Table
            columns={columns}
            data={models}
            error={Boolean(incrementalModels.error)}
            loading={incrementalModels.isFetching}
            placeholder={placeholder}
            selectedRows={selectedRows}
            onRowClick={onRowClick}
            onSelect={onRowSelect}
          />

          <Pagination count={modelsCount} label="models" page={page} rowsPerPage={limit} setPage={setPage} sx={{ pr: 4 }} />
        </Page>
      </PermissionProvider>

      <BulkDeleteConfirmationModal
        count={selectedRows.length}
        isOpen={confirmingDelete}
        label="model"
        onClose={() => setConfirmingDelete(false)}
        onDelete={bulkDeleteModels}
      />

      {movingToFolder && (
        <MoveFolder
          folder={null}
          folderType="models"
          modelIds={selectedRows.map((id) => id.toString())}
          viewType="models"
          onClose={() => {
            setMovingToFolder(false);
            fullModelsQuery.refetch();
            onRowSelect([]);
          }}
        />
      )}

      <EditLabels
        description="You can label models that have similar properties"
        existingLabelOptions={labels}
        hint="Example keys: team, project, region, env."
        isOpen={addingLabels}
        loading={loadingAddLabels}
        saveLabel={`Apply to ${selectedRows.length} ${pluralize("model", selectedRows.length)}`}
        title="Add labels"
        onClose={() => setAddingLabels(false)}
        onSave={bulkAddLabels}
      />
    </>
  );
};

const Loader = () => {
  const { resources } = useUser();

  if (resources?.model) {
    return <Models />;
  }

  return (
    <Page
      fullWidth
      outsideTopbar={
        resources?.source ? null : (
          <PageAlert
            button={
              <PermissionedLinkButton
                href="/sources/new"
                permissions={[{ resource: "model", grants: [ResourcePermissionGrant.Create] }]}
                variant="primary"
              >
                Configure data source
              </PermissionedLinkButton>
            }
            description="Hightouch must be connected to least one data source before you can create a model. Your source can be a data warehouse, spreadsheet, or other data system."
            title="First, you need to configure a data source"
          />
        )
      }
      title="Models"
    >
      <Heading mb={8} size="xl">
        Models
      </Heading>
      <Placeholder
        content={{
          image: modelPlaceholder,
          title: "No models in this workspace",
          body: "A model describes how your data source will be queried. For most sources, you can use SQL to filter, join, and transform your data before syncing. Alternatively, you can select an existing table or view, or import models from other tools like Looker and dbt.",
          button: resources?.source ? (
            <PermissionedLinkButton
              href="/models/new"
              permissions={[{ resource: "model", grants: [ResourcePermissionGrant.Create] }]}
              variant="primary"
            >
              Add model
            </PermissionedLinkButton>
          ) : null,
        }}
      />
    </Page>
  );
};

export default Loader;
