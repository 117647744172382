import { Image, Text, ThemeUIStyleObject } from "theme-ui";

import placeholderSource from "src/components/permission/source.svg";
import { SourceQuery, useDiscoverSourceMutation, useSourceObjectDefinitionsQuery } from "src/graphql";
import { Badge } from "src/ui/badge";

export type SourceConfig = Record<string, unknown>;

export type SourceTunnel = NonNullable<NonNullable<SourceQuery["connections_by_pk"]>["tunnel"]>;

export const useObjectDefinitions = (sourceId: string, enable = true) => {
  const {
    data: objectDefinitionsData,
    isFetching: objectDefinitionsLoading,
    error: objectDefinitionsError,
    refetch: refetchObjectDefinitions,
  } = useSourceObjectDefinitionsQuery({ sourceId: sourceId ?? "" }, { enabled: Boolean(sourceId) && enable });

  const { isLoading: discoverLoading, error: discoverError, mutateAsync: discover } = useDiscoverSourceMutation();

  const objectDefinitions = objectDefinitionsData?.object_definitions;

  const objectDefinitionOptions =
    objectDefinitions?.map(({ name, object_definition_group }) => ({
      label: `${object_definition_group?.name}.${name}`,
      value: `${object_definition_group?.name}.${name}`,
    })) || [];

  const refetch = async () => {
    try {
      await discover({ sourceId });
      refetchObjectDefinitions();
    } catch (error) {
      console.log(error);
    }
  };

  return {
    refetch,
    objectDefinitions,
    objectDefinitionOptions,
    loading: discoverLoading || objectDefinitionsLoading,
    error: (discoverError || objectDefinitionsError)?.message,
  };
};

type SourceTileProps = {
  source?: {
    definition: {
      name: string;
      icon: string;
      isSampleDataSource: boolean;
    };
  };
  iconSx?: Record<string, unknown>;
  placeholder?: boolean;
};

export function SourceTile({ source, placeholder, iconSx = {} }: Readonly<SourceTileProps>): JSX.Element | null {
  return (
    <>
      <SourceIcon placeholder={placeholder} source={source} sx={{ width: "24px", ...iconSx }} />
      <Text sx={{ fontWeight: "semi", fontSize: 2, ml: 2 }}>
        {!source && placeholder ? "Private source" : source?.definition.name}
      </Text>
      <SourceBadges isSampleDataSource={source?.definition.isSampleDataSource} />
    </>
  );
}

export function SourceIcon({
  placeholder,
  source,
  sx = {},
}: Readonly<{
  placeholder?: boolean;
  source:
    | {
        definition: {
          name: string;
          icon: string;
        };
      }
    | undefined
    | null;
  sx: ThemeUIStyleObject;
}>): JSX.Element | null {
  const alt = placeholder && !source ? "Private source" : source?.definition?.name;
  const src = placeholder && !source ? placeholderSource : source?.definition?.icon;
  return <Image alt={alt} src={src} sx={{ ...sx, objectFit: "contain" }} />;
}

export function SourceBadges({
  isSampleDataSource,
  sx = {},
}: Readonly<{
  isSampleDataSource: boolean | undefined;
  sx?: ThemeUIStyleObject;
}>): JSX.Element | null {
  return isSampleDataSource ? (
    <Badge sx={{ ml: 2, ...sx }} variant="orange">
      Sample
    </Badge>
  ) : null;
}
