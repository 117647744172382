import { FC } from "react";

import { Box, Image, Text, Grid } from "theme-ui";

import { Editor } from "src/components/editor";
import { DbtQuery } from "src/components/explore/preview/dbt-query";
import { TableQuery } from "src/components/explore/preview/table-query";
import GitHubLogo from "src/components/logos/github.svg";
import { CustomQueryView } from "src/components/sources/forms/custom-query";
import { useDbtModelQuery, useDbtSyncModelQuery, useGetLookerLookQuery, ModelQuery, ObjectQuery } from "src/graphql";
import { Button } from "src/ui/button";
import { Field } from "src/ui/field";
import { QueryType } from "src/utils/models";

import { LookerQuery } from "../explore/preview/looker-query";
import { VisualQuery } from "../explore/preview/visual-query";

type Props = {
  model?: ((ModelQuery["segments_by_pk"] | ObjectQuery["segments_by_pk"]) & { parent?: { id: string } }) | null;
};

export const Query: FC<Readonly<Props>> = ({ model }) => {
  const source = model?.connection;
  const type = model?.query_type;
  const dbtModelId = model?.query_dbt_model_id;
  const lookerLookId = model?.query_looker_look_id;

  const { data: dbtModelQuery } = useDbtModelQuery(
    {
      id: Number(dbtModelId),
    },
    { enabled: Boolean(dbtModelId && type === QueryType.Dbt) },
  );

  const { data: dbtSyncModelQuery } = useDbtSyncModelQuery(
    {
      id: String(dbtModelId),
    },
    { enabled: Boolean(dbtModelId && type === QueryType.DbtModel) },
  );

  const { data: lookerLookQuery } = useGetLookerLookQuery(
    {
      id: lookerLookId ?? "",
      withSql: true,
    },
    { enabled: Boolean(lookerLookId && type === QueryType.LookerLook) },
  );

  switch (type) {
    case QueryType.Visual:
      return (
        <Box sx={{ p: 2, border: "small", borderRadius: 1, bg: "white" }}>
          <VisualQuery model={model} />
        </Box>
      );
    case QueryType.Table:
      return (
        <Box sx={{ p: 2, border: "small", borderRadius: 1, bg: "white" }}>
          <TableQuery table={model?.query_table_name ?? ""} />
        </Box>
      );
    case QueryType.RawSql:
      return (
        <Box sx={{ border: "small", borderRadius: 1, bg: "white" }}>
          <Editor readOnly language="sql" value={model?.query_raw_sql ?? ""} />
        </Box>
      );
    case QueryType.DbtModel:
      return dbtSyncModelQuery?.dbt_sync_models_by_pk ? <DbtQuery model={dbtSyncModelQuery?.dbt_sync_models_by_pk} /> : null;
    case QueryType.Dbt:
      return (
        <Grid gap={3}>
          <Field inline label="Name">
            <Text variant="subtle">{dbtModelQuery?.dbt_models_by_pk?.alias || dbtModelQuery?.dbt_models_by_pk?.name}</Text>
          </Field>

          {dbtModelQuery?.dbt_models_by_pk?.description && (
            <Field inline label="Description">
              <Text variant="subtle">{dbtModelQuery?.dbt_models_by_pk?.description}</Text>
            </Field>
          )}

          {dbtModelQuery?.dbt_models_by_pk?.original_file_path &&
            dbtModelQuery?.dbt_models_by_pk?.git_sync_config?.repository?.includes("github.com") && (
              <Field inline label="Source">
                <a
                  href={`${dbtModelQuery?.dbt_models_by_pk?.git_sync_config?.repository}/blob/master/${dbtModelQuery?.dbt_models_by_pk?.original_file_path}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  <Button
                    sx={{
                      p: 3,
                      alignSelf: "start",
                    }}
                    variant="secondary"
                  >
                    <Image src={GitHubLogo} sx={{ height: 25 }} />
                  </Button>
                </a>
              </Field>
            )}
        </Grid>
      );
    case QueryType.LookerLook:
      return lookerLookQuery?.getLookerLook?.look ? <LookerQuery look={lookerLookQuery?.getLookerLook?.look} /> : null;
    case QueryType.Custom:
      return model && source ? <CustomQueryView query={model.custom_query} source={source} /> : null;
    default:
      return null;
  }
};
