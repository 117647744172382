import { useCallback, useMemo, useState, FC, ChangeEvent } from "react";

import { Box, Checkbox, Switch } from "@hightouchio/ui";
import { capitalize, debounce } from "lodash";
import { Text } from "theme-ui";

import { usePermission } from "src/contexts/permission-context";
import { useUpdateModelColumnMutation, useUpdateModelColumnsMutation } from "src/graphql";
import { ColumnDateTypes, ColumnType } from "src/types/visual";
import { Badge } from "src/ui/badge";
import { Row, Wrap } from "src/ui/box";
import { Button } from "src/ui/button";
import { Heading } from "src/ui/heading";
import { Input, SearchInput } from "src/ui/input";
import { NewSelect } from "src/ui/new-select";
import { Table } from "src/ui/table";
import { formatDate } from "src/utils/time";

import { ErrorModal } from "../modals/error-modal";
import { Permission } from "../permission";

type Props = {
  source?: any;
  modelId: string;
  columns: any;
};

export enum RefreshIntervals {
  Hourly = 3600,
  Daily = 86400,
  Weekly = 604800,
  BiWeekly = 1209600,
  Monthly = 2419200,
  ManualOnly = -1,
}

const REFRESH_OPTIONS = [
  {
    label: "Hourly",
    value: RefreshIntervals.Hourly,
  },
  {
    label: "Daily",
    value: RefreshIntervals.Daily,
  },
  {
    label: "Weekly",
    value: RefreshIntervals.Weekly,
  },
  {
    label: "Bi-weekly",
    value: RefreshIntervals.BiWeekly,
  },
  {
    label: "Monthly",
    value: RefreshIntervals.Monthly,
  },
  {
    label: "Manual Only",
    value: RefreshIntervals.ManualOnly,
  },
];

const SUPPORTED_SUGGESTION_SOURCES = ["postgres", "bigquery", "snowflake", "athena", "redshift", "databricks", "sample-data"];
const SUPPORTED_JSON_ARRAY_SOURCES = ["postgres", "bigquery", "snowflake"];

// Represents the supported casts for each source type that should be available in the UI.
// TODO: expand support for casting as backend support increases
const SUPPORTED_COLUMN_CASTS = {
  snowflake: {
    [ColumnType.String]: [
      { label: "String", value: ColumnType.String },
      { label: "Number", value: ColumnType.Number },
      { label: "Timestamp", value: ColumnType.Timestamp },
    ],
  },
  redshift: {
    [ColumnType.String]: [
      { label: "String", value: ColumnType.String },
      { label: "Number", value: ColumnType.Number },
    ],
    [ColumnType.Timestamp]: [
      { label: "Timestamp", value: ColumnType.Timestamp },
      { label: "Date", value: ColumnType.Date },
    ],
  },
};

export const ColumnSettings: FC<Readonly<Props>> = ({ modelId, source, columns }) => {
  const [search, setSearch] = useState<string>("");

  const refreshInterval = columns?.find(({ top_k_sync_interval }) => Boolean(top_k_sync_interval))?.top_k_sync_interval;
  const refreshing = Boolean(columns?.find(({ trigger_top_k_sync, top_k_enabled }) => top_k_enabled && trigger_top_k_sync));

  const [currentRefreshInterval, setCurrentRefreshInterval] = useState<number>(refreshInterval);

  const suggestionsDisabled = !SUPPORTED_SUGGESTION_SOURCES.includes(source?.type);

  const jsonArraysEnabled = SUPPORTED_JSON_ARRAY_SOURCES.includes(source?.type);

  const columnCasts = source?.type in SUPPORTED_COLUMN_CASTS ? SUPPORTED_COLUMN_CASTS[source?.type] : null;

  const { mutateAsync: updateColumn } = useUpdateModelColumnMutation();
  const { mutateAsync: updateColumns } = useUpdateModelColumnsMutation();

  const updateAlias = useCallback(
    debounce((name, alias) => {
      updateColumn({ id: modelId, name, input: { alias } });
    }, 1500),
    [updateColumn, modelId],
  );

  const updateRedactedName = useCallback(
    debounce(({ name, redactedText }: { name: string; redactedText: string }) => {
      updateColumn({ id: modelId, name, input: { redacted_text: redactedText } });
    }, 1500),
    [updateColumn, modelId],
  );

  const permission = usePermission();

  const tableColumns = useMemo(
    () => [
      {
        name: "Enabled",
        max: "100px",
        cell: ({ disable, name }) => {
          const handleChange = useCallback(
            (event: ChangeEvent<HTMLInputElement>) => {
              updateColumn({ id: modelId, name, input: { disable: !event.target.checked } });
            },
            [name, modelId, updateColumn],
          );

          return <Checkbox isChecked={!disable} isDisabled={Boolean(permission?.unauthorized)} onChange={handleChange} />;
        },
      },
      {
        key: "name",
        name: "Name",
      },
      {
        name: "Type",
        min: "150px",
        max: "250px",
        cell: ({ name, type: warehouseType, custom_type: customType, disable }) => {
          const type = customType ?? warehouseType;

          const isValidJsonArrayColumnType = [
            ColumnType.Json,
            ColumnType.JsonArrayStrings,
            ColumnType.JsonArrayNumbers,
          ].includes(type);

          if (columnCasts && warehouseType in columnCasts) {
            return (
              <NewSelect
                disabled={disable}
                options={columnCasts[warehouseType]}
                strategy="absolute"
                value={type}
                onChange={(value) => {
                  updateColumn({ id: modelId, name, input: { custom_type: value } });
                }}
              />
            );
          }

          if (jsonArraysEnabled && isValidJsonArrayColumnType) {
            return (
              <NewSelect
                disabled={disable}
                options={[
                  { label: "JSON Array (Strings)", value: ColumnType.JsonArrayStrings },
                  { label: "JSON Array (Numbers)", value: ColumnType.JsonArrayNumbers },
                ]}
                placeholder="Please select a type..."
                strategy="absolute"
                value={type === ColumnType.Json ? undefined : type}
                onChange={(value) => {
                  updateColumn({ id: modelId, name, input: { custom_type: value } });
                }}
              />
            );
          }

          return <Text>{capitalize(type)}</Text>;
        },
      },
      {
        min: "200px",
        name: "Alias",
        cell: ({ alias, name, disable }) => {
          return (
            <UncontrolledInput
              disabled={disable}
              initialValue={alias}
              placeholder="Add an alias..."
              onChange={(value) => {
                updateAlias(name, value);
              }}
            />
          );
        },
      },
      {
        name: "Suggestions",
        cell: ({
          top_k_enabled: enabled,
          name,
          last_top_k_sync_timestamp: timestamp,
          top_k_error: error,
          disable,
          disable_preview,
          type,
        }) => {
          const isDateType = ColumnDateTypes.includes(type);

          return isDateType ? (
            <Row sx={{ alignItems: "center" }}>
              <Text sx={{ color: "base.5", fontSize: 0 }}>Not supported for date types</Text>
            </Row>
          ) : (
            <Row sx={{ alignItems: "center" }}>
              <Box alignItems="center" display="flex" gap={2} mr={error || timestamp ? 4 : 0}>
                <Text
                  sx={{
                    textTransform: "uppercase",
                    fontSize: "10px",
                    color: "base.4",
                    fontWeight: "bold",
                  }}
                >
                  {enabled ? "On" : "Off"}
                </Text>

                <Switch
                  isChecked={enabled}
                  isDisabled={isDateType || suggestionsDisabled || disable || disable_preview}
                  onChange={(value) => {
                    updateColumn({ id: modelId, name, input: { top_k_enabled: value } });
                  }}
                />
              </Box>
              {!disable && (
                <>
                  {error ? (
                    <ErrorModal
                      error={error}
                      variant={
                        typeof error === "string" && error.startsWith("number of distinct values exceeds limit")
                          ? "warning"
                          : "error"
                      }
                    />
                  ) : timestamp && enabled ? (
                    <Text sx={{ color: "base.5", fontSize: 0 }}>Last updated {formatDate(timestamp)}</Text>
                  ) : null}
                </>
              )}
            </Row>
          );
        },
      },
      {
        name: "Redacted",
        min: "150px",
        max: "250px",
        cell: ({ disable_preview, disable, name, redacted_text }) => {
          return (
            <Row sx={{ alignItems: "center" }}>
              <Checkbox
                isChecked={disable_preview}
                isDisabled={Boolean(permission?.unauthorized ?? disable)}
                mr={2}
                onChange={(event) => {
                  updateColumn({
                    id: modelId,
                    name,
                    input: { disable_preview: event.target.checked, top_k_enabled: event.target.checked ? false : undefined },
                  });
                }}
              />

              {Boolean(disable_preview) && (
                <UncontrolledInput
                  disabled={disable}
                  initialValue={redacted_text}
                  placeholder="Customize redacted text..."
                  onChange={(value) => {
                    updateRedactedName({ name: name, redactedText: value });
                  }}
                />
              )}
            </Row>
          );
        },
      },
    ],
    [suggestionsDisabled, permission, updateColumn, updateAlias],
  );

  const filteredColumns = useMemo(
    () => columns.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase())),
    [search, columns],
  );

  const disabled = useCallback(({ disable }: { disable: boolean }) => disable, []);

  return (
    <>
      <Wrap spacing={2} sx={{ alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}>
        <Row sx={{ alignItems: "center", flexShrink: 0 }}>
          <Heading sx={{ mr: 2 }}>Columns</Heading>
          <Badge sx={{ mr: 4 }} variant="base">
            {columns?.length}
          </Badge>
          <SearchInput placeholder="Search columns..." value={search} onChange={setSearch} />
        </Row>
        <Row sx={{ alignItems: "center", justifyContent: "flex-end", flexShrink: 0 }}>
          <Text sx={{ mr: 2, color: "base.6", whiteSpace: "nowrap" }}>Refresh interval</Text>
          <NewSelect
            options={REFRESH_OPTIONS}
            placeholder="Select an interval..."
            value={currentRefreshInterval}
            onChange={(value) => {
              if (value) {
                setCurrentRefreshInterval(value);
                updateColumns({
                  id: modelId,
                  input: {
                    top_k_sync_interval: value,
                  },
                });
              }
            }}
          />
          <Permission>
            <Button
              disabled={refreshing}
              sx={{ ml: 2 }}
              variant="secondary"
              onClick={() => {
                updateColumns({
                  id: modelId,
                  input: {
                    trigger_top_k_sync: true,
                  },
                });
              }}
            >
              {refreshing ? "Refreshing..." : "Refresh all suggestions"}
            </Button>
          </Permission>
        </Row>
      </Wrap>

      <Table
        columns={tableColumns}
        data={filteredColumns}
        disabled={disabled}
        primaryKey="name"
        rowHeight={50}
        sx={{ mt: 4 }}
      />
    </>
  );
};

const UncontrolledInput = ({ initialValue, onChange, ...props }) => {
  const [value, setValue] = useState<string>(initialValue);

  const handleChange = (value) => {
    setValue(value);
    onChange(value);
  };

  return <Input {...props} value={value} onChange={handleChange} />;
};
