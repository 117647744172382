import { FC } from "react";

import { Column, Row, Box } from "@hightouchio/ui";

import { useUser } from "src/contexts/user-context";
import { Circle } from "src/ui/circle";
import { LogoutIcon } from "src/ui/icons";
import { Link } from "src/ui/link";
// eslint-disable-next-line no-restricted-imports
import { Menu } from "src/ui/menu";
import { getInitials } from "src/utils/user";

export const UserMenu: FC = () => {
  const { user } = useUser();

  return (
    <>
      <Menu
        portal
        header={
          <Column gap={2} width="100%">
            <Row gap={3} justify="space-between" overflow="hidden">
              <Box overflow="hidden">
                <Box fontSize="lg" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                  {user?.name}
                </Box>
                <Box color="gray.600" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                  {user?.email}
                </Box>
              </Box>
              <Circle color="avocado" radius="32px" sx={{ color: "forest", fontWeight: "bold", fontSize: 0 }}>
                {getInitials(user?.name)}
              </Circle>
            </Row>

            <Row align="center" gap={2}>
              <Link to={`${import.meta.env.VITE_WEBSITE_URL}/terms-of-service`} variant="subtle">
                Terms
              </Link>
              <Circle color="dark.1" radius="4px" />
              <Link to={`${import.meta.env.VITE_WEBSITE_URL}/privacy-policy`} variant="subtle">
                Privacy
              </Link>
            </Row>
          </Column>
        }
        options={[
          {
            divider: "top",
            icon: LogoutIcon,
            label: "Log out of Hightouch",
            link: `${import.meta.env.VITE_API_BASE_URL}/auth/logout`,
          },
        ]}
        placement="bottom-end"
        sx={{ width: "100%", mt: 0 }}
        width="300px"
      >
        <Row
          _hover={{
            borderColor: "rgba(255,255,255,.5)",
          }}
          align="center"
          border="1px solid rgba(255,255,255,.3)"
          borderRadius="6px"
          color="white"
          gap={2.5}
          justify={["center", "center", "center", "flex-start"]}
          px={[0, 0, 0, 2.5]}
          py={2}
          width="100%"
        >
          <Circle color="lightspeed" radius="24px" sx={{ flex: "none", color: "forest", fontWeight: "bold", fontSize: "11px" }}>
            {getInitials(user?.name)}
          </Circle>
          <Box
            color="rgba(255,255,255,.7)"
            display={["none", "none", "none", "block"]}
            fontSize="sm"
            fontWeight={500}
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {user?.email}
          </Box>
        </Row>
      </Menu>
    </>
  );
};
