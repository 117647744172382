import { FC, useMemo, useState } from "react";

import { LabelsCell } from "src/components/labels/labels-cell";
import { ResourceSelect } from "src/components/resource-select";
import { ConnectionsBoolExp, ConnectionsOrderBy, useSourcesQuery, SourcesQuery } from "src/graphql";
import { Row } from "src/ui/box";
import { TableColumn, useTableConfig } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { TextWithTooltip } from "src/ui/text";
import { QueryType } from "src/utils/models";
import { SourceBadges, SourceIcon } from "src/utils/sources";

export type Source = SourcesQuery["connections"][0];

enum SortKeys {
  Name = "name",
  Type = "type",
  UpdatedAt = "updated_at",
}

type Props = {
  onSelect: (source: Source) => void;
  queryType?: QueryType;
};

export const SourceSelect: FC<Readonly<Props>> = ({ onSelect, queryType }) => {
  const [search, setSearch] = useState("");

  const { onSort, orderBy } = useTableConfig<ConnectionsOrderBy>({
    defaultSortKey: "updated_at",
    sortOptions: Object.values(SortKeys),
  });

  const hasuraFilters: ConnectionsBoolExp = useMemo(() => {
    if (search) {
      return {
        name: {
          _ilike: `%${search}%`,
        },
      };
    }
    return {};
  }, [search]);

  const {
    data: sources,
    error,
    isLoading: loading,
    isRefetching,
  } = useSourcesQuery({ filters: hasuraFilters, orderBy }, { select: (data) => data.connections });

  const availableSources = useMemo(() => {
    return queryType ? sources?.filter((source) => source?.definition?.supportedQueries.includes(queryType)) : sources;
  }, [sources, queryType]);

  const columns: TableColumn[] = [
    {
      name: "Name",
      sortDirection: orderBy?.name,
      onClick: () => onSort(SortKeys.Name),
      cell: (source) => (
        <Row sx={{ alignItems: "center", gap: 4 }}>
          <SourceIcon source={source} sx={{ width: "18px", flexShrink: 0 }} />
          <TextWithTooltip
            sx={{
              fontWeight: "bold",
              maxWidth: "32vw",
              color: "black",
            }}
            text={source.name}
          >
            {source.name}
          </TextWithTooltip>
          <LabelsCell labels={source.tags} />
          <SourceBadges isSampleDataSource={source.definition.isSampleDataSource} />
        </Row>
      ),
    },
    {
      name: "Type",
      sortDirection: orderBy?.type,
      onClick: () => onSort(SortKeys.Type),
      max: "max-content",
      cell: (source) => (
        <Row sx={{ alignItems: "center" }}>
          <TextWithTooltip text={source.definition.name}>{source.definition.name}</TextWithTooltip>
        </Row>
      ),
    },
    {
      ...LastUpdatedColumn,
      max: "max-content",
      sortDirection: orderBy?.updated_at,
      onClick: () => onSort(SortKeys.UpdatedAt),
    },
  ];

  return (
    <ResourceSelect
      columns={columns}
      data={availableSources}
      error={Boolean(error)}
      label="source"
      loading={loading || isRefetching}
      search={search}
      onSearch={setSearch}
      onSelect={onSelect}
    />
  );
};
