import { useEffect, useState, FC } from "react";

import { PlusSmallIcon, FolderOpenIcon, Square2StackIcon, TrashIcon } from "@heroicons/react/24/outline";
import {
  Button,
  Checkbox,
  EditableHeading,
  Menu,
  MenuList,
  MenuDivider,
  MenuItem,
  useToast,
  MenuActionsButton,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import { useFlags } from "launchdarkly-react-client-sdk";
import pluralize from "pluralize";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Grid, Image, Text } from "theme-ui";

import { AudienceExplore } from "src/components/audiences/audience-explore";
import { AudienceTraits } from "src/components/audiences/audience-traits";
import { MoveFolder } from "src/components/folders/move-to-folder";
import { useFolder } from "src/components/folders/use-folder";
import { EditLabels } from "src/components/labels/edit-labels";
import { Labels } from "src/components/labels/labels";
import { useLabels } from "src/components/labels/use-labels";
import { Page } from "src/components/layout";
import { Crumb } from "src/components/layout/header/breadcrumbs";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import { Syncs, SyncSortKeys } from "src/components/models/syncs";
import { Permission } from "src/components/permission";
import { LegacySplits, Splits } from "src/components/splits";
import { Warning } from "src/components/warning";
import { FormErrorProvider } from "src/contexts/form-error-context";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  ResourcePermissionGrant,
  SyncsOrderBy,
  SyncTemplatesForParentModelQuery,
  useAudienceQuery,
  useCreateSyncsMutation,
  useDeleteAudienceMutation,
  useSyncTemplatesForParentModelQuery,
  useUpdateAudienceMutation,
} from "src/graphql";
import useHasPermission from "src/hooks/use-has-permission";
import * as analytics from "src/lib/analytics";
import { isPresent } from "src/types/utils";
import { SplitTestDefinition } from "src/types/visual";
import { Avatar } from "src/ui/avatar";
import { SquareBadge } from "src/ui/badge";
import { Column, Row } from "src/ui/box";
import { ChevronDownIcon, ExternalLinkIcon } from "src/ui/icons";
import { Link } from "src/ui/link";
import { PageSpinner } from "src/ui/loading";
import Logo from "src/ui/Logo";
import { Modal } from "src/ui/modal";
import { Popout } from "src/ui/popout";
import { useTableConfig } from "src/ui/table";
import { Tabs } from "src/ui/tabs";
import { useDestinations } from "src/utils/destinations";
import { EMPTY_AUDIENCE_DEFINITION, QueryState, QueryType, useModelRun, useQueryState, useUpdateQuery } from "src/utils/models";
import { generateSlug } from "src/utils/slug";
import { formatDate } from "src/utils/time";

enum Tab {
  QUERY = "Query",
  SYNCS = "Syncs",
  TRAITS = "Trait enrichments",
  SPLITS = "Splits",
}

type SyncTemplate = SyncTemplatesForParentModelQuery["sync_templates"][0];

export const Audience: FC = () => {
  const { audience_id: id } = useParams<{ audience_id?: string }>();
  const { toast } = useToast();
  const navigate = useNavigate();
  const { user } = useUser();
  const [deleteModal, setDeleteModal] = useState(false);
  const [tab, setTab] = useState<Tab>(Tab.QUERY);
  const [addSyncModal, setAddSyncModal] = useState(false);
  const [editLabelsModal, setEditLabelsModal] = useState(false);
  const [syncTemplatesToAdd, setSyncTemplatesToAdd] = useState<SyncTemplate[]>([]);
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);

  const { appAudienceSplits: splitsEnabled, appPriorityLists, appSplitsV2Enabled } = useFlags();

  const { queryState, initQueryState, setVisualQueryFilter } = useQueryState();
  const updateQuery = useUpdateQuery();

  const { onSort, orderBy } = useTableConfig<SyncsOrderBy>({
    defaultSortKey: "updated_at",
    sortOptions: Object.values(SyncSortKeys),
  });

  const {
    data: audienceData,
    isLoading: modelLoading,
    refetch,
  } = useAudienceQuery(
    {
      id: id ?? "",
      orderBy,
    },
    { enabled: Boolean(id) },
  );
  const { labels } = useLabels();

  const { mutateAsync: updateAudience, isLoading: updating } = useUpdateAudienceMutation();
  const { mutateAsync: deleteAudience } = useDeleteAudienceMutation();
  const { mutateAsync: addSyncs, isLoading: addingSyncs } = useCreateSyncsMutation();

  const audience = audienceData?.segments_by_pk;
  const parentModel = audience?.parent;
  const membership = audience?.priority_list_memberships?.[0];
  const syncs = audience?.syncs;
  const source = audience?.connection;
  const splits = audience?.splits;
  const labelKeys = Object.keys(audience?.labels || {});

  const { data: syncTemplatesData, isLoading: syncTemplatesLoading } = useSyncTemplatesForParentModelQuery(
    { parentModelId: parentModel?.id },
    { enabled: Boolean(parentModel) },
  );

  const usedSyncTemplateIds = syncs?.map(({ sync_template_id }) => sync_template_id);
  const syncTemplates = syncTemplatesData?.sync_templates;
  const usedSyncTemplates = syncTemplates?.filter(({ id }) => usedSyncTemplateIds?.includes(id));
  const availableSyncTemplates = syncTemplates?.filter(({ id }) => !usedSyncTemplateIds?.includes(id));

  const {
    data: { definitions: destinationDefinitions },
  } = useDestinations();

  const save = async (data, queryStateOverride: Partial<QueryState> = {}) => {
    await updateQuery({
      model: audience,
      queryState: {
        ...queryState,
        ...queryStateOverride,
      },
      columns: data?.columns ?? columns,
    });

    onUpdate();
  };

  const saveSplitDefinition = async (splitTestDefinition: SplitTestDefinition | undefined) => {
    const newVisualQueryFilter = { ...(queryState.visualQueryFilter ?? EMPTY_AUDIENCE_DEFINITION), splitTestDefinition };
    setVisualQueryFilter(newVisualQueryFilter);

    return await save(undefined, { visualQueryFilter: newVisualQueryFilter });
  };

  const {
    runQuery,
    getSchema,
    cancelQuery,
    resetRunState,
    loading: queryLoading,
    error: queryError,
    rows,
    numRowsWithoutLimit,
    columns,
    transformedSql,
  } = useModelRun(QueryType.Visual, audience?.parent?.columns, {
    modelId: audience?.id,
    variables: { sourceId: source?.id, parentModelId: parentModel?.id, ...queryState },
  });

  const onUpdate = () => {
    analytics.track("Model Updated", {
      model_id: id,
      model_type: QueryType.Visual,
      model_name: audience?.name,
      source_id: source?.id,
      source_type: source?.type,
    });

    toast({
      id: "update-audience",
      title: "Audience was updated",
      variant: "success",
    });
  };

  const [name, setName] = useState(audience?.name ?? "");

  useEffect(() => {
    setName(audience?.name ?? "");
  }, [audience?.name]);

  const userCanUpdate = useHasPermission([{ resource: "audience", grants: [ResourcePermissionGrant.Update], resource_id: id }]);

  const userCanDelete = useHasPermission([{ resource: "audience", grants: [ResourcePermissionGrant.Delete], resource_id: id }]);

  const userCanCreate = useHasPermission([{ resource: "audience", grants: [ResourcePermissionGrant.Create] }]);

  const saveName = async () => {
    if (!id) {
      return;
    }

    await updateAudience({
      id,
      input: {
        name,
        updated_by: user?.id != null ? String(user?.id) : undefined,
      },
    });

    onUpdate();
  };

  const saveLabels = async (labels: Record<string, string | number>) => {
    if (!id) {
      return;
    }

    await updateAudience({
      id,
      input: {
        tags: labels,
      },
    });

    onUpdate();
  };

  const folder = useFolder({ folderId: audience?.folder?.id, folderType: "audiences", viewType: "models" });

  const onDelete = async () => {
    if (!id) {
      return;
    }

    try {
      await deleteAudience({ id });

      analytics.track("Model Deleted", {
        model_id: id,
        model_type: QueryType.Visual,
        model_name: audience?.name,
        source_id: source?.id,
        source_type: source?.type,
      });

      navigate("/audiences");
    } catch (error) {
      toast({
        id: "delete-audience",
        title: "Failed to delete this audience",
        variant: "error",
      });

      Sentry.captureException(error);

      throw error;
    }
  };

  useEffect(() => {
    initQueryState(audience);
  }, [audience]);

  useEffect(() => {
    refetch();
  }, [tab]);

  if (modelLoading || syncTemplatesLoading) {
    return <PageSpinner />;
  }

  const TABS = [
    Tab.QUERY,
    {
      value: splitsEnabled && Tab.SPLITS,
      render: () => (
        <Row sx={{ alignItems: "center" }}>
          <Text>Splits</Text>
          {Array.isArray(splits) && splits.length > 0 && <SquareBadge sx={{ ml: 2 }}>{splits.length}</SquareBadge>}
        </Row>
      ),
    },
    {
      render: () => (
        <Row sx={{ alignItems: "center" }}>
          <Text>Syncs</Text>
          {Array.isArray(syncs) && syncs.length > 0 && <SquareBadge sx={{ ml: 2 }}>{syncs.length}</SquareBadge>}
        </Row>
      ),
      value: Tab.SYNCS,
    },
    Tab.TRAITS,
  ].filter(isPresent);

  const onAddSync = () => {
    if (Array.isArray(syncTemplates) && syncTemplates.length > 0) {
      setAddSyncModal(true);
    } else {
      navigate(`/syncs/new?model=${id}`);
    }
  };

  if (!modelLoading && !syncTemplatesLoading && !audience) {
    return <Warning subtitle="It may have been deleted" title="Audience not found" />;
  }

  const crumbs: Crumb[] = [{ label: "Audiences", link: "/audiences" }];

  if (folder?.path) {
    folder.path.split("/").forEach((path) => {
      crumbs.push({
        label: path,
        link: "/audiences?folder=" + folder.id,
      });
    });
  }

  crumbs.push({
    label: audience?.name ?? "",
  });

  return (
    <>
      <PermissionProvider permissions={[{ resource: "audience", grants: [ResourcePermissionGrant.Update] }]}>
        <Page
          crumbs={crumbs}
          sx={{
            flex: 1,
            minHeight: 0,
            pb: 8,
          }}
          title={`${audience?.name ?? "Unnamed audience"} - Audiences`}
        >
          <Column sx={{ width: "100%", height: "100%", minWidth: "700px" }}>
            <Row
              sx={{
                width: "100%",
                justifyContent: "space-between",
                flex: "none",
              }}
            >
              <Column sx={{ mb: 6, width: "100%" }}>
                <Row sx={{ alignItems: "center", width: "100%", justifyContent: "space-between" }}>
                  <EditableHeading
                    isDisabled={!userCanUpdate.hasPermission}
                    size="lg"
                    value={name}
                    onChange={setName}
                    onSubmit={saveName}
                  />
                  <Row gap={3} sx={{ alignItems: "center", ml: 8 }}>
                    {(userCanUpdate || userCanCreate || userCanDelete) && (
                      <Menu>
                        <MenuActionsButton />

                        <MenuList>
                          {userCanUpdate && (
                            <MenuItem
                              icon={FolderOpenIcon}
                              onClick={() => {
                                setIsFolderModalOpen(true);
                              }}
                            >
                              Move to folder
                            </MenuItem>
                          )}

                          {userCanCreate && (
                            <MenuItem
                              icon={Square2StackIcon}
                              onClick={() => {
                                navigate(`/audiences/${id}/clone`);
                              }}
                            >
                              Clone
                            </MenuItem>
                          )}

                          {(userCanUpdate || userCanCreate) && userCanDelete && <MenuDivider />}

                          {userCanDelete && (
                            <MenuItem
                              icon={TrashIcon}
                              variant="danger"
                              onClick={() => {
                                setDeleteModal(true);
                              }}
                            >
                              Delete
                            </MenuItem>
                          )}
                        </MenuList>
                      </Menu>
                    )}

                    <Permission permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Create] }]}>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          onAddSync();
                        }}
                      >
                        Add sync
                      </Button>
                    </Permission>
                  </Row>
                </Row>
                <Row sx={{ mt: 2 }}>
                  <Row sx={{ alignItems: "center", pr: 4, mr: 4, borderRight: "small" }}>
                    <Logo logoUrl={source?.definition?.icon} name={source?.definition?.name ?? ""} />
                    <Text sx={{ mx: 2, fontWeight: "semi" }}>{parentModel?.name}</Text>
                    <Link to={`/audiences/setup/parent-models/${parentModel?.id}`}>
                      <Box sx={{ color: "primary", ":hover": { color: "secondary" } }}>
                        <ExternalLinkIcon size={14} />
                      </Box>
                    </Link>
                  </Row>
                  <Row sx={{ alignItems: "center" }}>
                    <Text sx={{ mr: 1, color: "base.6" }}>Last updated:</Text>
                    <Text sx={{ mr: 1 }}>{formatDate(audience?.updated_at || audience?.created_at)} by</Text>
                    <Avatar name={audience?.updated_by_user?.name || audience?.created_by_user?.name} />
                  </Row>
                  {appPriorityLists && membership && (
                    <Row sx={{ alignItems: "center", borderLeft: "small", ml: 4, pl: 4, wrap: "nowrap" }}>
                      <Text sx={{ display: "flex", alignItems: "center" }}>
                        #{membership.rank + 1} in
                        <Link
                          sx={{ display: "flex", alignItems: "center", ml: 2 }}
                          to={`/audiences/priority-lists/${membership.priority_list.id}`}
                        >
                          <Box
                            sx={{ color: "primary", display: "flex", alignItems: "center", ":hover": { color: "secondary" } }}
                          >
                            {membership.priority_list.name}
                            <ExternalLinkIcon size={14} sx={{ ml: 2 }} />
                          </Box>
                        </Link>
                      </Text>
                    </Row>
                  )}
                  {labelKeys?.length > 0 && (
                    <Row sx={{ alignItems: "center", pl: 4, ml: 4, borderLeft: "small" }}>
                      <Popout
                        content={({ close }) => (
                          <>
                            <Labels labels={audience?.labels} />
                            <Permission
                              permissions={[
                                { resource: "audience", grants: [ResourcePermissionGrant.Update], resource_id: audience?.id },
                              ]}
                            >
                              <Button
                                mt={4}
                                size="sm"
                                variant="secondary"
                                onClick={() => {
                                  setEditLabelsModal(true);
                                  close();
                                }}
                              >
                                Edit labels
                              </Button>
                            </Permission>
                          </>
                        )}
                        contentSx={{ p: 3 }}
                      >
                        <Text sx={{ mr: 1 }}>Labels</Text>
                        <SquareBadge>{labelKeys.length}</SquareBadge>
                        <ChevronDownIcon size={16} sx={{ ml: 2 }} />
                      </Popout>
                    </Row>
                  )}
                  {!labelKeys?.length && (
                    <Permission
                      permissions={[
                        { resource: "audience", grants: [ResourcePermissionGrant.Update], resource_id: audience?.id },
                      ]}
                    >
                      <Row sx={{ pl: 4, ml: 4, borderLeft: "small" }}>
                        <Button
                          icon={PlusSmallIcon}
                          size="sm"
                          variant="secondary"
                          onClick={() => {
                            setEditLabelsModal(true);
                          }}
                        >
                          Add labels
                        </Button>
                      </Row>
                    </Permission>
                  )}
                </Row>
              </Column>
            </Row>

            <Tabs setTab={(tab) => setTab(tab as Tab)} sx={{ flex: "none", mb: 8 }} tab={tab} tabs={TABS} />

            {tab === Tab.QUERY && (
              <FormErrorProvider>
                <AudienceExplore
                  audience={audience}
                  cancelQuery={cancelQuery}
                  columns={columns}
                  error={queryError}
                  getSchema={getSchema}
                  loading={queryLoading}
                  numRowsWithoutLimit={numRowsWithoutLimit}
                  parentModel={parentModel}
                  reset={resetRunState}
                  rows={rows}
                  runQuery={runQuery}
                  source={source}
                  transformedSql={transformedSql}
                  onSave={save}
                  onVisualQueryFilterChange={setVisualQueryFilter}
                  {...queryState}
                  rowsPerPage={15}
                />
              </FormErrorProvider>
            )}

            {tab === Tab.SYNCS && <Syncs isAudience orderBy={orderBy} syncs={syncs} onAdd={onAddSync} onSort={onSort} />}

            {tab === Tab.TRAITS && audience && <AudienceTraits audience={audience} />}

            {tab === Tab.SPLITS &&
              audience &&
              queryState.visualQueryFilter &&
              (appSplitsV2Enabled ? (
                <Splits
                  audience={audience}
                  data={queryState.visualQueryFilter?.splitTestDefinition}
                  onAddSync={onAddSync}
                  onSave={saveSplitDefinition}
                />
              ) : (
                <LegacySplits
                  audience={audience}
                  data={queryState.visualQueryFilter?.splitTestDefinition}
                  onSave={saveSplitDefinition}
                />
              ))}
          </Column>
        </Page>
      </PermissionProvider>

      <Modal
        footer={
          <>
            <Button
              variant="secondary"
              onClick={() => {
                setAddSyncModal(false);
              }}
            >
              Cancel
            </Button>

            <Button
              isDisabled={syncTemplatesToAdd?.length > 0}
              variant="secondary"
              onClick={() => navigate(`/syncs/new?model=${id}`)}
            >
              Create new sync
            </Button>
            <Button
              isDisabled={syncTemplatesToAdd?.length === 0}
              isLoading={addingSyncs}
              onClick={async () => {
                await addSyncs({
                  objects: syncTemplatesToAdd.map((template) => ({
                    segment_id: id,
                    sync_template_id: template.id,
                    slug: generateSlug(template.name),
                  })),
                });

                setSyncTemplatesToAdd([]);
                analytics.track("Model Deleted", {
                  model_id: id,
                  model_type: QueryType.Visual,
                  model_name: audience?.name,
                  source_id: source?.id,
                  source_type: source?.type,
                });

                setAddSyncModal(false);

                toast({
                  id: "add-sync-to-audience",
                  title: `${pluralize("sync", syncTemplatesToAdd.length, true)} added`,
                  variant: "success",
                });
              }}
            >
              Add syncs
            </Button>
          </>
        }
        isOpen={addSyncModal}
        sx={{ width: "900px" }}
        title="Select sync templates"
        onClose={() => {
          setAddSyncModal(false);
        }}
      >
        <Grid gap={6}>
          {availableSyncTemplates?.length === 0 ? (
            <Text sx={{ color: "base.7", fontWeight: "semi" }}>
              You have no available sync templates.{" "}
              <Link to="/audiences/setup/sync-templates/new">Create a new sync template</Link> to easily sync audiences to other
              destinations.
            </Text>
          ) : (
            <Text sx={{ color: "base.7", fontWeight: "semi" }}>
              Select one or more sync templates below. Templates are pre-configured to work with this Audience's data source. If
              your destination isn't listed below, <Link to={`/syncs/new?model=${id}`}>create a new sync</Link>.
            </Text>
          )}

          {Array.isArray(availableSyncTemplates) && availableSyncTemplates.length > 0 && (
            <Column>
              <Text sx={{ fontWeight: "semi", color: "base.6", textTransform: "uppercase", fontSize: 0, mb: 3 }}>
                Available sync templates
              </Text>

              <Grid gap={3}>
                {availableSyncTemplates?.map((syncTemplate) => {
                  const { id, name, destination } = syncTemplate;
                  const destinationDefinition = destinationDefinitions?.find(({ type }) => type === destination.type);
                  const checked = Boolean(syncTemplatesToAdd.find((template) => template.id === id));

                  return (
                    <Row
                      key={id}
                      sx={{
                        alignItems: "center",
                        justifyContent: "space-between",
                        p: 3,
                        borderRadius: 1,
                        bg: checked ? "primaries.0" : "white",
                        border: "small",
                        borderColor: checked ? "primaries.2" : "white",
                        cursor: "pointer",
                        transition: "100ms all",
                      }}
                      onClick={(event) => {
                        // Ignore click event on checkbox's input
                        if ((event.target as HTMLElement).matches("input")) {
                          return;
                        }

                        if (checked) {
                          setSyncTemplatesToAdd((templates) => templates.filter((template) => template.id !== id));
                        } else {
                          setSyncTemplatesToAdd((templates) => [...templates, syncTemplate]);
                        }
                      }}
                    >
                      <Row sx={{ alignItems: "center" }}>
                        <Checkbox
                          isChecked={checked}
                          onChange={() => {
                            // Checkbox state is managed above
                          }}
                        />
                        <Text sx={{ fontWeight: "semi", ml: 2 }}>{name}</Text>
                      </Row>

                      <Row sx={{ alignItems: "center", ml: 4 }}>
                        <Image src={destinationDefinition?.icon} width={24} />

                        <Column sx={{ ml: 4 }}>
                          <Text sx={{ fontSize: "10px", color: "base.4", fontWeight: "bold", textTransform: "uppercase" }}>
                            Destination
                          </Text>
                          <Text sx={{ fontWeight: "semi" }}>{destination.name || destinationDefinition?.name}</Text>
                        </Column>
                      </Row>

                      <Link to={`/audience/setup/sync-templates/${id}`}>
                        <ExternalLinkIcon color="base.3" size={16} />
                      </Link>
                    </Row>
                  );
                })}
              </Grid>
            </Column>
          )}

          {Array.isArray(usedSyncTemplates) && usedSyncTemplates.length > 0 && (
            <Column>
              <Text sx={{ fontWeight: "semi", color: "base.6", textTransform: "uppercase", fontSize: 0, mb: 3 }}>
                Active sync templates
              </Text>
              <Grid gap={3}>
                {usedSyncTemplates?.map((syncTemplate) => {
                  const { id, name, destination } = syncTemplate;
                  return (
                    <Row
                      key={id}
                      sx={{
                        alignItems: "center",
                        justifyContent: "space-between",
                        p: 3,
                        borderRadius: 1,
                        bg: "base.2",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate(`/audiences/setup/sync-templates/${id}`)}
                    >
                      <Row sx={{ alignItems: "center" }}>
                        <Text sx={{ fontWeight: "semi" }}>{name}</Text>
                      </Row>

                      <Row sx={{ alignItems: "center", ml: 4 }}>
                        <Column>
                          <Text sx={{ fontSize: "10px", color: "base.4", fontWeight: "bold", textTransform: "uppercase" }}>
                            Destination
                          </Text>
                          <Text sx={{ fontWeight: "semi" }}>{destination.name}</Text>
                        </Column>
                      </Row>

                      <ExternalLinkIcon color="base.3" size={16} />
                    </Row>
                  );
                })}
              </Grid>
            </Column>
          )}
        </Grid>
      </Modal>

      <DeleteConfirmationModal
        isOpen={deleteModal}
        label="audience"
        onClose={() => {
          setDeleteModal(false);
        }}
        onDelete={onDelete}
      />

      {audience && isFolderModalOpen && (
        <MoveFolder
          folder={audience.folder}
          folderType="audiences"
          modelIds={[audience.id]}
          viewType="models"
          onClose={() => setIsFolderModalOpen(false)}
        />
      )}

      <EditLabels
        description="You can label audiences that have similar properties"
        existingLabelOptions={labels}
        hint="Example keys: team, project, region, env."
        isOpen={editLabelsModal}
        labels={audience?.labels}
        loading={updating}
        saveLabel="Save"
        title="Edit labels"
        onClose={() => setEditLabelsModal(false)}
        onSave={async (labels) => {
          await saveLabels(labels);
          setEditLabelsModal(false);
        }}
      />
    </>
  );
};
