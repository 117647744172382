import { FC, useCallback, ReactNode } from "react";

import { LinkButton, Row, Heading } from "@hightouchio/ui";

import sequencePlaceholder from "src/assets/placeholders/sequence.svg";
import { FeaturePreview } from "src/components/feature-gates";
import { Page } from "src/components/layout";
import { PageAlert } from "src/components/page-alert";
import { PermissionedLinkButton } from "src/components/permissioned-button";
import { SequenceStatusBadge } from "src/components/sequences/sequence-status-badge";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import { ResourcePermissionGrant, SyncSequencesOrderBy, useSequencesQuery } from "src/graphql";
import * as analytics from "src/lib/analytics";
import { PageSpinner } from "src/ui/loading";
import { Pagination, Table, TableColumn, useTableConfig } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { Placeholder } from "src/ui/table/placeholder";
import { useNavigate } from "src/utils/navigate";
import { formatDatetime } from "src/utils/time";
import { openUrl } from "src/utils/urls";

enum SortKeys {
  Name = "name",
  UpdatedAt = "updated_at",
  LastRunAt = "last_run_at",
}

export const Sequences: FC = () => {
  const { workspace, resources } = useUser();
  const navigate = useNavigate();

  const { limit, offset, orderBy, page, setPage, onSort } = useTableConfig<SyncSequencesOrderBy>({
    defaultSortKey: "updated_at",
    sortOptions: Object.values(SortKeys),
  });

  const { data, isLoading: loading, error: sequencesError } = useSequencesQuery({ orderBy, limit, offset });

  const sequences = data?.sync_sequences;
  const sequencesCount = data?.sync_sequences_aggregate?.aggregate?.count ?? 0;

  const onRowClick = useCallback(({ id }, event) => openUrl(`/sequences/${id}`, navigate, event), [navigate]);

  const columns: TableColumn[] = [
    {
      name: "Status",
      key: "runs.[0].status",
      cell: (lastRunStatus) => <SequenceStatusBadge status={lastRunStatus} />,
    },
    {
      key: "name",
      name: "Name",
      sortDirection: orderBy?.name,
      onClick: () => onSort(SortKeys.Name),
    },
    {
      key: "last_run_at",
      name: "Last run",
      sortDirection: orderBy?.last_run_at,
      onClick: () => onSort(SortKeys.LastRunAt),
      cell: (timestamp) => formatDatetime(timestamp),
    },
    {
      ...LastUpdatedColumn,
      sortDirection: orderBy?.updated_at,
      onClick: () => onSort(SortKeys.UpdatedAt),
    },
  ];

  if (loading) {
    return <PageSpinner />;
  }

  if (!sequences?.length) {
    return (
      <SequencesPage
        pageAlert={
          resources?.canSequence ? null : (
            <PageAlert
              button={
                <LinkButton href="/syncs" variant="primary">
                  Go to syncs
                </LinkButton>
              }
              description="Sequences consist of multiple related syncs that are triggered in a specific order. Your workspace must have 2+ syncs before you can use this feature."
              title="First, you need to configure at least two syncs"
            />
          )
        }
      >
        <Heading mb={8} size="xl">
          Sequences
        </Heading>
        <Placeholder
          content={{
            image: sequencePlaceholder,
            title: "No sequences in this workspace",
            body: "Sequences help you trigger a series of related syncs in a predefined order. This feature offers an easier way to orchestrate syncs without using a tool like Airflow.",
            button: resources?.canSequence ? (
              <PermissionedLinkButton
                href="/sequences/new"
                permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Create] }]}
                variant="primary"
                onClick={() => {
                  analytics.track("Add Sequence Clicked");
                }}
              >
                Add sequence
              </PermissionedLinkButton>
            ) : null,
          }}
        />
      </SequencesPage>
    );
  }

  return (
    <SequencesPage>
      <Row sx={{ alignItems: "center", justifyContent: "space-between", mb: 8 }}>
        <Heading size="xl">Sequences</Heading>
        <PermissionedLinkButton
          href="/sequences/new"
          permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Create] }]}
          variant="primary"
          onClick={() => {
            analytics.track("Add Sequence Clicked");
          }}
        >
          Add sequence
        </PermissionedLinkButton>
      </Row>
      <FeaturePreview
        enabled={workspace?.organization?.plan?.sku === "business_tier"}
        featureDetails={{
          pitch: "Flexible orchestration tool for managing complex sequences of syncs",
          description:
            "Sync sequences let you define the order in which multiple syncs should run for a given use case. When you need to send data involving multiple objects, Hightouch can ensure that those syncs are executed correctly.",
          bullets: [
            "Specify the order in which Hightouch should run a series of syncs",
            "Hydrate your data for use cases involving dependent objects, like accounts and contacts",
            "Avoid brittle point-to-point APIs or manual cron jobs",
          ],
          image: {
            src: "https://cdn.sanity.io/images/pwmfmi47/production/5753e43bf95780d5c3dd96ed644ba956393b6797-865x507.png",
          },
        }}
        featureName="sync sequences"
        mb={8}
        variant="full"
      />
      <Table
        columns={columns}
        data={sequences}
        error={Boolean(sequencesError)}
        placeholder={{
          error: "Sequences failed to load, please try again.",
        }}
        onRowClick={onRowClick}
      />
      <Pagination count={sequencesCount} label="sequences" page={page} rowsPerPage={limit} setPage={setPage} />
    </SequencesPage>
  );
};

const SequencesPage: FC<Readonly<{ children: ReactNode; pageAlert?: ReactNode }>> = ({ children, pageAlert }) => (
  <PermissionProvider permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Create] }]}>
    <Page fullWidth outsideTopbar={pageAlert} title="Sequences">
      {children}
    </Page>
  </PermissionProvider>
);
