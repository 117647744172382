import { useState, FC } from "react";

import Helmet from "react-helmet";

import { DraftSubmissionModal } from "src/components/modals/draft-submission-modal";
import { useUser } from "src/contexts/user-context";
import { DraftOperation, ResourceToPermission } from "src/graphql";
import { Wizard } from "src/ui/wizard";
import { useNavigate } from "src/utils/navigate";

import { useCreateModelWizard } from "./use-create-model-wizard";

export const CreateModel: FC = () => {
  const navigate = useNavigate();
  const { workspace } = useUser();
  const [submitDraftModalOpen, setSubmitDraftModalOpen] = useState<boolean>(false);

  const goToModel = (id: string) => {
    navigate(`/models/${id}`);
  };

  const { createModel, setStep, step, steps } = useCreateModelWizard({});

  return (
    <>
      <Helmet>
        <title>New model</title>
      </Helmet>

      <DraftSubmissionModal
        draft={{
          _set: {
            draft: false, // the draft change is to set the column `draft` to false.
          },
        }}
        getResourceId={async () => {
          const model = await createModel();
          return model?.id;
        }}
        open={submitDraftModalOpen}
        operation={DraftOperation.Create}
        resource={ResourceToPermission.Model}
        onClose={() => setSubmitDraftModalOpen(false)}
        onSubmit={(id) => {
          goToModel(id);
        }}
      />

      <Wizard
        setStep={setStep}
        step={step}
        steps={steps}
        title="New model"
        onCancel={() => {
          navigate("/models");
        }}
        onSubmit={async () => {
          if (workspace?.approvals_required) {
            setSubmitDraftModalOpen(true);
          } else {
            const model = await createModel();
            if (model?.id) {
              goToModel(model?.id);
            }
          }
        }}
      />
    </>
  );
};
