import { useState, FC } from "react";

import { Box, Container, Flex, Image } from "theme-ui";

import { DiagramIllustrations, EducationModal, Popover, Tile } from "src/components/onboarding";
import * as analytics from "src/lib/analytics";
import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Heading } from "src/ui/heading";
import { PlusSmallIcon } from "src/ui/icons";
import { SourceBadges, SourceIcon } from "src/utils/sources";

import { fontSizes } from "../../../../design";
import { colors } from "../../../../design/colors";
import { pulsatingBorder } from "./animation";
import { OnboardingActions, OnboardingData } from "./onboarding-reducer";

type Props = {
  onboardingData: OnboardingData;
  handleOnboarding: (type) => void;
  onSetOnboardingData: (action: OnboardingActions) => void;
};

export const DiagramDeclaration: FC<Props> = ({ onboardingData, onSetOnboardingData, handleOnboarding }) => {
  const sourceName = onboardingData.declareSource?.name;
  const destinationName = onboardingData.declareDestination?.name;
  const [educationModalOpen, setEducationModalOpen] = useState(false);

  const emptySource = { definition: onboardingData?.declareSource };

  return (
    <>
      <Heading
        sx={{
          my: 10,
          fontSize: fontSizes[5],
          fontWeight: 600,
        }}
        variant="h1"
      >
        Select a source and destination for your data
      </Heading>
      <Row sx={{ width: "100%" }}>
        <Container sx={{ maxWidth: "960px", p: 8 }}>
          <Flex
            sx={{
              justifyContent: "space-between",
              fontSize: fontSizes[2],
              fontWeight: 500,
              color: "base.7",
            }}
          >
            <Box
              sx={{
                border: `2px solid ${colors.base[3]}`,
                width: "40%",
                borderRadius: 8,
                animation: !sourceName ? pulsatingBorder({ variant: "secondary" }) : undefined,
              }}
            >
              <Tile
                active={!sourceName}
                badge={<SourceBadges isSampleDataSource={emptySource.definition?.isSampleDataSource} />}
                icon={
                  emptySource.definition ? (
                    <SourceIcon source={emptySource as any} sx={{ width: "36px" }} />
                  ) : (
                    <PlusSmallIcon size={24} />
                  )
                }
                popoverAsTooltip={true}
                popoverContent="selectSource"
                showChange={Boolean(sourceName)}
                subtitle={sourceName ? "Source" : undefined}
                title={sourceName || "Select source"}
                onChangeClick={() => handleOnboarding("declareSource")}
                onClick={() => handleOnboarding("declareSource")}
              />
            </Box>
            <Box
              sx={{
                border: `2px solid ${colors.base[3]}`,
                width: "40%",
                borderRadius: 8,
                animation: sourceName && !destinationName ? pulsatingBorder({ variant: "secondary" }) : undefined,
              }}
            >
              <Tile
                active={!onboardingData.declareDestination}
                icon={
                  destinationName ? (
                    <Image alt={destinationName} src={onboardingData?.declareDestination?.icon} width={36} />
                  ) : (
                    <PlusSmallIcon size={24} />
                  )
                }
                popoverAsTooltip={true}
                popoverContent="selectDestination"
                showChange={Boolean(destinationName)}
                subtitle={destinationName && "Destination"}
                title={destinationName ? destinationName : "Select destination"}
                onChangeClick={destinationName ? () => handleOnboarding("declareDestination") : undefined}
                onClick={() => handleOnboarding("declareDestination")}
              />
            </Box>
          </Flex>
          <DiagramIllustrations />
          <Row>
            <Flex sx={{ width: "100%", justifyContent: "center", alignItems: "top", mt: 5 }}>
              <Popover content="createYourFirstSyncDisabled" disabled={Boolean(sourceName && destinationName)}>
                <Button
                  disabled={!sourceName || !destinationName}
                  size="large"
                  sx={{
                    mt: 1,
                    borderWidth: "2px",
                    animation: sourceName && destinationName ? pulsatingBorder({ variant: "primary" }) : undefined,
                  }}
                  variant="primary"
                  onClick={() => setEducationModalOpen(true)}
                >
                  Continue setup &rarr;
                </Button>
              </Popover>
            </Flex>
          </Row>
        </Container>
      </Row>
      <EducationModal
        destinationIcon={onboardingData?.declareDestination?.icon}
        destinationTitle={onboardingData?.declareDestination?.name}
        isOpen={educationModalOpen}
        sourceIcon={onboardingData?.declareSource?.icon}
        sourceTitle={onboardingData?.declareSource?.name}
        onCancel={() => setEducationModalOpen(false)}
        onConfirm={() => {
          onSetOnboardingData({ type: "set.declareComplete", complete: true });
          analytics.track("Workspace Onboarding Started", {
            source_type: onboardingData.declareSource?.name,
            destination_type: onboardingData.declareDestination?.name,
          });
          setEducationModalOpen(false);
        }}
      />
    </>
  );
};
