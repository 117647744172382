import { FC, useEffect, useState } from "react";

import { PlusIcon } from "@heroicons/react/24/solid";
import { Box, Code, Column, Heading, Row, Spinner, Text } from "@hightouchio/ui";
import { format, parseISO } from "date-fns";

import emptyStateImage from "src/components/resource-activity/empty-state.svg";
import { useUser } from "src/contexts/user-context";
import {
  ParsedDiff,
  parseDiff,
  QueryableChangeLogResource,
  ResourceActivity,
  useResourceActivity,
} from "src/hooks/use-resource-activity";
import { Placeholder } from "src/ui/table/placeholder";

import { FeaturePreview } from "../feature-gates";
import { ActivityItem } from "./item";

export type ResourceActivityMapper = {
  parser: (activity: ResourceActivity, parsedDiff: ParsedDiff) => DisplayedResourceActivity | null;
  accessor: string;
  overrideDiffAccessor?: Record<string, unknown>;
};

interface Props {
  resourceId: string;
  mappers: ResourceActivityMapper[];
  resource: QueryableChangeLogResource;
  defaultMapper?: (props: DefaultActivityProps) => DisplayedResourceActivity;
  resourceNameOverride?: string;
}

interface DefaultActivityProps {
  activity: ResourceActivity;
  resource: string;
}

export interface DisplayedResourceActivity {
  message: string | JSX.Element;
  changes?: (string | JSX.Element)[];
  isCreation?: boolean;
  icon?: JSX.Element;
}

export function normalizeName(name: string): string {
  // Split camelcase but keep letter
  const words = name.replaceAll("_", " ").split(/(?=[A-Z])/);
  const normalizedWords = words.map((word) => word.toLowerCase()).filter((word) => word.length > 0);
  return normalizedWords.join(" ");
}

const defaultResourceMapper = ({ activity, resource }: DefaultActivityProps): DisplayedResourceActivity | null => {
  if (!activity.metadata.old && activity.metadata.new) {
    return {
      message: `${resource} created by ${activity.metadata.user_name}`,
      isCreation: true,
      icon: <PlusIcon />,
    };
  }
  // This should only occur when a draft is approved to create a resource
  if (!activity.diff) {
    return null;
  }

  const topLevelKeys = Object.keys(activity.diff).map(normalizeName);
  if (topLevelKeys.length === 0) {
    return {
      message: (
        <Text>
          {activity.metadata.user_name} updated {resource}
        </Text>
      ),
    };
  }
  if (topLevelKeys.length === 1) {
    return {
      message: (
        <Text>
          {activity.metadata.user_name} updated <Code>{topLevelKeys[0]}</Code>
        </Text>
      ),
    };
  }
  return {
    message: (
      <Text>
        {activity.metadata.user_name} updated {resource.toLowerCase()} properties
      </Text>
    ),
    changes: topLevelKeys,
  };
};

// TODO: remove this when we get a new subtle text variant in Hightouch UI
export const SubtleText: FC<{ children: any }> = ({ children }) => {
  return <span style={{ fontSize: "12px", fontWeight: 500, lineHeight: "16px", color: "#697586" }}>{children}</span>;
};

const NoResourceActivity = ({ resource, period }: { resource: string; period: "all" | "week" }) => {
  return (
    <Placeholder
      content={{
        image: emptyStateImage,
        title: period === "week" ? `No activity in the last week` : `No recent activity`,
        body: `This log shows all changes made to the ${resource.toLowerCase()}, when they occurred and by whom.`,
      }}
    />
  );
};

export const ResourceActivityTimeline: FC<Props> = ({
  resourceId,
  resource,
  mappers,
  defaultMapper = defaultResourceMapper,
  resourceNameOverride,
}) => {
  const { workspace } = useUser();
  const period = workspace?.organization?.plan?.sku === "business_tier" ? "all" : "week";
  const resourceName = resourceNameOverride || resource;

  const [dateFormattedActivities, setDateFormattedActivities] = useState<{
    [date: string]: { formattedActivity: DisplayedResourceActivity[]; activity: ResourceActivity }[];
  }>({});

  const { activity: resourceActivities, loading, attributionLoading } = useResourceActivity({ resourceId, period, resource });

  useEffect(() => {
    setDateFormattedActivities({});
    if (!resourceActivities) {
      return;
    }

    for (const activity of resourceActivities) {
      const mappedActivity = mappers
        .map((mapper) => {
          const parsedDiff = parseDiff(activity, mapper.accessor, mapper.overrideDiffAccessor);
          if (!parsedDiff) {
            return null;
          }
          return mapper.parser(activity, parsedDiff);
        })
        .filter((activity) => activity !== null) as DisplayedResourceActivity[];

      let formattedChanges = mappedActivity;
      if (mappedActivity.length === 0) {
        const defaultActivity = defaultMapper({ activity, resource: resourceName });
        if (!defaultActivity) {
          continue;
        }
        formattedChanges = [defaultActivity];
      }
      setDateFormattedActivities((prev) => {
        const date = format(parseISO(activity.metadata.created_at), "PP");
        return {
          ...prev,
          [date]: [...(prev[date] || []), { formattedActivity: formattedChanges, activity }],
        };
      });
    }
  }, [resourceActivities, mappers, defaultMapper, period, loading]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Column>
      <Heading mb={4}>Recent activity</Heading>
      <Column gap={8}>
        <FeaturePreview
          enabled={workspace?.organization?.plan?.sku === "business_tier"}
          featureDetails={{
            image: {
              src: "https://cdn.sanity.io/images/pwmfmi47/production/e1741531ea3970f965294cb386f900bd1c149e28-856x518.png",
            },
            pitch: `View a timeline of changes to your ${resourceName.toLowerCase()}`,
            description: `With activity history, you can granularly track changes to the schedule, labels, and configuration of your ${resourceName.toLowerCase()}.`,
            bullets: [
              `Track and view the state of a ${resourceName.toLowerCase()} at any point in time`,
              `Gain insight into who approved and merged changes to the ${resourceName.toLowerCase()}`,
              `Correlate changes to your ${resourceName.toLowerCase()} with changes to your data`,
            ],
          }}
          featureName={`${resourceName.toLowerCase()} activity history`}
          variant="limited"
        />
        {resourceActivities?.length === 0 ? (
          <NoResourceActivity period={period} resource={resourceName} />
        ) : (
          <Box
            display="grid"
            gap={4}
            gridTemplateColumns="max-content max-content min-content"
            justifyContent="space-between"
            width="100%"
          >
            {/* We need to group activities by date with a divider between them labelled with the date */}
            {Object.entries(dateFormattedActivities).map(([date, activities], idx) => {
              return (
                <>
                  <Row key={idx} alignItems="center" bg="gray.100" gridColumn="1 / 4" mt={2} p={2}>
                    {/* We need to do this because we don't yet have an official subtle text variant */}
                    <Box as="span" color="gray.600" fontSize="sm" fontWeight="semibold">
                      {date}
                    </Box>
                  </Row>

                  {activities.map(({ formattedActivity, activity }) => (
                    <ActivityItem
                      key={activity.metadata.id}
                      activity={activity}
                      attributionLoading={attributionLoading}
                      formattedChanges={formattedActivity}
                      icon={formattedActivity[0]?.icon}
                    />
                  ))}
                </>
              );
            })}
          </Box>
        )}
      </Column>
    </Column>
  );
};
