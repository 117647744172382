import { useEffect, useState, FC } from "react";

import { FolderOpenIcon, ArrowPathIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Box, Switch, Menu, MenuActionsButton, MenuList, MenuDivider, MenuItem, useToast } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { isEqual, isMatch } from "lodash";
import pluralize from "pluralize";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Text } from "theme-ui";

import { DestinationForm } from "src/components/destinations/sync-form";
import { DraftCircle } from "src/components/drafts/draft-circle";
import { EditingDraftWarning } from "src/components/drafts/draft-warning";
import { MoveFolder } from "src/components/folders/move-to-folder";
import { useFolder } from "src/components/folders/use-folder";
import { GitActivity } from "src/components/git/git-activity";
import { EditLabels } from "src/components/labels/edit-labels";
import { Labels } from "src/components/labels/labels";
import { useLabels } from "src/components/labels/use-labels";
import { Page } from "src/components/layout";
import { Crumb } from "src/components/layout/header/breadcrumbs";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import { OverageContentAlert } from "src/components/overage/overage-content-alert";
import { SidebarForm } from "src/components/page";
import { Permission } from "src/components/permission";
import { ResourceActivityTimeline } from "src/components/resource-activity/timeline";
import { Schedule, ScheduleManager } from "src/components/schedule";
import { ScheduleType } from "src/components/schedule/types";
import { DisplaySlug } from "src/components/slug/display-slug";
import { SplitsSyncAssignmentForm } from "src/components/splits/splits-sync-assignment-form";
import { Runs } from "src/components/syncs/runs";
import { SyncAlerts } from "src/components/syncs/sync-alerts";
import { SyncName } from "src/components/syncs/sync-name";
import { WarehouseSyncLogs } from "src/components/syncs/warehouse-sync-logs";
import { Warning } from "src/components/warning";
import { DraftProvider, useDraft } from "src/contexts/draft-context";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  MinimalSyncQuery,
  ResourcePermissionGrant,
  SyncRunsQuery,
  useDeleteSyncMutation,
  useExternalSegmentsQuery,
  useStartSyncRunMutation,
  useSyncQuery,
  useUpdateSyncMutation,
  useUpdateSyncRequestMutation,
  useSequencesForSyncQuery,
  ResourceToPermission,
  SyncDraft,
  SyncQuery,
  useMinimalSyncQuery,
  useUpdateSplitSyncAssignmentsMutation,
  useStartSyncRunsForSegmentMutation,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import useHasPermission from "src/hooks/use-has-permission";
import * as analytics from "src/lib/analytics";
import { SquareBadge } from "src/ui/badge";
import { Column, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Field } from "src/ui/field";
import { ChevronDownIcon, ExternalLinkIcon, InfoIcon, PlayIcon, PlusIcon } from "src/ui/icons";
import { Link } from "src/ui/link";
import { PageSpinner, Spinner } from "src/ui/loading";
import { Message } from "src/ui/message";
import { Popout } from "src/ui/popout";
import { Table } from "src/ui/table";
import { Tabs } from "src/ui/tabs";
import { useDestination } from "src/utils/destinations";
import { useIncrementalQuery } from "src/utils/incremental-query";
import { QueryType } from "src/utils/models";
import { isScheduleComplete } from "src/utils/schedule";
import { SyncStatus, syncStatusIsTerminal } from "src/utils/syncs";
import { useQueryString } from "src/utils/use-query-string";

import { syncActivityMappers } from "./sync-activity";

enum Tab {
  RUNS = "Runs",
  CONFIGURATION = "Configuration",
  SCHEDULE = "Schedule",
  ALERTS = "Alerts",
  GIT_ACTIVITY = "Git activity",
  WAREHOUSE_SYNC_LOGS = "Sync logs",
  SPLITS = "Splits",
  ACTIVITY = "Activity",
}

const getDeleteSyncErrorMessage = (error: Error): string => {
  return error.message.startsWith("Foreign key violation") && error.message.includes("sync_sequence")
    ? "This sync cannot be deleted because it is used in one or more sequences"
    : error.message;
};

export const SyncWrapper: FC = () => {
  const { sync_id: id } = useParams<{ sync_id: string }>();
  const {
    data: syncData,
    fullQueryRefetch,
    isLoading,
  } = useIncrementalQuery<MinimalSyncQuery, SyncQuery>(
    useMinimalSyncQuery(
      {
        id: id ?? "",
      },
      {
        enabled: Boolean(id),
      },
    ),
    useSyncQuery(
      {
        id: id ?? "",
      },
      {
        enabled: Boolean(id),
      },
    ),
  );

  if (!id) {
    return <PageSpinner />;
  }

  return (
    <DraftProvider
      initialResourceIsDraft={syncData?.syncs?.[0]?.draft || false}
      resourceId={id}
      resourceType={ResourceToPermission.Sync}
    >
      <Sync refetchSync={fullQueryRefetch} syncData={syncData} syncLoading={isLoading} />
    </DraftProvider>
  );
};

interface SyncProps {
  syncData: Partial<SyncQuery> | undefined;
  syncLoading: boolean;
  refetchSync: () => void;
}

const Sync: FC<SyncProps> = ({ syncData, syncLoading, refetchSync }: SyncProps) => {
  const { user, workspace } = useUser();
  const navigate = useNavigate();
  const { sync_id: id } = useParams<{ sync_id: string }>();
  const { toast } = useToast();
  const [schedule, setSchedule] = useState<any>();
  const [cancelling, setCancelling] = useState<boolean>(false);
  const [tab, setTab] = useState<Tab>(Tab.RUNS);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<boolean>(true);
  const [isEditLabelModalOpen, setIsEditLabelModalOpen] = useState(false);
  const [cancelledSyncId, setCancelledSyncId] = useState<string>();
  const [showRun, setShowRun] = useState(true);
  const [runs, setRuns] = useState<SyncRunsQuery["sync_requests"] | undefined>();

  const { appSplitsV2Enabled } = useFlags();
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);

  const { labels } = useLabels();

  const { mutateAsync: updateSync, isLoading: isSyncUpdating } = useUpdateSyncMutation();
  const { mutateAsync: forceRun } = useStartSyncRunMutation();
  const { mutateAsync: cancelSyncRequest } = useUpdateSyncRequestMutation();
  const { mutateAsync: deleteSync } = useDeleteSyncMutation();

  const sync = syncData?.syncs?.[0];
  const latestRun = runs?.filter((run) => run.sync_attempts?.length > 0)[0];
  const syncAttempt = latestRun?.sync_attempts?.[0];
  const syncAlerts = sync?.sync_alerts;
  const model = sync?.segment;
  const source = model?.connection;
  const usesSyncTemplate = sync?.sync_template_id;
  const currentLabels = sync?.tags ?? {};
  const labelKeys = Object.keys(currentLabels);

  const isAudienceModel = model?.query_type === QueryType.Visual;
  const showSplitsTab = appSplitsV2Enabled && isAudienceModel;
  const splits = (sync?.destination_instance_splits ?? []).map(({ split }) => split);

  const updateSplitSyncAssignments = useUpdateSplitSyncAssignmentsMutation();
  const startSyncsRunsForSegment = useStartSyncRunsForSegmentMutation();

  const updateSplitSyncAssignment = async (selectedSplitIds) => {
    // In order to do an update, we remove the existing destination_instance-split rows (for this sync)
    // and then insert the selected destination_instance-splits (for this sync)
    const removeObjects = splits.map((split) => ({
      split_id: { _eq: split.id },
      destination_instance_id: { _eq: sync?.id },
    }));

    const addObjects = selectedSplitIds.map((splitId: string) => ({
      split_id: splitId,
      destination_instance_id: sync?.id,
    }));

    try {
      await updateSplitSyncAssignments.mutateAsync({ addObjects, removeObjects });
    } catch (error) {
      toast({
        id: "split-sync-assignment-error",
        title: "There was an error saving your splits configuration.",
        variant: "error",
      });

      throw error;
    }

    try {
      await startSyncsRunsForSegment.mutateAsync({ segment_id: String(model?.id), full_resync: false });
    } catch (error) {
      toast({
        id: "split-sync-assignment-error",
        title: "Split assignments were successfully updated, but syncs could not be refreshed.",
        variant: "warning",
      });

      throw error;
    }

    toast({
      id: "split-sync-assignment-success",
      title: "Successfully updated split group assignments.",
      message: "Syncs have been scheduled to re-run.",
      variant: "success",
    });
  };

  const {
    data: { destination, definition: destinationDefinition },
  } = useDestination(sync?.destination?.id, { pause: !sync });

  const {
    draft,
    editingDraft,
    editingDraftChanges,
    updateResourceOrDraft,
    setSubmitDraftModalOpen,
    setEditingDraft,
    onViewDraft,
  } = useDraft();

  const newResource = draft?.new_resource as SyncDraft;
  const draftSchedule = newResource?._set?.schedule;

  const { data: externalSegmentsData } = useExternalSegmentsQuery({ syncId: sync?.id }, { enabled: !!sync });
  // We used to support multiple external segments per sync, where only one
  // would be valid at a time. When there is more than one, we only care about
  // the most recent, active one.
  const externalSegment = externalSegmentsData?.external_segments?.sort((a, b) => {
    return a.created_at > b.created_at ? -1 : 1;
  })?.[0];

  const { data: entitlementsData, isLoading: _loadingEntitlements } = useEntitlements(true);
  const overageLockout: boolean = entitlementsData.overage?.overageLockout;
  const overageText = entitlementsData.overage?.destinationOverageText; // @TODO: hookup once there are more than one overage.

  const { data: sequences, isLoading: sequencesLoading } = useSequencesForSyncQuery(
    { syncId: String(id) },
    { enabled: Boolean(id), select: (data) => data.sync_sequences },
  );

  const toggleSyncPause = async (enabled: boolean) => {
    if (!id) {
      return;
    }

    try {
      await updateSync({
        id,
        object: {
          schedule_paused: !enabled,
        },
      });

      toast({
        id: "toggle-sync-pause",
        title: `Sync was ${enabled ? "enabled" : "disabled"}`,
        variant: "success",
      });
    } catch (error) {
      toast({
        id: "toggle-sync-pause",
        title: `Sync could not be ${enabled ? "enabled" : "disabled"}`,
        message: error.message,
        variant: "error",
      });
    }

    refetchSync();
  };

  const startRun = async (resync = false) => {
    const { startSyncRun } = await forceRun({
      id: Number(id),
      full_resync: resync,
    });

    // Only render a popup message if we actually scheduled a sync.
    const scheduled = startSyncRun?.scheduled;
    analytics.track("Sync Manually Started", {
      sync_id: id,
      destination_type: destinationDefinition?.name,
      schedule_type: schedule?.type,
      source_type: model?.connection?.type,
    });

    if (scheduled) {
      if (resync) {
        toast({
          id: "start-sync-run",
          title: "Resync will begin shortly",
          variant: "success",
        });
      } else {
        toast({
          id: "start-sync-run",
          title: "Manual run will begin shortly",
          variant: "success",
        });
      }
    }

    refetchSync();
  };

  const cancelRun = async () => {
    setCancelling(true);
    setCancelledSyncId(latestRun?.id);
    cancelSyncRequest({
      id: latestRun?.id,
      object: {
        trigger_cancel: true,
      },
    });

    refetchSync();
  };

  const TABS = [
    Tab.RUNS,
    newResource?._set?.config && editingDraft
      ? {
          render: () => (
            <Row sx={{ alignItems: "center" }}>
              Configuration <DraftCircle sx={{ ml: 2 }} />
            </Row>
          ),
          value: Tab.CONFIGURATION,
        }
      : Tab.CONFIGURATION,
    showSplitsTab && Tab.SPLITS,
    !usesSyncTemplate &&
      (draftSchedule !== undefined && editingDraft
        ? {
            render: () => (
              <Row sx={{ alignItems: "center" }}>
                Schedule <DraftCircle sx={{ ml: 2 }} />
              </Row>
            ),
            value: Tab.SCHEDULE,
          }
        : Tab.SCHEDULE),
    // TODO: Feature flag
    Tab.ACTIVITY,
    !usesSyncTemplate && !editingDraftChanges && Tab.ALERTS,
    model?.git_sync_metadata && Tab.GIT_ACTIVITY,
    source?.definition?.supportsInWarehouseDiffing && !editingDraftChanges && Tab.WAREHOUSE_SYNC_LOGS,
  ].filter(Boolean);

  const running = syncAttempt?.status === SyncStatus.ACTIVE;

  const {
    data: { autorun },
  } = useQueryString();

  useEffect(() => {
    let autoRunTimeout: number | undefined | null;
    if (sync && autorun) {
      autoRunTimeout = window.setTimeout(() => {
        startRun();
      }, 400);
    }
    return () => {
      if (autoRunTimeout) {
        clearTimeout(autoRunTimeout);
        autoRunTimeout = null;
      }
    };
  }, [autorun, sync?.id]);

  useEffect(() => {
    if (sync?.schedule || sync?.schedule === null) {
      setSchedule(sync?.schedule ? sync.schedule : { type: ScheduleType.MANUAL });
    }
  }, [sync?.schedule, editingDraft]);

  useEffect(() => {
    setEnabled(!sync?.schedule_paused);
  }, [sync?.schedule_paused]);

  useEffect(() => {
    // if the latest run id no longer match the cancelled id then it means cancel is successful.
    if (syncAttempt?.status === SyncStatus.CANCELLED || cancelledSyncId !== latestRun?.id) {
      setCancelling(false);
    }
  }, [syncAttempt?.status, latestRun?.id, cancelledSyncId]);

  useEffect(() => {
    if (syncStatusIsTerminal(syncAttempt?.status as SyncStatus)) {
      setShowRun(true);
    }
  }, [syncAttempt?.status]);

  useEffect(() => {
    if (draft && editingDraft && draftSchedule !== undefined) {
      setSchedule(draftSchedule ? draftSchedule : { type: ScheduleType.MANUAL });
    }
  }, [editingDraft]);

  const onUpdate = () => {
    analytics.track("Sync Edited", {
      sync_id: id,
      destination_type: destinationDefinition?.name,
      schedule_type: schedule?.type,
      source_type: model?.connection?.type,
    });

    if (!workspace?.approvals_required) {
      toast({
        id: "update-sync",
        title: "Sync was updated",
        variant: "success",
      });
    }

    refetchSync();
  };

  const updateConfig = async (config) => {
    if (!id || !sync) {
      return;
    }

    const updatePayload = {
      config: { ...config, configVersion: sync?.config?.configVersion },
      updated_by: user?.id != null ? String(user?.id) : undefined,
      // we null the draft id, it gets added on the backend and we want to be consistent
      // if a workspace turns off approvals again =
      approved_draft_id: null,
    };

    updateResourceOrDraft &&
      (await updateResourceOrDraft(
        { _set: updatePayload },
        onUpdate,
        async () => {
          await updateSync({
            id,
            object: updatePayload,
          });
        },
        sync.draft || false,
      ));
    setSubmitDraftModalOpen(true);
  };

  const updateSchedule = async () => {
    if (!id || !sync) {
      return;
    }

    const updatePayload = {
      updated_by: user?.id != null ? String(user?.id) : undefined,
      schedule: schedule?.type === "manual" ? null : schedule,
      // we null the draft id, it gets added on the backend and we want to be consistent
      // if a workspace turns off approvals again =
      approved_draft_id: null,
    };

    updateResourceOrDraft &&
      (await updateResourceOrDraft(
        { _set: updatePayload },
        onUpdate,
        async () => {
          await updateSync({
            id,
            object: updatePayload,
          });
        },
        sync.draft || false,
      ));
    setSubmitDraftModalOpen(true);
  };

  const updateLabels = async (labels: Record<string, string | number>) => {
    if (!id) {
      return;
    }

    try {
      await updateSync({
        id: id,
        object: {
          tags: labels,
        },
      });

      onUpdate();
      setIsEditLabelModalOpen(false);
    } catch (error) {
      toast({
        id: "update-sync-labels",
        title: "Couldn't update labels",
        message: error.message,
        variant: "error",
      });
    }
  };

  const hasPrimaryKeyIssue =
    model?.query_type === QueryType.Visual
      ? Boolean(model?.parent?.columns?.length && !model?.parent?.columns.some((c) => c.name === model?.parent?.primary_key))
      : Boolean(model?.columns?.length && !model?.columns.some((c) => c.name === model?.primary_key));

  const { hasPermission: userCanStart } = useHasPermission([
    { resource: "sync", grants: [ResourcePermissionGrant.Start], resource_id: id },
  ]);
  const { hasPermission: userCanDelete } = useHasPermission([
    { resource: "sync", grants: [ResourcePermissionGrant.Delete], resource_id: id },
  ]);
  const { hasPermission: userCanUpdate } = useHasPermission([
    { resource: "sync", grants: [ResourcePermissionGrant.Update], resource_id: id },
  ]);
  const { hasPermission: userCanEnable } = useHasPermission([
    { resource: "sync", grants: [ResourcePermissionGrant.Enable], resource_id: id },
  ]);

  const folder = useFolder({
    folderId: sync?.segment?.folder?.id,
    folderType: sync?.segment?.query_type === "visual" ? "audiences" : "models",
    viewType: "syncs",
  });

  const loading = syncLoading || sequencesLoading;

  if (!loading && !sync) {
    return <Warning subtitle="It may have been deleted" title="Sync not found" />;
  }

  if (loading) {
    return <PageSpinner />;
  }

  // Since when we edit the draft, we set the schedule on the draft instead of the
  // actual resource, we need to check if there is a draft when we are setting the schedule
  // to the manual schedule type.
  const scheduleSaveDisabled =
    isEqual(schedule, sync?.schedule) ||
    (sync?.schedule === null && isMatch(schedule, { type: ScheduleType.MANUAL }) && !draft) ||
    (editingDraft && isEqual(draftSchedule, schedule)) ||
    !isScheduleComplete(schedule);

  const crumbs: Crumb[] = [{ label: "Syncs", link: "/syncs" }];

  if (folder?.path) {
    folder.path.split("/").forEach((path) => {
      crumbs.push({
        label: path,
        link: "/syncs?folder=" + folder.id,
      });
    });
  }

  crumbs.push({
    label: "Sync",
  });

  return (
    <>
      <PermissionProvider permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Update], resource_id: sync?.id }]}>
        <Page
          crumbs={crumbs}
          outsideTopbar={
            draft && (
              <EditingDraftWarning
                draft={draft}
                editingDraft={editingDraft}
                resourceType={ResourceToPermission.Sync}
                setEditingDraft={setEditingDraft}
                onViewDraft={onViewDraft}
              />
            )
          }
          sync={sync}
          title={`${model?.name || "Private model"} to ${
            destination ? destination.name || destinationDefinition?.name || "private destination" : "private destination"
          } - Syncs`}
        >
          <Row
            sx={{ alignItems: "center", justifyContent: "space-between", mb: 5, width: "100%", borderBottom: "small", pb: 2 }}
          >
            <SyncName
              destination={destination}
              destinationDefinition={destinationDefinition}
              model={model}
              source={source}
              sync={sync}
            />
            <Row gap={4}>
              <Box alignItems="center" display="flex" gap={2}>
                <Text
                  sx={{
                    textTransform: "uppercase",
                    fontSize: "10px",
                    color: "base.4",
                    fontWeight: "bold",
                  }}
                >
                  {enabled ? "Enabled" : "Disabled"}
                </Text>

                <Switch
                  isChecked={enabled}
                  isDisabled={!userCanEnable}
                  onChange={(value) => {
                    setEnabled(value);
                    toggleSyncPause(value);
                  }}
                />
              </Box>

              {(userCanUpdate || userCanStart || userCanDelete) && (
                <Menu>
                  <MenuActionsButton />

                  <MenuList>
                    {userCanUpdate && (
                      <MenuItem
                        icon={FolderOpenIcon}
                        onClick={() => {
                          setIsFolderModalOpen(true);
                        }}
                      >
                        Move to folder
                      </MenuItem>
                    )}

                    {userCanStart && (
                      <MenuItem
                        icon={ArrowPathIcon}
                        isDisabled={running}
                        onClick={() => {
                          setShowRun(false);
                          startRun(true);
                        }}
                      >
                        Resync full query
                      </MenuItem>
                    )}

                    {(userCanUpdate || userCanStart) && userCanDelete && <MenuDivider />}

                    {userCanDelete && (
                      <MenuItem
                        icon={TrashIcon}
                        isDisabled={running}
                        variant="danger"
                        onClick={() => {
                          setDeleting(true);
                        }}
                      >
                        Delete
                      </MenuItem>
                    )}
                  </MenuList>
                </Menu>
              )}

              <Permission permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Start], resource_id: id }]}>
                {running || cancelling ? (
                  <Button
                    disabled={cancelling}
                    iconBefore={<Spinner color={cancelling ? "primary" : "red"} size={14} />}
                    variant={cancelling ? "secondary" : "redOutline"}
                    onClick={cancelRun}
                  >
                    {cancelling ? "Canceling..." : "Cancel run"}
                  </Button>
                ) : (
                  <Button
                    disabled={!showRun || overageLockout || !!sync?.draft}
                    iconBefore={<PlayIcon size={14} />}
                    tooltip={overageLockout ? overageText : undefined}
                    variant="secondary"
                    onClick={() => {
                      setShowRun(false);
                      startRun(false);
                    }}
                  >
                    Run
                  </Button>
                )}
              </Permission>
            </Row>
          </Row>

          <>
            <Row gap={8} sx={{ alignSelf: "flex-start", alignItems: "flex-start", mb: 6 }}>
              <Column sx={{ pr: 10 }}>
                <Text sx={{ fontSize: 0, textTransform: "uppercase", color: "base.4", fontWeight: "bold", mb: 1 }}>
                  Sync ID
                </Text>
                <Text sx={{ textTransform: "capitalize", fontWeight: "semi" }}>{sync?.id}</Text>
              </Column>
              <Column sx={{ pl: 3, pr: 10, borderLeft: "small" }}>
                <Text sx={{ fontSize: 0, textTransform: "uppercase", color: "base.4", fontWeight: "bold", mb: 1 }}>
                  Schedule
                </Text>
                <Schedule schedule={sync?.schedule} />
              </Column>
              {model?.query_type !== QueryType.Visual && (
                <Column sx={{ pl: 3, pr: 10, borderLeft: "small" }}>
                  <Text sx={{ fontSize: 0, textTransform: "uppercase", color: "base.4", fontWeight: "bold", mb: 1 }}>Slug</Text>
                  <DisplaySlug currentSlug={sync?.slug} />
                </Column>
              )}
              {sync?.config?.mode && (
                <Column sx={{ pl: 3, borderLeft: "small" }}>
                  <Text sx={{ fontSize: 0, textTransform: "uppercase", color: "base.4", fontWeight: "bold", mb: 1 }}>Mode</Text>
                  <Text sx={{ textTransform: "capitalize", fontWeight: "semi" }}>{sync?.config?.mode}</Text>
                </Column>
              )}
              {!editingDraftChanges &&
                (labelKeys.length > 0 ? (
                  <Row sx={{ alignItems: "center", pl: 4, ml: 4, height: "100%", borderLeft: "small" }}>
                    <Popout
                      content={({ close }) => (
                        <>
                          <Labels labels={sync?.tags} />
                          <Permission
                            permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Update], resource_id: id }]}
                          >
                            <Button
                              sx={{ mt: 4 }}
                              variant="secondary"
                              onClick={() => {
                                setIsEditLabelModalOpen(true);
                                close();
                              }}
                            >
                              Edit labels
                            </Button>
                          </Permission>
                        </>
                      )}
                      contentSx={{ p: 3, minWidth: "90px" }}
                    >
                      <Text sx={{ mr: 1 }}>Labels</Text>
                      <SquareBadge>{Object.keys(sync?.tags || {}).length}</SquareBadge>
                      <ChevronDownIcon size={16} sx={{ ml: 2 }} />
                    </Popout>
                  </Row>
                ) : (
                  <Row sx={{ pl: 4, ml: 4, borderLeft: "small" }}>
                    <Button
                      iconBefore={<PlusIcon color="base.5" size={14} />}
                      size="small"
                      variant="secondary"
                      onClick={() => {
                        setIsEditLabelModalOpen(true);
                      }}
                    >
                      Add labels
                    </Button>
                  </Row>
                ))}
            </Row>

            <Grid gap={8} sx={{ width: "100%" }}>
              <Tabs setTab={(tab) => setTab(tab as Tab)} tab={tab} tabs={TABS} />
              <>
                {tab === Tab.RUNS && (
                  <>
                    {hasPrimaryKeyIssue && (
                      <Message sx={{ width: "100%", maxWidth: "100%" }} variant="warning">
                        <Row sx={{ alignItems: "center", justifyContent: "space-between" }}>
                          <Field label="Looks like your primary key is set to an undefined column.">
                            As a result, your syncs may fail or undefined behavior may occur. Go to your model and make sure you
                            have your primary key set to a valid column in your model.
                          </Field>
                          <Column sx={{ flexShrink: 0, px: 2 }}>
                            <Link
                              to={
                                model?.query_type === QueryType.Visual
                                  ? `/audiences/setup/parent-models/${model?.parent?.id}`
                                  : `/models/${model?.id}`
                              }
                            >
                              <Button variant="dark">Go to your model</Button>
                            </Link>
                          </Column>
                        </Row>
                      </Message>
                    )}

                    {id && <Runs setRuns={setRuns} syncId={id} />}
                  </>
                )}

                {tab === Tab.CONFIGURATION && overageLockout && <OverageContentAlert />}
                {tab === Tab.CONFIGURATION && !overageLockout && !usesSyncTemplate && model && source?.definition && (
                  <PermissionProvider
                    fallback={<Spinner center size={64} />}
                    permissions={
                      workspace?.approvals_required
                        ? [] // when the workspace enables approvals, anyone can submit a draft.
                        : [{ resource: "sync", grants: [ResourcePermissionGrant.Update], resource_id: id }]
                    }
                  >
                    {destination && destinationDefinition && (
                      <DestinationForm
                        destination={destination}
                        destinationDefinition={destinationDefinition}
                        externalSegment={externalSegment}
                        model={model}
                        slug={destinationDefinition.type}
                        sourceDefinition={source.definition}
                        sync={sync}
                        onSubmit={updateConfig}
                      />
                    )}
                  </PermissionProvider>
                )}
                {tab === Tab.CONFIGURATION && !overageLockout && usesSyncTemplate && (
                  <Row
                    sx={{
                      mt: -4,
                      justifyContent: "space-between",
                      alignItems: "center",
                      maxWidth: "800px",
                      p: 4,
                      borderRadius: 1,
                      bg: "base.1",
                      color: "base.7",
                      fontWeight: "semi",
                    }}
                  >
                    <Row sx={{ alignItems: "center" }}>
                      <InfoIcon />
                      <Text sx={{ ml: 3 }}>This sync uses a shared configuration template</Text>
                    </Row>
                    <Row sx={{ alignItems: "center" }}>
                      <Link
                        sx={{ display: "flex", color: "inherit", alignItems: "center" }}
                        to={`/audiences/setup/sync-templates/${sync?.sync_template_id}`}
                      >
                        <Text sx={{ mr: 4 }}>Edit the template</Text>
                        <ExternalLinkIcon color="base.4" size={14} />
                      </Link>
                    </Row>
                  </Row>
                )}

                {tab === Tab.SPLITS && showSplitsTab && (
                  <SplitsSyncAssignmentForm
                    audienceId={model?.id}
                    splitsAssignedToCurrentSync={splits}
                    onSubmit={updateSplitSyncAssignment}
                  />
                )}
                {tab === Tab.ACTIVITY && id && (
                  <ResourceActivityTimeline mappers={syncActivityMappers} resource="Sync" resourceId={id} />
                )}

                {tab === Tab.SCHEDULE && overageLockout && <OverageContentAlert />}
                {tab === Tab.SCHEDULE && !overageLockout && (
                  <PermissionProvider
                    fallback={<Spinner center size={64} />}
                    permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Update], resource_id: id }]}
                  >
                    <Row sx={{ width: "100%", justifyContent: "space-between" }}>
                      <Column mr={8} sx={{ width: "100%" }}>
                        {sequences && sequences.length > 0 && (
                          <Column>
                            <Field
                              description={`This sync is currently being run by ${sequences.length} ${pluralize(
                                "sequence",
                                sequences.length > 1,
                              )}`}
                              label="Sequences"
                              size="large"
                            >
                              <Table
                                columns={[
                                  { name: "Name", key: "name" },
                                  {
                                    name: "Syncs",
                                    cell: ({ members }) => `${members.length} ${pluralize("sync", members.length > 1)}`,
                                  },
                                  {
                                    cell: () => (
                                      <ExternalLinkIcon color="base.4" sx={{ display: "flex", justifyContent: "flex-end" }} />
                                    ),
                                  },
                                ]}
                                data={sequences}
                                showHeaders={false}
                                onRowClick={({ id }) => navigate(`/sequences/${id}`)}
                              />
                            </Field>
                          </Column>
                        )}
                        <ScheduleManager schedule={schedule} setSchedule={setSchedule} />
                        <Message sx={{ mt: 12, maxWidth: "100%" }}>
                          <Text>
                            You can also trigger this sync via Airflow. The ID for this sync is: <strong>{id}</strong>.
                          </Text>
                          <Text sx={{ mt: 3 }}>
                            For more information view our{" "}
                            <Link newTab to={`${import.meta.env.VITE_DOCS_URL}/integrations/airflow`}>
                              Airflow Operator docs
                            </Link>
                            . If you need an API key you can create one <Link to="/settings/api-keys">here.</Link>
                          </Text>
                        </Message>
                      </Column>
                      <SidebarForm
                        hideInviteTeammate
                        hideSendMessage
                        buttons={[
                          userCanUpdate ? (
                            <Button disabled={scheduleSaveDisabled} sx={{ width: "100%" }} onClick={() => updateSchedule()}>
                              {workspace?.approvals_required ? "Save draft" : "Save"}
                            </Button>
                          ) : null,
                        ]}
                        docsUrl={`${import.meta.env.VITE_DOCS_URL}/syncs/schedule-sync-ui/`}
                        invite="If you need help setting up this sync"
                        name="scheduling syncs"
                      />
                    </Row>
                  </PermissionProvider>
                )}

                {tab === Tab.ALERTS && (
                  <SyncAlerts
                    alerts={syncAlerts}
                    rowThresholdAttempted={sync?.row_threshold_attempted}
                    rowThresholdTotal={sync?.row_threshold_total}
                    syncId={id}
                  />
                )}

                {tab === Tab.GIT_ACTIVITY && <GitActivity id={id} />}

                {tab === Tab.WAREHOUSE_SYNC_LOGS && (
                  <WarehouseSyncLogs config={sync?.warehouse_history_config} id={id} source={source ?? undefined} />
                )}
              </>
            </Grid>
          </>
        </Page>
      </PermissionProvider>

      <EditLabels
        description="You can label syncs that have similar properties"
        existingLabelOptions={labels}
        hint="Example keys: team, project, region, env."
        isOpen={isEditLabelModalOpen}
        labels={currentLabels}
        loading={isSyncUpdating}
        saveLabel="Save"
        title="Edit labels"
        onClose={() => setIsEditLabelModalOpen(false)}
        onSave={updateLabels}
      />

      {sync?.segment && isFolderModalOpen && (
        <MoveFolder
          folder={sync.segment.folder}
          folderType={sync.segment.query_type === "visual" ? "audiences" : "models"}
          modelIds={[sync.segment.id]}
          viewType="syncs"
          onClose={() => setIsFolderModalOpen(false)}
        />
      )}

      <DeleteConfirmationModal
        isOpen={deleting}
        label="sync"
        onClose={() => {
          setDeleting(false);
        }}
        onDelete={async () => {
          if (!id) {
            return;
          }

          await deleteSync(
            {
              id,
            },
            {
              onSuccess: () => {
                analytics.track("Sync Deleted", {
                  sync_id: id,
                  destination_type: destinationDefinition?.name,
                  schedule_type: schedule?.type,
                  source_type: model?.connection?.type,
                });
              },
              onError: (error) => {
                toast({
                  id: "delete-sync",
                  title: "Couldn't delete this sync",
                  message: getDeleteSyncErrorMessage(error),
                  variant: "error",
                });
              },
            },
          );

          navigate("/syncs");
        }}
      />
    </>
  );
};
