import { FC } from "react";

import { UpsellHeading, Column, Row } from "@hightouchio/ui";
import { Text } from "theme-ui";

import { Page } from "src/components/layout";
import { Container } from "src/ui/box";
import { AudienceIcon } from "src/ui/icons";

import { FeatureFull } from "../feature-gates";
import { Logos } from "./demo-logos";

export const AudiencesDemo: FC = () => {
  return (
    <Page fullWidth sx={{ px: 0 }} title="Audiences">
      <Container center size="large" sx={{ px: 6, gap: 8 }}>
        <Column sx={{ alignItems: "center", path: { fill: "space" } }}>
          <AudienceIcon color="space" size={64} />
          <UpsellHeading mb="2" mt="4" size="3xl">
            Hightouch Audiences
          </UpsellHeading>
        </Column>

        <Row sx={{ width: "100%" }}>
          <Column sx={{ flex: 1.25 }}>
            <FeatureFull
              enabled={false}
              featureDetails={{
                description:
                  "Audiences is a simple tool to create custom segments, traits and experiments... no SQL required. It empowers data-driven marketers to build captivating campaigns without engineering support.",
                pitch: "The most powerful audience builder for your customer data",
                bullets: [
                  "Use any trusted data in the data warehouse — users, events, custom objects",
                  "Define traits and relationships to personalize your message",
                  "Create experiments and A/B tests with built-in splits functionality",
                  "Tap into complex campaign logic with priority lists and sync sequences",
                  "Send audiences to 100's of email, ads, SMS, and other destinations",
                ],
              }}
              featureName="audiences"
            >
              <></>
            </FeatureFull>
          </Column>
          <Column sx={{ maxWidth: "600px", ml: 8, flex: 1 }}>
            <video
              controls={true}
              poster="https://cdn.sanity.io/images/pwmfmi47/production/4d576498380ac0f1282f371143db1533ed9efdf3-1500x900.png"
              src="https://cdn.sanity.io/files/pwmfmi47/production/bbd24af51d616f02c9cc8402827785652e0c1529.mp4"
              width="100%"
            />
          </Column>
        </Row>

        <Column sx={{ alignItems: "center" }}>
          <Text
            sx={{ fontWeight: "bold", color: "base.4", mb: 2, textTransform: "uppercase", letterSpacing: "1px", fontSize: 0 }}
          >
            Audiences is used by
          </Text>
          <Logos />
        </Column>
      </Container>
    </Page>
  );
};
