import { FC, ReactNode } from "react";

import { Flex, ThemeUIStyleObject } from "theme-ui";

import { ChevronDownIcon } from "src/ui/icons";
// eslint-disable-next-line no-restricted-imports
import { Menu, MenuOption } from "src/ui/menu";

import { Button, ButtonProps } from "./button";

export interface DropdownButtonProps {
  children: ReactNode;
  loading?: boolean;
  disabled?: boolean;
  options: MenuOption[];
  onClick: ButtonProps["onClick"];
  sx?: ThemeUIStyleObject;
}

export const DropdownButton: FC<Readonly<DropdownButtonProps>> = ({
  children,
  onClick,
  loading = false,
  disabled = false,
  options,
  sx = {},
}) => {
  return (
    <Flex sx={{ alignItems: "center", ...sx }}>
      <Button
        disabled={disabled}
        loading={loading}
        sx={{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          borderRight: "none",
        }}
        variant="purple"
        onClick={onClick}
      >
        {children}
      </Button>
      <Flex sx={{ height: "32px", width: 1, bg: "secondaries.3" }} />
      <Menu options={options}>
        <Flex
          sx={{
            alignItems: "center",
            justifyContent: "center",
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: 1,
            borderBottomRightRadius: 1,
            border: "small",
            borderLeft: "none",
            borderColor: "primaries.3",
            color: "primaries.9",
            bg: "primaries.0",
            ":hover:not(:disabled)": {
              bg: "primaries.3",
            },
            cursor: "pointer",
            p: 2,
            height: "32px",
          }}
        >
          <ChevronDownIcon color="primaries.9" size={14} />
        </Flex>
      </Menu>
    </Flex>
  );
};
