import { ReactNode } from "react";

import { Flex, Grid } from "theme-ui";

// eslint-disable-next-line no-restricted-imports
import { Checkbox } from "src/ui/checkbox";

export type Props = {
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
  children?: ReactNode;
};

export const Collapsible = ({ label, value, onChange, children }: Props) => {
  return (
    // this box is the overall collapsible box
    <Flex sx={{ flexDirection: "column", borderRadius: 2, border: "small" }}>
      <Flex sx={{ flexDirection: "row", alignItems: "center" }}>
        <Checkbox label={label} size="large" sx={{ p: 6 }} value={value} onChange={onChange} />
      </Flex>
      {Boolean(value) && (
        <Grid gap={6} sx={{ p: 6, borderTop: "small" }}>
          {children}
        </Grid>
      )}
    </Flex>
  );
};

Collapsible.displayName = "Collapsible";
