import { useMemo, useState, FC, useCallback, MouseEvent, useRef, useEffect } from "react";

import { Column, Box, Tooltip, Link } from "@hightouchio/ui";
import { animate, useMotionValue } from "framer-motion";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useLocation } from "react-router-dom";
import { Text } from "theme-ui";

import grain from "src/assets/backgrounds/grain.png";
import { FeedbackMenu } from "src/components/app/feedback-menu";
import { UserMenu } from "src/components/layout/header/user-menu";
import { InviteFormModal } from "src/components/modals/invite-modal";
import { useUser } from "src/contexts/user-context";
import { ResourceToPermission, ResourcePermissionGrant } from "src/graphql";
import { ResourcePermission } from "src/hooks/use-has-permission";
import { Logo } from "src/ui/brand";
import {
  AddUserIcon,
  SyncIcon,
  DestinationIcon,
  ModelIcon,
  SettingIcon,
  OnboardingIcon,
  SourceIcon,
  AudienceIcon,
  ChatIcon,
  ChevronDownIcon,
  DocsIcon,
  ExtensionsIcon,
  SequencesIcon,
} from "src/ui/icons";
// eslint-disable-next-line no-restricted-imports
import { Menu, MenuOption } from "src/ui/menu";
import { useNavigate } from "src/utils/navigate";
import { switchWorkspace } from "src/utils/workspaces";

import { Permission } from "../permission";

export const NAV_WIDTHS_PER_BREAKPOINT = ["56px", "56px", "56px", "218px"];

export const Nav: FC = () => {
  const navigate = useNavigate();
  const { onboarding, workspaces, workspace } = useUser();
  const { syncSequencesWorkspace } = useFlags();
  const [openInvite, setOpenInvite] = useState(false);

  const menuOptions: MenuOption[] = useMemo(
    () => [
      {
        title: "Actions",
        label: "Manage workspaces",
        onClick: () => {
          navigate("/workspaces", { slug: false });
        },
        stickToTop: true,
        divider: "bottom",
      },
      ...(workspaces?.map(({ name, id, slug }, index) => ({
        title: index === 0 ? "Workspaces" : undefined,
        label: name,
        onClick: () => switchWorkspace(id, `/${slug}`),
      })) ?? []),
    ],
    [workspaces],
  );

  return (
    <>
      <Column
        align="center"
        background={[
          "forest.darker",
          "forest.darker",
          "forest.darker",
          `url(${grain}) repeat, linear-gradient(175.93deg, #044747 0%, #065655 11.46%, #002B2E 82.29%), linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))`,
        ]}
        height="100vh"
        overflowX="hidden"
        overflowY="auto"
        pb={3}
        position="sticky"
        px={2}
        top={0}
        transition="100ms width ease-in-out"
        width={NAV_WIDTHS_PER_BREAKPOINT}
      >
        <Menu portal options={menuOptions} sx={{ m: 3, width: "100%" }} width="300px">
          <Box
            _hover={{
              borderColor: "rgba(255,255,255,.5)",
            }}
            alignItems="center"
            border="1px solid rgba(255,255,255,.3)"
            borderRadius="6px"
            color="white"
            display="flex"
            gap={2}
            justifyContent={["center", "center", "center", "initial"]}
            px={2}
            py={[3, 3, 3, 2]}
            transition="200ms border-color"
            width="100%"
          >
            <Logo size="22px" />
            <Box
              sx={{
                display: ["none", "none", "none", "flex"],
                alignItems: "center",
                justifyContent: "space-between",
                flex: 1,
                gap: 1,
              }}
            >
              <Column>
                <Text sx={{ textTransform: "uppercase", fontSize: "10px", color: "whites.4", fontWeight: "bold" }}>
                  Workspace
                </Text>
                <Text
                  sx={{
                    fontSize: 0,
                    fontWeight: "semi",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {workspace?.name}
                </Text>
              </Column>

              <ChevronDownIcon color="rgba(255,255,255,.3)" size={18} />
            </Box>
          </Box>
        </Menu>

        <Column align="stretch" gap={1} width="100%">
          {onboarding && <NavLink href="/onboarding" icon={OnboardingIcon} label="Setup" />}
          <NavLink
            href="/syncs"
            icon={SyncIcon}
            label="Syncs"
            permissions={[{ resource: ResourceToPermission.Sync, grants: [ResourcePermissionGrant.Read] }]}
          />
          <NavLink
            href="/models"
            icon={ModelIcon}
            label="Models"
            permissions={[{ resource: ResourceToPermission.Model, grants: [ResourcePermissionGrant.Read] }]}
          />
          <NavLink
            href="/audiences"
            icon={AudienceIcon}
            label="Audiences"
            permissions={[{ resource: ResourceToPermission.Audience, grants: [ResourcePermissionGrant.Read] }]}
          />
          <NavLink
            href="/sources"
            icon={SourceIcon}
            label="Sources"
            permissions={[{ resource: ResourceToPermission.Source, grants: [ResourcePermissionGrant.Read] }]}
          />
          <NavLink
            href="/destinations"
            icon={DestinationIcon}
            label="Destinations"
            permissions={[{ resource: ResourceToPermission.Destination, grants: [ResourcePermissionGrant.Read] }]}
          />
          {syncSequencesWorkspace && (
            <NavLink
              href="/sequences"
              icon={SequencesIcon}
              label="Sequences"
              permissions={[{ resource: ResourceToPermission.Sync, grants: [ResourcePermissionGrant.Read] }]}
            />
          )}
          <NavLink href="/extensions" icon={ExtensionsIcon} label="Extensions" />
          <NavLink href="/settings" icon={SettingIcon} label="Settings" />
        </Column>

        <Column align="stretch" gap={1} mt="auto" width="100%">
          <Permission permissions={[{ resource: "workspace", grants: [ResourcePermissionGrant.Update] }]}>
            <Box cursor="pointer" onClick={() => setOpenInvite(true)}>
              <NavItem icon={AddUserIcon} label="Invite a teammate" />
            </Box>
          </Permission>

          <FeedbackMenu>
            <NavItem icon={ChatIcon} label="Get in touch" />
          </FeedbackMenu>

          <NavLink newTab href={import.meta.env.VITE_DOCS_URL as string} icon={DocsIcon} label="Documentation" />

          <UserMenu />
        </Column>
      </Column>
      <InviteFormModal close={() => setOpenInvite(false)} name="Hightouch" open={openInvite} />
    </>
  );
};

const NavLink: FC<
  Readonly<{
    isSelected?: boolean;
    label: string;
    href?: string;
    icon: any;
    permissions?: ResourcePermission[];
    disableContent?: string;
    newTab?: boolean;
  }>
> = ({
  isSelected,
  href,
  label,
  permissions,
  icon,
  disableContent = "You do not have permission to view this.",
  newTab = false,
}) => {
  const router = useLocation();
  const { hasPermissions, slug } = useUser();

  const active = Boolean(typeof isSelected === "undefined" && href ? router.pathname.includes(href) : isSelected);

  const hasPermission = !permissions || hasPermissions(permissions);

  if (hasPermission) {
    return (
      <Link href={newTab ? String(href) : `/${slug}${href}`} {...(newTab ? { target: "_blank", rel: "noreferrer" } : {})}>
        <NavItem active={active} icon={icon} label={label} />
      </Link>
    );
  } else {
    return (
      <Tooltip message={disableContent}>
        <NavItem active={active} icon={icon} label={label} />
      </Tooltip>
    );
  }
};

const NavItem = ({ active, label, icon: Icon }: { active?: boolean; label: string; icon: any }) => {
  const root = useRef<HTMLDivElement>(null);
  const x = useMotionValue(0);
  const y = useMotionValue(0);

  useEffect(() => {
    return x.onChange((value) => {
      root.current?.style.setProperty("--x", String(value));
    });
  }, []);

  useEffect(() => {
    return y.onChange((value) => {
      root.current?.style.setProperty("--y", String(value));
    });
  }, []);

  const mouseMoved = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      // `layerX` and `layerY` are non-standard despite great browser support,
      // so they aren't included in TypeScript types
      const nativeEvent = event.nativeEvent as unknown as { layerX: number; layerY: number };

      // Skip animation if it's not visible
      if (!active) {
        x.set(nativeEvent.layerX);
        y.set(nativeEvent.layerY);
        return;
      }

      animate(x, nativeEvent.layerX, {
        type: "tween",
        duration: 0.05,
      });

      animate(y, nativeEvent.layerY, {
        type: "tween",
        duration: 0.05,
      });
    },
    [active],
  );

  const mouseLeft = useCallback(() => {
    // Skip animation if it's not visible
    if (!active) {
      x.set(200);
      y.set(20);
      return;
    }

    animate(x, 200, {
      type: "tween",
      duration: 0.3,
    });

    animate(y, 20, {
      type: "tween",
      duration: 0.3,
    });
  }, [active]);

  return (
    <Box
      ref={root}
      _hover={{ bg: active ? undefined : "rgba(255,255,255,.08)" }}
      borderRadius="6px"
      overflow="hidden"
      p="1px"
      position="relative"
      style={{
        // @ts-expect-error TypeScript types for `style` don't allow CSS variables
        "--x": "200",
        "--y": "20",
      }}
      width="100%"
      onMouseLeave={mouseLeft}
      onMouseMove={mouseMoved}
    >
      <Box
        _before={{
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          // Shift radial gradient, so that the cursor is at the center of it
          // x = cursor x - gradient width / 2
          // y = cursor y - gradient height / 2
          transform: "translate(calc(var(--x) * 1px - 50px), calc(var(--y) * 1px - 40px))",
          width: "100px",
          height: "80px",
          background: "radial-gradient(rgba(255, 255, 255, .4) 0%, rgba(255, 255, 255, .1) 50%, rgba(255, 255, 255, 0) 100%)",
        }}
        bg="rgba(255, 255, 255, .1)"
        borderRadius="6px"
        inset={0}
        opacity={[0, 0, 0, active ? 1 : 0]}
        // Show only full-sized sidebar
        position="absolute"
        zIndex={1}
      />

      <Box
        alignItems="center"
        bg={active ? "#225f5f" : "transparent"}
        borderRadius="6px"
        color={active ? "white !important" : "rgba(255,255,255,.7) !important"}
        display="flex"
        gap={2.5}
        height="40px"
        justifyContent={["center", "center", "center", "flex-start"]}
        position="relative"
        px={2.5}
        zIndex={2}
      >
        <Icon color={active ? "lightspeed" : "rgba(255,255,255,.7)"} />
        <Box display={["none", "none", "none", "flex"]}>
          <Text sx={{ whiteSpace: "nowrap", fontWeight: 600, fontFamily: "'Sharp Sans Display No 1'", color: "inherit" }}>
            {label}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
