import { FC, useEffect, useState, useRef, useCallback } from "react";

import { Checkbox } from "@hightouchio/ui";
import { Text, Image } from "theme-ui";

import { CustomQuery, CustomQueryForm, validateCustomQuery } from "src/components/sources/forms/custom-query";
import { SqlEditor } from "src/components/sql-editor";
import { ResourcePermissionGrant, SourceDefinition } from "src/graphql";
import { useUnsavedValue } from "src/hooks/use-unsaved-value";
import * as analytics from "src/lib/analytics";
import { Row, Column, Wrap } from "src/ui/box";
import { Button } from "src/ui/button";
import { ChartIcon } from "src/ui/icons";
import { Spinner } from "src/ui/loading";
import { Tooltip } from "src/ui/tooltip";
import { QueryType } from "src/utils/models";
import { useObjectDefinitions } from "src/utils/sources";

import { Permission } from "../permission";
import { DBTModelSelector } from "./dbt-model-selector";
import { DBTSelector } from "./dbt-selector";
import { LookerSelector } from "./looker-selector";
import PlaceholderSVG from "./placeholder.svg";
import { QueryTypeMenu } from "./query-type-menu";
import { Results } from "./results";
import { SelectorRow } from "./selector-row";
import { TableSelector } from "./table-selector";

export type ExploreProps = {
  type?: QueryType;
  onTypeChange?: (type: QueryType) => void;
  sql?: string | null;
  onSQLChange?: (value: string) => void;
  runQuery: (limit: boolean) => Promise<any>;
  cancelQuery: () => void;
  table?: string | null;
  onTableChange?: (table: string) => void;
  dbtModel?: DBTModel;
  lookerLook?: LookerLook;
  onDBTModelChange?: (value: DBTModel) => void;
  onLookerLookChange?: (look: LookerLook) => void;
  customQuery: CustomQuery | undefined;
  onCustomQueryChange?: (query: CustomQuery) => void;
  source: ExploreSource | undefined | null;
  rowsPerPage?: number;
  error: string | undefined;
  errorAtLine: number | undefined;
  rows?: any;
  numRowsWithoutLimit?: number | null;
  isResultTruncated: boolean;
  columns?: any;
  loading?: boolean;
  reset?: () => void;
  onBack?: () => void;
  onSave?: () => Promise<void>;
  saveLoading?: boolean;
  saveDisabled?: boolean;
  saveLabel?: string;
  saveTooltip?: string;
  modelId?: number;
  isQueryDefined: (QueryType) => boolean;
};

export const Explore: FC<Readonly<ExploreProps>> = ({
  type,
  onTypeChange,
  runQuery,
  cancelQuery,
  rows,
  numRowsWithoutLimit,
  isResultTruncated,
  columns,
  loading,
  error: queryError,
  errorAtLine: queryErrorAtLine,
  source,
  sql,
  onSQLChange,
  customQuery,
  onCustomQueryChange,
  table,
  onTableChange,
  dbtModel,
  onDBTModelChange,
  lookerLook,
  onLookerLookChange,
  rowsPerPage,
  reset,
  onBack,
  onSave,
  saveDisabled,
  saveLabel,
  saveLoading,
  saveTooltip,
  modelId,
  isQueryDefined,
}) => {
  const limitPreviewRef = useRef<HTMLDivElement | null>(null);
  const [limit, setLimit] = useState<boolean>(true);
  const [validationError, setValidationError] = useState<Error | null>(null);

  const {
    loading: objectDefinitionsLoading,
    refetch: refetchObjectDefinitions,
    objectDefinitions,
    error: objectDefinitionsError,
  } = useObjectDefinitions(String(source?.id), source?.definition?.supportedQueries.includes(QueryType.Table));

  useEffect(() => {
    if (queryError) {
      analytics.track("Model Query Error", {
        model_type: source?.definition?.type,
        query_mode: type,
        error: queryError,
      });
    }
  }, [queryError]);

  useEffect(() => {
    const handler = (event) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
        runQuery(limit);
      }
    };
    window.addEventListener("keydown", handler);

    return () => window.removeEventListener("keydown", handler);
  }, [runQuery, limit]);

  useEffect(() => {
    return reset;
  }, []);

  const error = queryError || objectDefinitionsError;

  const validateQuery = async (): Promise<boolean> => {
    switch (type) {
      case QueryType.Custom:
        try {
          if (source) {
            await validateCustomQuery(source, customQuery);
          }
        } catch (err) {
          setValidationError(err);
          return false;
        }
        setValidationError(null);
        return true;
      default:
        setValidationError(null);
        return true;
    }
  };

  const unsavedSql = useUnsavedValue<string>("sql");

  const save = useCallback(() => {
    unsavedSql.clear();

    if (typeof onSave === "function") {
      onSave();
    }
  }, [unsavedSql.clear, onSave]);

  const showQueryTypeMenu = source && type && onTypeChange && source?.definition?.supportedQueries?.length > 1;

  return (
    <>
      <Row sx={{ width: "100%", alignItems: "center", justifyContent: "space-between", mb: 4 }}>
        {(onBack || onTypeChange) && (
          <Wrap spacing={4} sx={{ alignItems: "center", flex: 1 }}>
            {onBack && (
              <Button variant="secondary" onClick={onBack}>
                Back
              </Button>
            )}
            {showQueryTypeMenu && (
              <QueryTypeMenu supportedQueries={source.definition.supportedQueries} type={type} onChange={onTypeChange} />
            )}
          </Wrap>
        )}
        <Wrap spacing={2} sx={{ flex: 1, alignItems: "center", justifyContent: "flex-end" }}>
          {!source?.definition?.cleanRoomType && (
            <Row ref={limitPreviewRef} sx={{ alignItems: "center", gap: 1 }}>
              <Checkbox
                isChecked={limit}
                label="Limit preview to 100 records"
                onChange={(event) => setLimit(event.target.checked)}
              />
              <Tooltip text="Automatically add a LIMIT expression to the query to cap the number of returned results to 100. For most sources, Hightouch will still show the row count of the full query results." />
            </Row>
          )}

          {!source?.definition?.cleanRoomType && (
            <Permission
              permissions={[{ resource: "source", grants: [ResourcePermissionGrant.Preview], resource_id: source?.id }]}
            >
              <Button
                disabled={!isQueryDefined(type)}
                loading={loading}
                variant="secondary"
                onClick={async () => {
                  await runQuery(limit);
                  analytics.track("Model Query Previewed", { model_type: source?.definition?.type, query_mode: type });
                }}
              >
                Preview
              </Button>
            </Permission>
          )}

          <Permission permissions={[{ resource: "model", grants: [ResourcePermissionGrant.Update], resource_id: modelId }]}>
            {onSave && (
              <Button
                disabled={!isQueryDefined(type) || saveDisabled}
                loading={saveLoading}
                tooltip={saveTooltip}
                onClick={async () => {
                  if (await validateQuery()) {
                    save();
                  }
                }}
              >
                {saveLabel || "Save"}
              </Button>
            )}
          </Permission>
        </Wrap>
      </Row>
      <Column
        sx={{
          flex: 1,
          width: "100%",
          flexDirection: type === QueryType.Custom ? "row" : "column",
        }}
      >
        <Column
          sx={{
            flex: 1,
            maxWidth: type === QueryType.Custom ? "554px" : undefined,
            maxHeight: type === QueryType.Custom || type === QueryType.RawSql ? undefined : "50vh",
          }}
        >
          {type === QueryType.Table && source && onTableChange ? (
            <TableSelector
              loading={objectDefinitionsLoading}
              objectDefinitions={objectDefinitions}
              reload={refetchObjectDefinitions}
              source={source}
              table={table}
              onChange={onTableChange}
            />
          ) : type === QueryType.Dbt ? (
            <DBTSelector
              dbtModelId={dbtModel?.id}
              objectDefinitions={objectDefinitions}
              objectDefinitionsLoading={objectDefinitionsLoading}
              onChange={onDBTModelChange}
            />
          ) : type === QueryType.DbtModel && source && onDBTModelChange ? (
            <DBTModelSelector dbtModelId={dbtModel?.id} source={source} onChange={onDBTModelChange} />
          ) : type === QueryType.LookerLook && source && onLookerLookChange ? (
            <LookerSelector lookerLookId={lookerLook?.id} source={source} onChange={onLookerLookChange} />
          ) : type === QueryType.Custom && source && onCustomQueryChange ? (
            <CustomQueryForm query={customQuery} source={source} onChange={onCustomQueryChange} />
          ) : (
            <Row sx={{ width: "100%", overflow: "hidden" }}>
              {source?.definition?.isSampleDataSource === true && source?.definition?.sampleModels?.length && (
                <Column sx={{ minWidth: "360px", maxWidth: "480px", mr: 4 }}>
                  <Row sx={{ borderBottom: "small", width: "100%", height: "50px", alignItems: "center" }}>
                    <Text sx={{ fontSize: 2, fontWeight: "semi" }}>Sample Models</Text>
                  </Row>
                  <Column sx={{ overflow: "auto", flex: 1 }}>
                    {source?.definition?.sampleModels?.map((model) => {
                      return (
                        <SelectorRow
                          key={model?.name ?? ""}
                          icon={<ChartIcon />}
                          selected={model?.sql === sql}
                          onClick={() => {
                            onSQLChange?.(model?.sql ?? "");
                          }}
                        >
                          {model?.name}
                        </SelectorRow>
                      );
                    })}
                  </Column>
                </Column>
              )}
              <SqlEditor
                highlightErroredLine={queryErrorAtLine}
                placeholder={
                  source?.definition?.isSampleDataSource === true
                    ? "Select a sample model or enter a custom query..."
                    : "Enter your query..."
                }
                source={source ?? undefined}
                unsavedValue={unsavedSql}
                value={sql ?? ""}
                onChange={onSQLChange}
              />
            </Row>
          )}
        </Column>

        <Column sx={{ flex: 1, mt: type === QueryType.Custom ? 0 : 8, ml: type === QueryType.Custom ? 8 : 0 }}>
          {(rows || error) && !loading ? (
            <Results
              columns={columns}
              error={error || validationError?.message}
              isResultTruncated={isResultTruncated}
              numRowsWithoutLimit={numRowsWithoutLimit ?? undefined}
              rows={rows}
              rowsPerPage={rowsPerPage}
            />
          ) : (
            <Column
              sx={{
                border: "small",
                borderStyle: "dashed",
                justifyContent: "center",
                alignItems: "center",
                p: 4,
                bg: "base.1",
                flex: 1,
              }}
            >
              {loading ? (
                <>
                  <Spinner size={64} />
                  <Button size="small" sx={{ mt: 6 }} variant="secondary" onClick={cancelQuery}>
                    Cancel
                  </Button>
                </>
              ) : (
                <>
                  <Image src={PlaceholderSVG} />
                  <Text sx={{ fontSize: 2, fontWeight: "semi", mb: 2, mt: 4, textAlign: "center" }}>
                    Ready to test this query?
                  </Text>
                  <Text sx={{ color: "base.5", textAlign: "center" }}>A preview of the resulting rows will appear here</Text>
                </>
              )}
            </Column>
          )}
        </Column>
      </Column>
    </>
  );
};

export interface ExploreSource {
  name: string;
  id: string;
  type: string;
  config: Record<string, any>;
  definition: {
    name: string;
    icon: string;
    type: string;
    supportsResultSchema: boolean;
    isSampleDataSource: boolean;
    supportedQueries: SourceDefinition["supportedQueries"];
    sampleModels: SourceDefinition["sampleModels"];
    cleanRoomType: SourceDefinition["cleanRoomType"];
  };
}

export type DBTModel = {
  id: number;
  alias: string;
  description: string;
  name: string;
  original_file_path: string;
  git_sync_config: {
    id: number;
    schema: string;
  };
};

export type DBTSyncModel = {
  id: number;
  name: string;
  schema: string;
  dbt_unique_id: string;
  dbt_sync_config: {
    id: number;
    branch: string;
    repository: string;
  };
  data: {
    raw_sql: string;
    compiled_sql: string;
    [key: string]: any;
  };
  removed: boolean;
};

// TODO this should be defined in types.ts somewhere
export type LookerLook = {
  id: string | undefined | null;
  title: string;
};
