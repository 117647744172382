import { FC, forwardRef, useEffect, useMemo, useRef, useState } from "react";

import { isEqual } from "lodash";
import { Text } from "theme-ui";

import { Column, Row } from "src/ui/box";
import { TraitIcon } from "src/ui/icons";
import { Input } from "src/ui/input";
import { getSearchRegExp } from "src/utils/string";

import { ColumnOption, useFormkitContext } from "./formkit-context";
import { FormProps } from "./types";

interface CustomColumnOption {
  label: ColumnOption["label"];
  type?: ColumnOption["type"];
  value?: ColumnOption["value"];
  options?: ColumnOption["options"];
}

export const StandardInput: FC<Readonly<FormProps>> = ({ value, onChange }) => {
  const [search, setSearch] = useState<string>("");
  const { columns } = useFormkitContext();
  const searchRef = useRef<HTMLInputElement | null>(null);
  const selectedColumnRef = useRef<HTMLDivElement | null>(null);

  const options = useMemo(() => {
    if (search) {
      const regex = getSearchRegExp(search, "i");
      const filteredColumns: CustomColumnOption[] = [];

      for (const column of columns ?? []) {
        if (column.options) {
          filteredColumns.push({
            label: column.label,
            options: column.options.filter((option) => regex.test(option.label)),
          });
        } else if (regex.test(column.label)) {
          filteredColumns.push(column);
        }
      }

      return filteredColumns;
    }

    return columns;
  }, [columns, search]);

  useEffect(() => {
    if (searchRef && searchRef.current) {
      // set timeout required to allow the input to be focused
      setTimeout(() => {
        searchRef.current?.focus();
      }, 10);
    }
  }, [searchRef]);

  useEffect(() => {
    if (selectedColumnRef && selectedColumnRef.current) {
      selectedColumnRef.current.scrollIntoView();
    }
  }, [selectedColumnRef]);

  return (
    <Column sx={{ p: 3, flex: 1 }}>
      <Input ref={searchRef} placeholder="Search..." sx={{ width: "100%", flex: 0 }} value={search} onChange={setSearch} />
      <Column sx={{ mt: 4, overflow: "auto" }}>
        {options.map((option, index) => {
          if (option.options) {
            const group = option.label;
            return (
              <>
                <Text
                  sx={{
                    fontSize: 0,
                    color: "base.5",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    pl: 1,
                    pb: 2,
                    pt: index > 0 ? 4 : 0,
                  }}
                >
                  {group}
                </Text>
                {option.options.map((groupedOption) => {
                  const selected = isEqual(groupedOption.value, value.from);
                  return (
                    <Option
                      key={groupedOption.label}
                      ref={selected ? selectedColumnRef : undefined}
                      option={groupedOption}
                      selected={selected}
                      onClick={() => onChange({ type: "standard", from: groupedOption.value })}
                    />
                  );
                })}
              </>
            );
          }
          const selected = isEqual(option.value, value);
          return (
            <Option
              key={option.label}
              ref={selected ? selectedColumnRef : undefined}
              option={option}
              selected={selected}
              onClick={() => onChange({ type: "standard", from: option.label })}
            />
          );
        })}
      </Column>
    </Column>
  );
};

const Option = forwardRef<HTMLDivElement, { option: CustomColumnOption; selected: boolean; onClick: () => void }>(
  ({ option, selected, onClick }, ref) => {
    return (
      <Row
        ref={ref}
        sx={{
          color: selected ? "primary" : undefined,
          minHeight: "32px",
          alignItems: "center",
          fontSize: 0,
          flex: 0,
          cursor: "pointer",
          px: 2,
          borderBottom: "small",
          bg: selected ? "primaries.0" : undefined,
          pointerEvents: selected ? "none" : undefined,
          ":hover": { bg: "base.0" },
        }}
        onClick={onClick}
      >
        {formatValue(option)}
        <Text sx={{ flex: 1, color: "base.5", textTransform: "capitalize" }}>{option.type}</Text>
      </Row>
    );
  },
);

export const formatValue = (option: CustomColumnOption) => {
  if (typeof option?.value === "object") {
    if (option?.value?.type === "additionalColumnReference") {
      return (
        <Row sx={{ alignItems: "center" }}>
          <TraitIcon color="base.5" size={16} sx={{ mr: 2 }} />
          <Text sx={{ fontWeight: "semi", flex: 1, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
            {option.label}
          </Text>
        </Row>
      );
    }
  }
  return option?.label;
};

Option.displayName = "Option";
