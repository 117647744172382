import { useEffect, useMemo, useState } from "react";

import { capitalize } from "lodash";
import { Text, Image } from "theme-ui";

import { LabelsCell } from "src/components/labels/labels-cell";
import { Query } from "src/components/models/query";
import placeholderSource from "src/components/permission/source.svg";
import { ResourceSelect } from "src/components/resource-select";
import { useDraft } from "src/contexts/draft-context";
import { SegmentsBoolExp, SegmentsOrderBy, useModelsAndAudiencesQuery } from "src/graphql";
import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { AudienceIcon } from "src/ui/icons";
import { Modal } from "src/ui/modal";
import { TableColumn, useTableConfig } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { TextWithTooltip } from "src/ui/text";
import { Tooltip } from "src/ui/tooltip";
import { QueryType } from "src/utils/models";

enum SortKeys {
  Name = "name",
  UpdatedAt = "updated_at",
  QueryType = "query_type",
}

export const ModelSelect = ({ onSelect }) => {
  const [search, setSearch] = useState("");
  const [previewModel, setPreviewModel] = useState<any>();
  const { setEditingDraft } = useDraft();

  const { limit, offset, orderBy, page, setPage, onSort } = useTableConfig<SegmentsOrderBy>({
    defaultSortKey: "updated_at",
    limit: 200,
    sortOptions: Object.values(SortKeys),
  });

  const filters: SegmentsBoolExp = useMemo(() => {
    const filters: SegmentsBoolExp = {
      _and: [
        {
          is_schema: { _eq: false },
        },
      ],
    };
    if (search) {
      filters._and!.push({ name: { _ilike: `%${search}%` } });
    }
    return filters;
  }, [search]);

  const {
    data,
    error,
    isFetching: loading,
  } = useModelsAndAudiencesQuery(
    {
      offset,
      limit,
      filters,
      orderBy,
    },
    { keepPreviousData: true },
  );

  const models = data?.segments;
  const count = data?.segments_aggregate?.aggregate?.count;

  const columns = useMemo(
    (): TableColumn[] => [
      {
        name: "Name",
        sortDirection: orderBy?.name,
        onClick: () => onSort(SortKeys.Name),
        cell: ({ name, connection: source, query_type: type, tags }) => {
          return (
            <Tooltip disabled={Boolean(source)} text="This destination is only visible to some users">
              <Row sx={{ alignItems: "center", gap: 4 }}>
                {type === QueryType.Visual ? (
                  <AudienceIcon />
                ) : (
                  <Image
                    alt={source?.definition?.name ?? "Private source"}
                    src={source?.definition?.icon ?? placeholderSource}
                    sx={{ width: "20px", maxHeight: "100%", objectFit: "contain", flexShrink: 0 }}
                  />
                )}
                <TextWithTooltip disabled={!name} sx={{ fontWeight: "bold", maxWidth: "32vw", color: "black" }} text={name}>
                  {name ?? "Private source"}
                </TextWithTooltip>
                <LabelsCell labels={tags} />
              </Row>
            </Tooltip>
          );
        },
      },
      {
        name: "Type",
        max: "200px",
        sortDirection: orderBy?.query_type,
        onClick: () => onSort(SortKeys.QueryType),
        cell: ({ query_type, custom_query }) => (
          <Text sx={{ fontWeight: "semi", color: "base.6" }}>
            {query_type === QueryType.Table
              ? "Table"
              : query_type === QueryType.Visual
              ? "Audience"
              : query_type === QueryType.Dbt
              ? "dbt"
              : query_type === QueryType.DbtModel
              ? "dbt Model"
              : query_type === QueryType.Custom
              ? custom_query?.["type"]
                ? capitalize(custom_query["type"])
                : "Custom"
              : "SQL"}
          </Text>
        ),
      },
      {
        ...LastUpdatedColumn,
        max: "200px",
        sortDirection: orderBy?.updated_at,
        onClick: () => onSort(SortKeys.UpdatedAt),
      },
      {
        name: "",
        max: "max-content",
        cell: (model) =>
          model.query_type !== QueryType.Visual ? (
            <Button size="small" variant="secondary" onClick={() => setPreviewModel(model)}>
              View query
            </Button>
          ) : null,
      },
    ],
    [models],
  );

  useEffect(() => {
    setEditingDraft(true);
  }, []);

  useEffect(() => {
    setPage(0);
  }, [filters]);

  return (
    <>
      <ResourceSelect
        columns={columns}
        data={models}
        error={Boolean(error)}
        label="model"
        loading={loading}
        pagination={{ count, page, setPage, limit }}
        search={search}
        onSearch={setSearch}
        onSelect={onSelect}
      />
      <Modal
        info
        isOpen={Boolean(previewModel)}
        sx={{ width: "50vw", height: "50vh" }}
        title={previewModel?.name}
        onClose={() => setPreviewModel(undefined)}
      >
        <Query model={previewModel} />
      </Modal>
    </>
  );
};
