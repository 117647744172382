import { useMemo } from "react";

import { UseQueryResult } from "react-query";

interface IncrementalQuery<DataType> {
  data: DataType;
  error: any;
  isFetching: boolean;
  isLoading: boolean;
  fullQueryRefetch: () => void;
}

export function useIncrementalQuery<MinimalQueryData, QueryData extends MinimalQueryData>(
  minimalQuery: UseQueryResult<Partial<QueryData>>,
  fullQuery: UseQueryResult<QueryData>,
): IncrementalQuery<Partial<QueryData> | undefined> {
  const data = useMemo(() => {
    if (fullQuery.dataUpdatedAt > minimalQuery.dataUpdatedAt) {
      return fullQuery.data;
    }

    return minimalQuery.data;
  }, [minimalQuery.data, fullQuery.data]);

  return {
    data,
    error: fullQuery.error || minimalQuery.error,
    isFetching: minimalQuery.isFetching,
    isLoading: minimalQuery.isLoading,
    fullQueryRefetch: fullQuery.refetch,
  };
}
