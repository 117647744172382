import { useEffect, FC } from "react";

import { Controller, useFormContext } from "react-hook-form";
import { Grid } from "theme-ui";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { Column } from "src/ui/box";
import { FieldError } from "src/ui/field";
import { ArrowRightIcon } from "src/ui/icons";
import { CreatableSelect, Select } from "src/ui/select";

import { Option } from "../../../../formkit";
import { useFormkitContext } from "./formkit-context";
import { formatFromColumnOption, formatOptionLabel } from "./mappings";
import { MappingsHeader } from "./mappings-header";

type Props = {
  name: string;
  options?: any;
  /**
  @default Columns in the model
  */
  fromOptions?: any;
  /**
  @default Name of model
  */
  fromLabel?: string;
  /**
  @default Icon of source
  */
  fromIcon?: string;
  fromLoadingOptions?: boolean;
  fromReloadOptions?: () => void;
  creatable?: boolean;
  /** `creatableTypes` are the types the user can select from when creating their own field. */
  creatableTypes?: Option[];
  loading?: boolean;
  object?: string;
  error?: string;
  reload?: () => void;
};

const retrieveErrorMessage = (errors, name: string) => {
  for (const direction of ["from", "to"]) {
    const mappingPath = `${name}.${direction}`;
    const errorMessage = errors?.[mappingPath];
    if (typeof errorMessage === "string") {
      return errorMessage.replace(mappingPath, "This");
    }
  }
  return "";
};

export const Mapping: FC<Readonly<Props>> = ({
  name,
  object,
  options,
  fromOptions,
  fromLabel,
  fromIcon,
  fromLoadingOptions,
  fromReloadOptions,
  creatable,
  creatableTypes,
  loading,
  reload,
  error,
}) => {
  const columns = fromOptions ? fromOptions : useFormkitContext().columns;
  const { errors } = useDestinationForm();
  const { watch, setValue } = useFormContext();

  const value = watch(name);

  useEffect(() => {
    if (!value) {
      setValue(name, {});
    }
  }, [value]);

  if (!value) {
    return null;
  }

  return (
    <Column>
      <Grid sx={{ gridTemplateColumns: "1fr max-content 1fr max-content", alignItems: "center" }}>
        <MappingsHeader
          columns={4}
          fromIcon={fromIcon}
          fromLabel={fromLabel}
          fromLoadingOptions={fromLoadingOptions}
          fromReloadOptions={fromReloadOptions}
          loading={loading}
          object={object}
          reload={reload}
          usingCustomFromOptions={Boolean(fromOptions)}
        />

        <Controller
          name={name}
          render={(data) => (
            <>
              <Select
                formatOptionLabel={formatFromColumnOption}
                isError={!!errors?.[`${data.field.name}.from`]}
                options={columns}
                placeholder="Select a column..."
                value={data.field.value.from}
                onChange={(option) => data.field.onChange({ ...data.field.value, from: option?.value || undefined })}
              />

              <ArrowRightIcon color="base.3" size={16} />

              <Column>
                {creatable ? (
                  <>
                    <CreatableSelect
                      isClearable
                      empty="Type in a field name and Hightouch will create the field in the destination!"
                      formatCreateLabel={(string) => `Create field "${string}"`}
                      formatOptionLabel={formatOptionLabel}
                      isError={!!errors?.[`${data.field.name}.to`]}
                      isLoading={loading}
                      isValidNewOption={(inputValue, _, selectOptions) => {
                        return !selectOptions?.find((v) => v.value === inputValue) && Boolean(inputValue);
                      }}
                      options={options || []}
                      placeholder="Select or add a field..."
                      value={data.field.value?.to}
                      onChange={(option) =>
                        data.field.onChange({
                          ...data.field.value,
                          to: option?.value || undefined,
                          fieldType: option?.type || undefined,
                        })
                      }
                      onCreateOption={(value) => {
                        data.field.onChange({ ...data.field.value, to: value || undefined });
                      }}
                    />
                    {creatableTypes && data.field.value?.to && !options?.find((v) => v.value === data.field.value?.to) && (
                      <Select
                        options={creatableTypes}
                        placeholder="Select a field type..."
                        value={data.field.value?.fieldType}
                        onChange={(creatableType) => {
                          data.field.onChange({ ...data.field.value, fieldType: creatableType?.value });
                        }}
                      />
                    )}
                  </>
                ) : (
                  <Select
                    formatOptionLabel={formatOptionLabel}
                    isError={!!errors?.[`${data.field.name}.to`]}
                    options={options || []}
                    placeholder="Select a field..."
                    value={data.field.value.to}
                    onChange={(option) => data.field.onChange({ ...data.field.value, to: option?.value || undefined })}
                  />
                )}
              </Column>
            </>
          )}
        />
      </Grid>

      <FieldError error={retrieveErrorMessage(errors, name) || error} />
    </Column>
  );
};
