import { useState, useMemo, useEffect, Dispatch, SetStateAction, useCallback } from "react";

import { differenceBy } from "lodash";
import { useQueryClient } from "react-query";
import type { MarkOptional } from "ts-essentials";
import { v4 as uuid } from "uuid";

import { DBTModel, LookerLook } from "src/components/explore/explore";
import { CustomQuery, isCustomQueryDirty } from "src/components/sources/forms/custom-query";
import { useDraft } from "src/contexts/draft-context";
import { useUser } from "src/contexts/user-context";
import {
  RunDbtModelQuery,
  RunDbtQuery,
  RunSqlQuery,
  RunTableQuery,
  useUpdateQueryMutation,
  useRunDbtModelQuery,
  useRunDbtQuery,
  useRunLookerLookQuery,
  useRunSqlQuery,
  useRunTableQuery,
  useRunVisualQuery,
  useRunCustomQuery,
  useDeleteModelColumnsMutation,
  QueryResponse,
  RunVisualQuery,
  ModelColumns,
  RunCustomQuery,
  usePreviewQuerySchemaQuery,
  PreviewQuerySchemaQuery,
  RunLookerLookQuery,
  ModelQuery,
  SegmentsSetInput,
  SuccessfulQueryResponse,
  ModelColumnInput,
  Maybe,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { VisualQueryFilter } from "src/types/visual";

type ModelQuerySegment = NonNullable<ModelQuery["segments_by_pk"]>;

export interface Model {
  id: ModelQuerySegment["id"];
  name: ModelQuerySegment["name"];
  connection: Maybe<{
    id: string;
    type: string;
  }>;
  query_type?: ModelQuerySegment["query_type"];
  query_raw_sql?: ModelQuerySegment["query_raw_sql"];
  query_table_name?: ModelQuerySegment["query_table_name"];
  query_dbt_model_id?: ModelQuerySegment["query_dbt_model_id"];
  query_looker_look_id?: ModelQuerySegment["query_looker_look_id"];
  custom_query?: ModelQuerySegment["custom_query"];
  visual_query_filter?: ModelQuerySegment["visual_query_filter"];
  columns?: ModelQuerySegment["columns"];
  draft?: boolean;
  // `title` property doesn't seem to exist, may have been a leftover from the past code
  // so, try removing it when we have a strict TypeScript config
  title?: string;
}

// Copy from packages/backend/core/lib/query/index.ts
export enum QueryType {
  RawSql = "raw_sql",
  Visual = "visual",
  Table = "table",
  DbtModel = "dbt_model",
  Dbt = "dbt",
  LookerLook = "looker_look",
  Custom = "custom",
}

export const QueryTypeDictionary: Record<QueryType, string> = {
  [QueryType.RawSql]: "SQL",
  [QueryType.Visual]: "Visual",
  [QueryType.Table]: "Table",
  [QueryType.DbtModel]: "dbt Model",
  [QueryType.Dbt]: "dbt",
  [QueryType.LookerLook]: "Looker Look",
  [QueryType.Custom]: "Custom",
};

export const RUN_QUERY = {
  [QueryType.RawSql]: useRunSqlQuery,
  [QueryType.Visual]: useRunVisualQuery,
  [QueryType.Table]: useRunTableQuery,
  [QueryType.Dbt]: useRunDbtQuery,
  [QueryType.DbtModel]: useRunDbtModelQuery,
  [QueryType.LookerLook]: useRunLookerLookQuery,
  [QueryType.Custom]: useRunCustomQuery,
};

// Error type used to signal that a column deletion error occurred, so the frontend
// can handle it specially.
export class DeleteColumnsError extends Error {
  deletedColumns: string[];

  constructor(message: string, options: ErrorOptions & { deletedColumns: string[] }) {
    super(message, options);
    this.deletedColumns = options.deletedColumns;
  }
}

export const EMPTY_AUDIENCE_DEFINITION = { conditions: [] };

export const getVariables = (
  type: QueryType,
  options: {
    // The id of the model if previewing an existing model.
    modelId?: string;
    variables: any;
  },
): any => {
  switch (type) {
    case QueryType.RawSql: {
      return {
        sql: options?.variables?.sql,
        modelId: Number(options?.modelId),
      };
    }

    case QueryType.Visual: {
      return {
        parentModelId: String(options?.variables?.parentModelId),
        filter: options?.variables?.visualQueryFilter || EMPTY_AUDIENCE_DEFINITION,
        audienceId: options.modelId ? options.modelId.toString() : undefined,
      };
    }

    case QueryType.Table: {
      return {
        table: options?.variables?.table,
        modelId: Number(options?.modelId),
      };
    }

    case QueryType.Dbt: {
      return {
        dbtModelId: options?.variables?.dbtModel?.id,
        modelId: Number(options?.modelId),
      };
    }

    case QueryType.DbtModel: {
      return {
        dbtModelId: options?.variables?.dbtModel?.id,
        modelId: Number(options?.modelId),
      };
    }

    case QueryType.LookerLook: {
      return {
        lookId: options?.variables?.lookerLook?.id,
        modelId: Number(options?.modelId),
      };
    }

    case QueryType.Custom: {
      return {
        customQuery: options?.variables?.customQuery,
        modelId: Number(options?.modelId),
      };
    }

    default:
      throw new Error(`Unsupported query type ${type}`);
  }
};

type InnerQueryResult =
  | RunVisualQuery
  | RunSqlQuery
  | RunTableQuery
  | RunDbtModelQuery
  | RunDbtQuery
  | RunLookerLookQuery
  | RunCustomQuery;

function getInnerQueryResult(result: InnerQueryResult): QueryResponse {
  return Object.values(result)[0] as QueryResponse;
}

export const useModelRun = (
  type: QueryType | undefined,
  modelColumns: any,
  options: {
    // The id of the model if previewing an existing model.
    modelId?: string;
    variables: any;
    onCompleted?: (data, error) => void;
  },
) => {
  const client = useQueryClient();
  const { featureFlags } = useUser();
  const [loading, setLoading] = useState<boolean>(false);
  const [schemaLoading, setSchemaLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [errorAtLine, setErrorAtLine] = useState<number | undefined>();
  const [runId, setRunId] = useState<string | undefined>("");
  const [result, setResult] = useState<{
    data?: MarkOptional<SuccessfulQueryResponse, "rows" | "numRowsWithoutLimit" | "sql">;
    id: string;
  }>({ id: "" });
  const [transformedSql, setTransformedSql] = useState<string | undefined>();

  const cancelQuery = () => {
    setRunId("");
    setLoading(false);
    setSchemaLoading(false);
    setError(undefined);
    setResult({
      ...result,
      data: undefined,
    });
  };

  const getSchema = async () => {
    if (!type) {
      return undefined;
    }

    setSchemaLoading(true);
    const id = uuid();
    setRunId(id);

    const variables = { sourceId: String(options?.variables?.sourceId), queryType: type, query: getVariables(type, options) };

    const queryKey = usePreviewQuerySchemaQuery.getKey(variables);
    const queryFn = usePreviewQuerySchemaQuery.fetcher(variables);

    let error: Error | undefined = undefined;
    let data: typeof result["data"];

    try {
      const response = await client.fetchQuery<PreviewQuerySchemaQuery>(queryKey, {
        queryFn,
        // Disable caching the query results.
        cacheTime: 0,
      });
      data = { rows: undefined, columns: response.previewQuerySchema.columns, exceedsPreviewMax: false };
    } catch (e) {
      error = e;
      setError(e.message);
      setErrorAtLine(undefined);
    }

    setResult({ id, data });
    setSchemaLoading(false);

    return { data, error };
  };

  const resetRunState = () => {
    setRunId("");
    setLoading(false);
    setSchemaLoading(false);
    setError("");
  };

  const runQuery = useCallback(
    async (limit: boolean): Promise<void> => {
      if (!type) {
        return undefined;
      }

      setLoading(true);
      const id = uuid();
      setRunId(id);

      const commonVariables = {
        sourceId: String(options?.variables?.sourceId),
        // NOTE: the feature flag names on the right side need to match the names
        // in the database
        disableRowCounter: featureFlags?.sql_row_counter_disabled ? true : undefined,
        // In the backend we actually query for 101 rows, but only return 100,
        // so that we can tell if the result is truncated.
        limit: limit ? 100 : undefined,
      };

      const variables = { ...commonVariables, ...getVariables(type, options) };

      const queryKey = RUN_QUERY[type].getKey(variables);
      const queryFn = RUN_QUERY[type].fetcher(variables);

      let responseResult: QueryResponse | null = null;
      try {
        const response = await client.fetchQuery<InnerQueryResult>(queryKey, {
          queryFn,
          // Disable caching the query results.
          cacheTime: 0,
        });
        responseResult = getInnerQueryResult(response);
      } catch (err) {
        setError(err.message);
        setErrorAtLine(undefined);
        setLoading(false);
        setResult({ id, data: undefined });
        setTransformedSql(undefined);
        return;
      }

      if (responseResult && responseResult.__typename === "FailedQueryResponse") {
        setError(responseResult.error);
        setTransformedSql(responseResult.sql as string);
        const lineNumber = responseResult.metadata?.line;
        if (lineNumber) {
          setErrorAtLine(lineNumber);
        }
        setResult({ id, data: undefined });
        setLoading(false);
        return;
      }

      setErrorAtLine(undefined);
      setError(undefined);
      setResult({ id, data: responseResult as SuccessfulQueryResponse });
      setTransformedSql(responseResult.sql || undefined);
      setLoading(false);
    },
    [client, featureFlags, options, type],
  );

  useEffect(() => {
    if (!result.data) {
      return;
    }
    if (result.id === runId) {
      if (options?.onCompleted) {
        options.onCompleted(
          {
            columns: result.data?.columns?.map(({ name, type, raw_type }) => ({ name, type, raw_type })), //remove __typename,
            rows: result.data?.rows,
          },
          undefined,
        );
      }
    }
  }, [runId, result]);

  const rows = useMemo(() => {
    return result.data?.rows?.map((row) => aliasRow(row, modelColumns));
  }, [result.data?.rows, modelColumns]);

  const columns = useMemo(
    () => aliasColumns(result.data?.columns, modelColumns)?.map(({ name, type, raw_type }) => ({ name, type, raw_type })),
    [result.data?.columns, modelColumns],
  ); //remove __typename

  return {
    runQuery,
    getSchema,
    cancelQuery,
    resetRunState,
    schemaLoading,
    loading,
    error,
    errorAtLine,
    columns,
    transformedSql,
    rawColumns: result.data?.columns?.map(({ name, type, raw_type }) => ({ name, type, raw_type })),
    rows,
    numRowsWithoutLimit: result.data?.numRowsWithoutLimit,
    isResultTruncated: result.data?.exceedsPreviewMax,
  };
};

interface UseQueryStateResult {
  queryState: QueryState;
  isDirty: (model: Model | null) => boolean;
  isQueryDefined: (type: QueryType | undefined) => boolean;
  resetQueryState: () => void;
  initQueryState: (model: Model | null | undefined) => void;
  setSQL: Dispatch<SetStateAction<string>>;
  setVisualQueryFilter: Dispatch<SetStateAction<VisualQueryFilter | undefined>>;
  setTable: Dispatch<SetStateAction<string>>;
  setDBTModel: Dispatch<SetStateAction<DBTModel | undefined>>;
  setLookerLook: Dispatch<SetStateAction<LookerLook | undefined>>;
  setCustomQuery: Dispatch<SetStateAction<CustomQuery | undefined>>;
}

export interface QueryState {
  sql: string | undefined | null;
  visualQueryFilter: VisualQueryFilter | undefined;
  table: string | undefined | null;
  dbtModel: DBTModel | undefined;
  lookerLook: LookerLook | undefined;
  customQuery: CustomQuery | undefined;
}

export const useQueryState = (): UseQueryStateResult => {
  const [sql, setSQL] = useState<string>("");
  const [visualQueryFilter, setVisualQueryFilter] = useState<VisualQueryFilter | undefined>();
  const [table, setTable] = useState<string>("");
  const [dbtModel, setDBTModel] = useState<DBTModel | undefined>();
  const [lookerLook, setLookerLook] = useState<LookerLook | undefined>();
  const [customQuery, setCustomQuery] = useState<CustomQuery | undefined>();

  const reset = () => {
    setSQL("");
    setVisualQueryFilter(undefined);
    setTable("");
    setDBTModel(undefined);
    setLookerLook(undefined);
    setCustomQuery(undefined);
  };

  const init = (model: Model | null | undefined) => {
    setSQL(model?.query_raw_sql ?? "");
    setVisualQueryFilter(model?.visual_query_filter ?? EMPTY_AUDIENCE_DEFINITION);
    setTable(model?.query_table_name ?? "");
    setDBTModel({ id: model?.query_dbt_model_id } as DBTModel);
    setLookerLook({ id: model?.query_looker_look_id, title: "" });
    setCustomQuery(model?.custom_query);
  };

  const queryState = useMemo(
    () => ({
      sql,
      visualQueryFilter,
      table,
      dbtModel,
      lookerLook,
      customQuery,
    }),
    [sql, visualQueryFilter, table, dbtModel, lookerLook, customQuery],
  );

  const isDirty = (model: Model | null): boolean => {
    return (
      queryState?.sql !== model?.query_raw_sql ||
      queryState?.table !== model?.query_table_name ||
      queryState?.dbtModel?.id !== model?.query_dbt_model_id ||
      queryState?.lookerLook?.id !== model?.query_looker_look_id ||
      isCustomQueryDirty(queryState?.customQuery, model?.custom_query)
    );
  };

  const isQueryDefined = (type: QueryType | undefined) => {
    switch (type) {
      case QueryType.RawSql:
        return Boolean(sql);
      case QueryType.Table:
        return Boolean(table);
      case QueryType.Dbt:
        return Boolean(dbtModel);
      case QueryType.DbtModel:
        return Boolean(dbtModel);
      case QueryType.LookerLook:
        return Boolean(lookerLook);
      case QueryType.Custom:
        return Boolean(customQuery);
      default:
        return false;
    }
  };

  return {
    queryState,
    isDirty,
    isQueryDefined,
    resetQueryState: reset,
    initQueryState: init,
    setSQL,
    setVisualQueryFilter,
    setTable,
    setDBTModel,
    setLookerLook,
    setCustomQuery,
  };
};

export const useModelState = () => {
  const [name, setName] = useState("");
  const [timestampColumn, setTimestampColumn] = useState("");
  const [primaryKey, setPrimaryKey] = useState("");

  const modelState = useMemo(
    () => ({
      name,
      timestampColumn,
      primaryKey,
    }),
    [name, primaryKey, timestampColumn],
  );

  const init = (model) => {
    setName(model?.name);
    setPrimaryKey(model?.primary_key);
    setTimestampColumn(model?.event?.timestamp_column);
  };

  return {
    modelState,
    initModelState: init,
    setName,
    setPrimaryKey,
    setTimestampColumn,
  };
};

export const useUpdateQuery = ({ logUpdate } = { logUpdate: true }) => {
  const { user } = useUser();
  const { mutateAsync: updateQuery } = useUpdateQueryMutation();
  const { mutateAsync: deleteColumns } = useDeleteModelColumnsMutation();
  const { updateResourceOrDraft } = useDraft();

  const update = async ({
    model,
    queryState = null,
    columns,
  }: {
    model: Model | null | undefined;
    queryState?: QueryState | null;
    columns: ModelColumnInput[] | undefined;
  }) => {
    const source = model?.connection;
    const type = model?.query_type;

    const deletedColumns = differenceBy(model?.columns, columns ?? [], "name");

    const update: SegmentsSetInput = {
      query_dbt_model_id: queryState?.dbtModel?.id,
      query_looker_look_id: queryState?.lookerLook?.id,
      query_raw_sql: queryState?.sql,
      query_table_name: queryState?.table,
      visual_query_filter: queryState?.visualQueryFilter,
      custom_query: queryState?.customQuery,
      // we null the draft id, it gets added on the backend and we want to be consistent
      // if a workspace turns off approvals again =
      approved_draft_id: null,
    };

    if (logUpdate && user?.id) {
      update.updated_by = String(user?.id);
    }

    const modelColumns = columns ? columns.map((column) => ({ ...column, model_id: model?.id })) : [];

    const updateFunc = async () => {
      if (deletedColumns.length) {
        try {
          await deleteColumns({ modelId: model?.id, names: deletedColumns.map(({ name }) => name) });
        } catch (err) {
          // Throw an error and abort the update - if the column deletion fails, we don't want to continue with the
          // update because it would potentially break relationships that depend on one of the columns that were
          // removed in the update. The user should be prompted to fix this first.
          throw new DeleteColumnsError("failed to delete columns", {
            cause: err,
            deletedColumns: deletedColumns.map((c) => c.name),
          });
        }
      }

      await updateQuery({
        id: model?.id,
        model: update,
        columns: modelColumns,
      });

      analytics.track("Model Updated", {
        model_id: model?.id,
        model_type: type,
        model_name: model?.name || model?.title,
        source_id: source?.id,
        source_type: source?.type,
      });
    };

    if (updateResourceOrDraft) {
      await updateResourceOrDraft(
        {
          _set: update,
          modelColumns,
        },
        () => undefined,
        updateFunc,
        model?.draft || false,
      );
    } else {
      await updateFunc();
    }
  };

  return update;
};

export const aliasColumns = (columns: any, modelColumns: any) =>
  columns?.map((column) => ({
    ...column,
    name: getColumnName(column.name, modelColumns),
  }));

export const aliasRow = (row, columns) => {
  const keys = Object.keys(row);

  // Add preview disabled columns back to the row
  const previewDisabledColumns = columns?.filter(({ disable_preview }) => disable_preview);
  previewDisabledColumns?.forEach(({ name }) => keys.push(name));

  return keys.reduce((rest, key) => {
    const modelColumn = columns?.find(({ name }) => name === key);
    const alias = modelColumn?.alias;
    const previewDisabled = modelColumn?.disable_preview;
    const redactedText = modelColumn?.redacted_text;
    const value = row[key];

    const newKey = alias || key;
    const newValue = previewDisabled ? redactedText ?? "<REDACTED BY HIGHTOUCH>" : value;

    return { [newKey]: newValue, ...rest };
  }, {});
};

export const getColumnName = (queryColumn: string, modelColumns: ModelColumns[]): string => {
  const alias = modelColumns?.find(({ name }) => name === queryColumn)?.alias;
  return alias || queryColumn;
};
