import { FC, useState, useEffect } from "react";

import { useToast } from "@hightouchio/ui";
import Helmet from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { Text } from "theme-ui";

import { Destinations } from "src/components/clone/destinations";
import { Explore } from "src/components/explore/explore";
import { DraftSubmissionModal } from "src/components/modals/draft-submission-modal";
import { useUser } from "src/contexts/user-context";
import { DraftOperation, ResourceToPermission, useCreateModelMutation, useModelQuery } from "src/graphql";
import { Column } from "src/ui/box";
import { Field } from "src/ui/field";
import { Heading } from "src/ui/heading";
import { Input } from "src/ui/input";
import { PageSpinner } from "src/ui/loading";
import { Select } from "src/ui/select";
import { Wizard } from "src/ui/wizard";
import { Step } from "src/ui/wizard/wizard";
import { useDestinations } from "src/utils/destinations";
import { QueryType, useModelRun, useModelState, useQueryState } from "src/utils/models";
import { generateSlug, ResourceType, useResourceSlug } from "src/utils/slug";

export const CloneModel: FC = () => {
  const { model_id: id } = useParams<{ model_id: string }>();
  const { toast } = useToast();
  const navigate = useNavigate();
  const { user, workspace } = useUser();
  const { getSlug } = useResourceSlug(ResourceType.Model);
  const [step, setStep] = useState<number>(0);
  const [selectedSyncs, setSelectedSyncs] = useState<Set<string>>(new Set());
  const { queryState, setSQL, setTable, setDBTModel, initQueryState, setCustomQuery, isQueryDefined } = useQueryState();
  const { modelState, setName, setPrimaryKey, initModelState } = useModelState();
  const [type, setType] = useState<QueryType | undefined>();
  const [hasQueryColumns, setHasQueryColumns] = useState(false);
  const [submitDraftModalOpen, setSubmitDraftModalOpen] = useState<boolean>(false);

  const { data: model, isLoading: modelLoading } = useModelQuery(
    { id: String(id) },
    { enabled: Boolean(id), select: (data) => data.segments_by_pk },
  );

  const source = model?.connection;
  const syncs = model?.syncs;

  const { mutateAsync: createModel, isLoading: creating } = useCreateModelMutation();

  const goToModel = (id: string) => {
    navigate(`/models/${id}`);
  };

  const {
    data: { definitions },
    loading: destinationsLoading,
    error: destinationsError,
  } = useDestinations();

  const create = async () => {
    const slug = await getSlug(modelState?.name);

    const res = await createModel({
      input: {
        slug,
        query_type: type,
        name: modelState?.name,
        primary_key: modelState?.primaryKey,
        connection_id: source?.id,
        created_by: user?.id != null ? String(user?.id) : undefined,
        query_dbt_model_id: queryState?.dbtModel?.id,
        query_looker_look_id: queryState?.lookerLook?.id,
        query_table_name: queryState?.table,
        query_raw_sql: queryState?.sql,
        custom_query: queryState?.customQuery,
        columns: { data: columns.map(({ name, type }) => ({ name, type })) },
        draft: workspace?.approvals_required,
        git_sync_metadata:
          type === "dbt"
            ? {
                git_sync_config_id: queryState?.dbtModel?.git_sync_config?.id,
                file_path: queryState?.dbtModel?.original_file_path,
                dbt_model_id: queryState?.dbtModel?.id,
              }
            : null,
        destination_instances: {
          data: selectedSyncs.size
            ? syncs
                ?.filter(({ id }) => selectedSyncs.has(String(id)))
                ?.map(({ destination, config, schedule, sync_alerts, row_threshold_attempted, row_threshold_total }) => ({
                  destination_id: destination?.id,
                  config,
                  schedule,
                  slug: generateSlug(`${modelState?.name}-to-${destination?.name}`),
                  schedule_paused: true,
                  created_by: user?.id != null ? String(user?.id) : undefined,
                  row_threshold_attempted,
                  row_threshold_total,
                  alert_instances: {
                    data: sync_alerts.map((alert) => ({
                      alert_id: alert.id,
                      fatal_error: alert.fatal_error,
                      row_error: alert.row_error,
                    })),
                  },
                })) ?? []
            : [],
        },
      },
    });

    toast({
      id: "clone-model",
      title: `Cloned ${model!.name} and ${selectedSyncs?.size} syncs`,
      variant: "success",
    });

    return res;
  };

  const {
    runQuery,
    cancelQuery,
    getSchema,
    rows,
    numRowsWithoutLimit,
    isResultTruncated,
    columns,
    loading: queryLoading,
    error: queryError,
    errorAtLine: queryErrorAtLine,
  } = useModelRun(type, undefined, {
    modelId: model?.id != null ? String(model?.id) : undefined,
    variables: { sourceId: source?.id, dbtModelId: queryState?.dbtModel?.id, ...queryState },
  });

  useEffect(() => {
    if (model) {
      initQueryState(model);
      initModelState(model);
      setName("");
      setType(model?.query_type as QueryType);
    }
  }, [model]);

  useEffect(() => {
    if (syncs) {
      setSelectedSyncs(new Set(syncs.map((sync) => String(sync.id))));
    }
  }, [syncs]);

  useEffect(() => {
    setHasQueryColumns(false);
  }, [queryState]);

  useEffect(() => {
    if (columns?.length && !queryError) {
      setHasQueryColumns(true);
    }
  }, [rows, columns]);

  if (modelLoading || destinationsLoading) {
    return <PageSpinner />;
  }

  const steps: Step[] = [
    {
      title: "Change model",
      disabled:
        !isQueryDefined(type) || Boolean(queryError) || (source?.definition?.supportsResultSchema ? false : !hasQueryColumns),
      onContinue: async () => {
        if (source?.definition?.supportsResultSchema && !hasQueryColumns) {
          await getSchema();
        }
        setStep((step) => step + 1);
      },
      render: () => (
        <Explore
          cancelQuery={cancelQuery}
          columns={columns}
          isQueryDefined={isQueryDefined}
          isResultTruncated={Boolean(isResultTruncated)}
          numRowsWithoutLimit={numRowsWithoutLimit}
          rows={rows}
          runQuery={runQuery}
          source={source}
          type={type}
          {...queryState}
          error={queryError || destinationsError?.message}
          errorAtLine={queryErrorAtLine}
          loading={queryLoading || destinationsLoading}
          rowsPerPage={15}
          onCustomQueryChange={setCustomQuery}
          onDBTModelChange={setDBTModel}
          onSQLChange={setSQL}
          onTableChange={setTable}
          onTypeChange={setType}
        />
      ),
    },
  ];

  if (syncs?.length) {
    steps.push({
      title: "Select destinations",
      header: (
        <Column sx={{ gap: 2 }}>
          <Heading>Select destinations to clone associated syncs</Heading>
          <Text sx={{ fontWeight: "semi", color: "base.5" }}>
            New syncs to these destinations will be automatically created using the cloned model. These new syncs will be
            disabled by default.
          </Text>
        </Column>
      ),
      render: () => (
        <Destinations definitions={definitions} selected={selectedSyncs} setSelected={setSelectedSyncs} syncs={syncs} />
      ),
    });
  }

  steps.push({
    title: "Finalize",
    disabled: !modelState?.name || !modelState?.primaryKey,
    submitting: creating,
    header: <Heading>Finalize your settings</Heading>,
    render: () => {
      const columnOptions = columns?.map(({ name }) => ({ value: name, label: name }));
      return (
        <Column sx={{ gap: 8, maxWidth: "600px" }}>
          <Field label="Name">
            <Input placeholder={model?.name} value={modelState?.name} onChange={setName} />
          </Field>
          <Field
            help="A primary key is column that contains unique values to identify each of the rows (e.g, email, id)."
            label="Primary key"
          >
            <Select
              options={columnOptions}
              placeholder="Select a column as the primary key (usually id)"
              value={modelState?.primaryKey}
              onChange={({ value }) => {
                setPrimaryKey(value);
              }}
            />
          </Field>
        </Column>
      );
    },
  });

  return (
    <>
      <Helmet>
        <title>{model?.name ? `Clone "${model.name}" model` : "Clone model"}</title>
      </Helmet>

      <DraftSubmissionModal
        draft={{
          _set: {
            draft: false, // the draft change is to set the column `draft` to false.
          },
        }}
        getResourceId={async () => {
          const model = await create();
          return model?.insert_segments_one?.id;
        }}
        open={submitDraftModalOpen}
        operation={DraftOperation.Create}
        resource={ResourceToPermission.Model}
        onClose={() => setSubmitDraftModalOpen(false)}
        onSubmit={(id) => {
          goToModel(id);
        }}
      />
      <Wizard
        setStep={setStep}
        step={step}
        steps={steps}
        title="Clone model"
        onCancel={() => {
          navigate(`/models/${id}`);
        }}
        onSubmit={async () => {
          if (workspace?.approvals_required) {
            setSubmitDraftModalOpen(true);
            return;
          }

          const model = await create();
          if (model?.insert_segments_one?.id) {
            goToModel(model?.insert_segments_one.id);
          }
        }}
      />
    </>
  );
};
