import { Column, Button, Link, Text, Box } from "@hightouchio/ui";
import Helmet from "react-helmet";

import { AuthLayout } from "src/components/auth/auth-layout";
import { GoogleButton, MicrosoftButton, EmailButton } from "src/components/auth/buttons";
import { Terms } from "src/components/auth/terms";
import { useAuthState } from "src/components/auth/use-auth-state";
import { PartnerConnectHeader } from "src/pages/partner-connect";
import { Card } from "src/ui/card";
import { useNavigate } from "src/utils/navigate";

export const Login = () => {
  const navigate = useNavigate();
  const { returnTo, partnerInformation, googleAuthUrl, microsoftAuthUrl } = useAuthState();

  return (
    <>
      <Helmet>
        <title>Log in</title>
      </Helmet>

      <AuthLayout
        heading={partnerInformation ? `Connect Hightouch to ${partnerInformation.partnerName}` : "Log in to Hightouch"}
      >
        <Card>
          {partnerInformation && <PartnerConnectHeader partnerInformation={partnerInformation} />}

          <Column
            gap={3}
            mb={8}
            sx={{ button: { bg: "gray.100", ":hover": { bg: "gray.200" }, ":active": { bg: "gray.300" } } }}
          >
            <GoogleButton
              action="Log in"
              onClick={() => {
                window.location.href = googleAuthUrl;
              }}
            />
            <MicrosoftButton
              action="Log in"
              onClick={() => {
                window.location.href = microsoftAuthUrl;
              }}
            />
            <EmailButton
              action="Log in"
              onClick={() => {
                navigate(returnTo ? `/login/email?returnTo=${returnTo}` : "/login/email", { slug: false });
              }}
            />
            <Button
              size="lg"
              onClick={() => {
                navigate(returnTo ? `/sso?returnTo=${returnTo}` : "/sso", { slug: false });
              }}
            >
              Log in with SSO
            </Button>
          </Column>
          <Box mb={4} sx={{ span: { color: "gray.700" }, textAlign: "center" }}>
            <Text>
              Don't have an account? <Link href="/signup">Sign up</Link>
            </Text>
          </Box>

          <Terms variant="light" />
        </Card>
      </AuthLayout>
    </>
  );
};

export default Login;
