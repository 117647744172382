import { useEffect, useState, FC } from "react";

import { InformationCircleIcon } from "@heroicons/react/24/outline";
import {
  Box,
  Text,
  Heading,
  Alert,
  Paragraph,
  Link,
  Button,
  ButtonGroup,
  Tooltip,
  Stats,
  StatsItem,
  StatsItemTitle,
  StatsItemValue,
  useToast,
} from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { groupBy } from "lodash";
import pluralize from "pluralize";
import { useQueryClient } from "react-query";

import { PlanWelcomeModal } from "src/components/modals/plan-welcome-modal";
import { PricingModal } from "src/components/modals/pricing-modal";
import { PricingPlan, pricingPlans } from "src/components/pricing/pricing-display";
import { Settings } from "src/components/settings";
import { DestinationTiles } from "src/components/settings/destination-tiles";
import { FeatureCatalog } from "src/components/settings/feature-catalog";
import { OrganizationMqrTable } from "src/components/settings/organization-mqr-table";
import { WorkspaceMetrics, WorkspaceMetricsTable } from "src/components/settings/workspace-metrics-table";
import { useUser } from "src/contexts/user-context";
import { useDestinationDefinitionsQuery, useOrganizationMetricsQuery, usePortalUrlQuery } from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import * as analytics from "src/lib/analytics";
import { WarningIcon } from "src/ui/icons";
import { PageSpinner } from "src/ui/loading";
import { Modal } from "src/ui/modal";
import { commaNumber } from "src/utils/numbers";
import { useQueryString } from "src/utils/use-query-string";

export const Billing: FC = () => {
  const { workspace } = useUser();
  const client = useQueryClient();
  const { toast } = useToast();
  const { data: entitlementsData, isLoading: loadingEntitlements } = useEntitlements(true);
  const { data: organizationMetrics, isLoading: loadingOrgUsage } = useOrganizationMetricsQuery();
  const { data: destinationDefinitions, isLoading: definitionsLoading } = useDestinationDefinitionsQuery();
  const [showWorkspaces, setShowWorkspaces] = useState(false);
  const [showDestinations, setShowDestinations] = useState(false);
  const [loadingStripe, setLoadingStripe] = useState(false);
  const {
    data: { success, canceled },
  } = useQueryString();
  const [successfulCheckoutPlan, setSuccessfulCheckoutPlan] = useState<PricingPlan | undefined>();
  const [isPricingModalOpen, setIsPricingModalOpen] = useState(false);
  useEffect(() => {
    if (success) {
      const plan = pricingPlans.find((pricingPlans) => pricingPlans.sku === success);
      if (plan) {
        analytics.track("Checkout Successful", {
          workspace_id: workspace?.id,
          sku: plan.sku,
        });
        setSuccessfulCheckoutPlan(plan);
      }
    }
  }, [success]);

  const { mqrTable: mqrTableEnabled } = useFlags();

  useEffect(() => {
    if (canceled) {
      analytics.track("Checkout canceled", {
        workspace_id: workspace?.id,
      });
    }
  }, [canceled]);

  useEffect(() => {
    analytics.track("Billing Screen Viewed", {
      workspace_id: workspace?.id,
    });
  }, []);

  const organization = entitlementsData?.organization;
  const entitlements = entitlementsData?.entitlements;
  const overage = entitlementsData?.overage;
  const destinationsCounts = entitlementsData?.destinationsCounts;

  const usageMetrics: WorkspaceMetrics[] = organizationMetrics?.getOrganizationMetrics?.metrics || [];
  const rolesByWorkspaceId = groupBy(organizationMetrics?.getOrganizationMetrics?.roles, "workspace_id");

  usageMetrics.forEach((workspace) => {
    workspace.roles = rolesByWorkspaceId[workspace.id];
  });

  const openPricingModal = (source: string) => () => {
    setIsPricingModalOpen(true);
    analytics.track("Upgrade Plan Clicked", {
      workspace_id: workspace?.id,
      source,
    });
  };

  const goToPortal = async () => {
    if (!workspace) {
      return;
    }

    if (organization?.billing?.customer_id) {
      setLoadingStripe(true);
      analytics.track("Manage Plan Clicked", {
        workspace_id: workspace.id,
      });
      try {
        const { getPortalUrl } = await client.fetchQuery(
          usePortalUrlQuery.getKey({ customer_id: workspace.organization?.billing?.customer_id }),
          usePortalUrlQuery.fetcher({ customer_id: workspace.organization?.billing?.customer_id }),
        );
        if (getPortalUrl.redirect) {
          location.href = getPortalUrl.redirect;
        } else {
          toast({
            id: "go-to-portal",
            title: "Couldn't generate portal URL",
            variant: "error",
          });

          setLoadingStripe(false);
        }
      } catch (e) {
        toast({
          id: "go-to-portal",
          title: "Couldn't generate portal URL",
          message: e.message,
          variant: "error",
        });

        setLoadingStripe(false);
      }
    }
  };

  if (loadingEntitlements) {
    return <PageSpinner />;
  }

  const hasOverages = overage.overage && (overage.destinationOverage || overage.premiumOverage);
  const planLimit = entitlements?.destinations || 1;

  return (
    <Settings route="billing" title="Billing">
      {overage.overage && (
        <Box display="flex" flexDirection="column" gap={4}>
          {overage.destinationOverage && (
            <Alert
              actionText="Upgrade plan"
              message="As a temporary courtesy, your syncs are still running while this workspace is in overage. Please remove destinations or upgrade your plan."
              title="You've exceeded the number of destination types allowed on your current plan."
              variant="warning"
              onAction={openPricingModal("destinations_banner")}
            />
          )}

          {overage.premiumOverage && (
            <Alert
              actionText="Upgrade plan"
              message="You've used a premium destination type and need to upgrade your plan."
              title="Premium destination used"
              variant="warning"
              onAction={openPricingModal("premium_banner")}
            />
          )}
        </Box>
      )}

      <Box mt={hasOverages ? 8 : 0}>
        <Heading>Overview {organization ? ` for ${organization.name}` : ""}</Heading>

        <Stats mt={6}>
          <StatsItem>
            <StatsItemTitle>Plan</StatsItemTitle>
            <StatsItemValue>{organization?.plan?.name || "Free"}</StatsItemValue>

            {organization?.plan?.sku !== "business_tier" &&
              (organization?.billing?.customer_id ? (
                <ButtonGroup mt={3}>
                  <Button
                    isLoading={loadingStripe}
                    size="sm"
                    onClick={() => {
                      goToPortal();
                    }}
                  >
                    Manage billing
                  </Button>

                  <Button size="sm" onClick={openPricingModal("existing_plan")}>
                    Upgrade plan
                  </Button>
                </ButtonGroup>
              ) : (
                <Button mt={3} size="sm" onClick={openPricingModal("kpi_bar")}>
                  Upgrade plan
                </Button>
              ))}
          </StatsItem>

          <StatsItem>
            <StatsItemTitle>Destination usage</StatsItemTitle>

            <StatsItemValue>
              {destinationsCounts.billableDestinations} of{" "}
              {Number(entitlements?.destinations) === -1 ? <span>&infin;</span> : planLimit}
            </StatsItemValue>

            <Button mt={3} size="sm" onClick={() => setShowDestinations(true)}>
              View all destination types
            </Button>
          </StatsItem>

          <StatsItem>
            <StatsItemTitle>Workspaces</StatsItemTitle>
            <StatsItemValue>{usageMetrics.length}</StatsItemValue>

            <Button mt={3} size="sm" onClick={() => setShowWorkspaces(true)}>
              View all workspaces
            </Button>
          </StatsItem>

          <StatsItem>
            <StatsItemTitle>Cost</StatsItemTitle>

            <StatsItemValue>
              {organization?.plan?.sku === "business_tier"
                ? "Contract"
                : `$${
                    organization?.billing?.subscription_cost
                      ? `${commaNumber(organization?.billing?.subscription_cost)} / ${
                          workspace!.organization?.billing?.subscription_interval
                        }`
                      : "0"
                  }`}
            </StatsItemValue>
          </StatsItem>
        </Stats>
      </Box>

      <Modal
        bodySx={{ bg: "white", p: 0 }}
        footer={<Button onClick={() => setShowWorkspaces(false)}>Close</Button>}
        isOpen={showWorkspaces}
        sx={{ width: "1080px" }}
        title="Workspaces"
        onClose={() => setShowWorkspaces(false)}
      >
        <WorkspaceMetricsTable isLoading={loadingOrgUsage} metrics={usageMetrics} />
      </Modal>

      <Modal
        bodySx={{ bg: "white" }}
        footer={<Button onClick={() => setShowDestinations(false)}>Close</Button>}
        isOpen={showDestinations}
        sx={{ width: "1080px" }}
        title="Destinations"
        onClose={() => setShowDestinations(false)}
      >
        <Box display="flex" flexDirection="column" gap={6}>
          <Box border="1px" borderColor="gray.300" borderRadius="md" display="flex" gap={14} p={4}>
            <Box alignItems="center" display="flex" gap={1}>
              <Text fontWeight="semibold">Plan limit:</Text>

              <Text>
                {Number(entitlements?.destinations) === -1 ? <span>&infin;</span> : planLimit}{" "}
                {pluralize("destination type", planLimit)}
              </Text>
            </Box>

            <Box alignItems="center" display="flex" gap={1}>
              <Text fontWeight="semibold">Current billable usage:</Text>

              <Box alignItems="center" display="inline-flex" gap={2}>
                <Text>
                  {destinationsCounts.billableDestinations}{" "}
                  {pluralize("destination type", destinationsCounts.billableDestinations)}
                </Text>

                {overage.overage && (
                  <Tooltip
                    message={`You are using ${destinationsCounts.billableDestinations} billable ${pluralize(
                      "destination type",
                      destinationsCounts.billableDestinations,
                    )}${
                      destinationsCounts.premiumDestinations > 0
                        ? `, including ${destinationsCounts.premiumDestinations} premium ${pluralize(
                            "destination type",
                            destinationsCounts.premiumDestinations,
                          )},`
                        : ""
                    } of the ${planLimit} included in your plan.`}
                  >
                    <WarningIcon color="red" />
                  </Tooltip>
                )}
              </Box>
            </Box>
          </Box>

          <Box border="1px" borderColor="gray.300" borderRadius="md" p={4}>
            <Stats>
              <StatsItem>
                <StatsItemTitle>Billable Destination Types</StatsItemTitle>
                <StatsItemValue>{destinationsCounts.billableDestinations}</StatsItemValue>
              </StatsItem>

              <StatsItem>
                <StatsItemTitle>Free Destination Types</StatsItemTitle>
                <StatsItemValue>{destinationsCounts.freeDestinations}</StatsItemValue>
              </StatsItem>

              <StatsItem>
                <StatsItemTitle>Total Count</StatsItemTitle>
                <StatsItemValue>{destinationsCounts.totalDestinations}</StatsItemValue>
              </StatsItem>
            </Stats>
          </Box>

          <DestinationTiles
            definitions={destinationDefinitions}
            destinationBillingTypes={entitlementsData?.destinationBillingTypes}
            isLoadingDefinitions={definitionsLoading}
            isLoadingDestinationBillingTypes={loadingEntitlements}
          />
        </Box>
      </Modal>

      {mqrTableEnabled && organization && (
        <Box mt={20}>
          <Box alignItems="center" display="flex" gap={2}>
            <Heading>Monthly queried rows</Heading>
            <Tooltip message="Monthly queried rows (or MQRs) are the number of deduped records across all models used by an active sync in the time period.">
              <Box as={InformationCircleIcon} boxSize={5} color="gray.700" />
            </Tooltip>
          </Box>
          <Paragraph>
            Learn more in our <Link href={`${import.meta.env.VITE_WEBSITE_URL}/docs/pricing/bt-pricing/`}>docs</Link>.
          </Paragraph>

          <Box mt={6}>
            <OrganizationMqrTable organizationWorkspaces={organization.workspaces} />
          </Box>
        </Box>
      )}

      <Box mt={20}>
        <Heading>Plan features</Heading>

        <Box mt={6}>
          <FeatureCatalog />
        </Box>
      </Box>

      {successfulCheckoutPlan && (
        <PlanWelcomeModal
          checkList={successfulCheckoutPlan.checkList}
          isOpen={Boolean(successfulCheckoutPlan)}
          plan={successfulCheckoutPlan.plan}
          onClose={() => {
            setSuccessfulCheckoutPlan(undefined);
          }}
        />
      )}

      {isPricingModalOpen && (
        <PricingModal
          isOpen={isPricingModalOpen}
          organization={organization}
          onClose={() => {
            analytics.track("Pricing Modal Dismissed", {
              workspace_id: workspace?.id,
            });
            setIsPricingModalOpen(false);
          }}
        />
      )}
    </Settings>
  );
};
