import { useEffect, useMemo, useState, FC } from "react";

import { TrashIcon } from "@heroicons/react/24/outline";
import {
  EditableHeading,
  Box as HightouchUiBox,
  useToast,
  Switch,
  Menu,
  MenuList,
  MenuItem,
  MenuActionsButton,
} from "@hightouchio/ui";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Text, Image } from "theme-ui";

import { Page } from "src/components/layout";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import { SidebarForm } from "src/components/page";
import { Permission } from "src/components/permission";
import { Schedule, ScheduleManager } from "src/components/schedule";
import { FormState, SequenceForm } from "src/components/sequences/sequence-form";
import { SequenceStatusBadge } from "src/components/sequences/sequence-status-badge";
import { DisplaySlug } from "src/components/slug/display-slug";
import { Warning } from "src/components/warning";
import { PermissionProvider } from "src/contexts/permission-context";
import {
  ResourcePermissionGrant,
  useSequenceQuery,
  useUpdateSequenceMutation,
  useDeleteSequenceMutation,
  useSequenceRunsQuery,
  SyncSequenceRunsOrderBy,
  useCreateSequenceMembersMutation,
  useDeleteSequenceMembersMutation,
  SyncSequenceMembersInsertInput,
  useUpdateSequenceRunMutation,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import useHasPermission from "src/hooks/use-has-permission";
import * as analytics from "src/lib/analytics";
import { Box, Column, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Circle } from "src/ui/circle";
import { ExternalLinkIcon, PlayIcon } from "src/ui/icons";
import { Link } from "src/ui/link";
import { PageSpinner, Spinner } from "src/ui/loading";
import { Message } from "src/ui/message";
import { Modal } from "src/ui/modal";
import { Pagination, Table, TableColumn, useTableConfig } from "src/ui/table";
import { Tabs } from "src/ui/tabs";
import { isScheduleComplete } from "src/utils/schedule";
import { SyncStatus, SyncStatusBadge, syncStatusIsTerminal } from "src/utils/syncs";
import { formatDatetime } from "src/utils/time";

enum Tab {
  RUNS = "Runs",
  CONFIGURATION = "Configuration",
  SCHEDULE = "Schedule",
}

export const Sequence: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { toast } = useToast();
  const [cancelling, setCancelling] = useState<boolean>(false);
  const [tab, setTab] = useState<Tab>(Tab.RUNS);
  const [updatingConfiguration, setUpdatingConfiguration] = useState<boolean>(false);
  const [updatingSchedule, setUpdatingSchedule] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<boolean>(true);
  const [showRun, setShowRun] = useState(true);

  const { data: sequence, isLoading: loadingSequence } = useSequenceQuery(
    {
      id: id ?? "",
    },
    {
      enabled: Boolean(id),
      refetchInterval: 5000,
      select: (data) => data.sync_sequences_by_pk,
    },
  );

  const lastRun = sequence?.runs[0];
  const status = lastRun?.status;
  const running = status === "running";

  const { mutateAsync: createMembers } = useCreateSequenceMembersMutation();
  const { mutateAsync: deleteMembers } = useDeleteSequenceMembersMutation({
    onSuccess: () => {
      // prevents sequence query from invalidating
    },
  });
  const { mutateAsync: updateSequence } = useUpdateSequenceMutation();
  const { mutateAsync: deleteSequence } = useDeleteSequenceMutation();
  const { mutateAsync: updateSequenceRun } = useUpdateSequenceRunMutation();

  const formMethods = useForm<FormState>();
  const { handleSubmit, watch, reset } = formMethods;
  const schedule = watch("schedule");
  const members = watch("members");

  const cancelRun = async () => {
    if (!lastRun) return;

    setCancelling(true);
    await updateSequenceRun({
      id: lastRun.id,
      object: {
        trigger_cancel: true,
      },
    });
  };

  const { data: entitlementsData, isLoading: _loadingEntitlements } = useEntitlements(true);
  const overageLockout: boolean = entitlementsData.overage?.overageLockout;
  const overageText = entitlementsData.overage?.destinationOverageText; // @TODO: hookup once there are more than one overage.

  const toggleEnabled = async (enabled: boolean) => {
    if (!id) return;

    try {
      await updateSequence({
        id,
        object: {
          schedule_paused: !enabled,
        },
      });

      toast({
        id: "toggle-sequence",
        title: `Sequence was ${enabled ? "enabled" : "disabled"}`,
        variant: "success",
      });
    } catch (error) {
      toast({
        id: "toggle-sequence",
        title: `Couldn't ${enabled ? "enable" : "disable"} this sequence`,
        message: error.message,
        variant: "error",
      });
    }
  };

  const startRun = async () => {
    if (!id) return;

    await updateSequence({ id, object: { force_run: true } });

    analytics.track("Sequence Manually Started", {
      sync_sequence_id: id,
      schedule_type: schedule?.type,
    });

    toast({
      id: "start-sequence-run",
      title: "Manual run will begin shortly",
      variant: "success",
    });
  };

  const TABS = [Tab.RUNS, Tab.CONFIGURATION, Tab.SCHEDULE];

  const onUpdate = () => {
    analytics.track("Sequence Edited", {
      sync_sequence_id: id,
    });

    toast({
      id: "update-sequence",
      title: "Sequence was updated",
      variant: "success",
    });
  };

  const updateSchedule = async ({ schedule }) => {
    if (!id) return;

    setUpdatingSchedule(true);
    await updateSequence({
      id,
      object: {
        schedule: schedule?.type === "manual" ? null : schedule,
      },
    });
    setUpdatingSchedule(false);
    onUpdate();
  };

  const [name, setName] = useState(sequence?.name ?? "");

  useEffect(() => {
    setName(sequence?.name ?? "");
  }, [sequence?.name]);

  const updatePermission = useHasPermission([{ resource: "sync", grants: [ResourcePermissionGrant.Update] }]);

  const saveName = async () => {
    if (!id) return;
    await updateSequence({
      id,
      object: {
        name,
      },
    });
    onUpdate();
  };

  const updateConfiguration = async (data) => {
    if (!id) return;

    setUpdatingConfiguration(true);

    const members = data.members.map(({ sync, abort_seq_on_failure, abort_seq_on_rejects }, index) => {
      const member: SyncSequenceMembersInsertInput = {
        sequence_id: id,
        sync_id: sync.id,
        reference_id: index + 1,
        abort_seq_on_failure,
        abort_seq_on_rejects,
        links: {
          predecessors: index > 0 ? [index] : [],
          successors: index !== data.members.length - 1 ? [index + 2] : [],
        },
      };

      return member;
    });

    await deleteMembers({ id });
    await createMembers({
      objects: members,
    });
    setUpdatingConfiguration(false);
    onUpdate();
  };

  const { hasPermission: userCanDelete } = useHasPermission([{ resource: "sync", grants: [ResourcePermissionGrant.Delete] }]);
  const { hasPermission: userCanEnable } = useHasPermission([{ resource: "sync", grants: [ResourcePermissionGrant.Enable] }]);

  const loading = loadingSequence;

  useEffect(() => {
    // Destination Maybe<> type causing issues here
    reset({
      name: sequence?.name,
      schedule: sequence?.schedule ? sequence?.schedule : { type: "manual" },
      members: sequence?.members as any,
    });
    setEnabled(!sequence?.schedule_paused);
  }, [sequence]);

  useEffect(() => {
    if (syncStatusIsTerminal(status as SyncStatus)) {
      setShowRun(true);
    }
    if (status === "cancelled") {
      setCancelling(false);
    }
  }, [status]);

  if (!loading && !sequence) {
    return <Warning subtitle="It may have been deleted" title="Sequence not found" />;
  }

  if (loading || !sequence || !id) {
    return <PageSpinner />;
  }

  return (
    <>
      <PermissionProvider permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Update] }]}>
        <FormProvider {...formMethods}>
          <Page
            crumbs={[{ label: "Sequences", link: "/sequences" }, { label: sequence.name }]}
            title={`${sequence.name} - Sequences`}
          >
            <Row sx={{ justifyContent: "space-between", mb: 5, width: "100%", borderBottom: "small", pb: 2 }}>
              <EditableHeading
                isDisabled={!updatePermission.hasPermission}
                size="lg"
                value={name}
                onChange={setName}
                onSubmit={saveName}
              />

              <Row gap={4}>
                <HightouchUiBox alignItems="center" display="flex" gap={2}>
                  <Text
                    sx={{
                      textTransform: "uppercase",
                      fontSize: "10px",
                      color: "base.4",
                      fontWeight: "bold",
                    }}
                  >
                    {enabled ? "Enabled" : "Disabled"}
                  </Text>

                  <Switch
                    isChecked={enabled}
                    isDisabled={!userCanEnable}
                    onChange={(value) => {
                      setEnabled(value);
                      toggleEnabled(value);
                    }}
                  />
                </HightouchUiBox>

                {userCanDelete && (
                  <Menu>
                    <MenuActionsButton />

                    <MenuList>
                      <MenuItem
                        icon={TrashIcon}
                        isDisabled={running}
                        variant="danger"
                        onClick={() => {
                          setDeleting(true);
                        }}
                      >
                        Delete
                      </MenuItem>
                    </MenuList>
                  </Menu>
                )}

                <Permission permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Start] }]}>
                  {running || cancelling ? (
                    <Button
                      disabled={cancelling}
                      iconBefore={<Spinner color={cancelling ? "primary" : "red"} size={14} />}
                      variant={cancelling ? "secondary" : "redOutline"}
                      onClick={cancelRun}
                    >
                      {cancelling ? "Canceling..." : "Cancel run"}
                    </Button>
                  ) : (
                    <Button
                      disabled={!showRun || overageLockout}
                      iconBefore={<PlayIcon size={14} />}
                      tooltip={overageLockout ? overageText : undefined}
                      variant="secondary"
                      onClick={() => {
                        setShowRun(false);
                        startRun();
                      }}
                    >
                      Run
                    </Button>
                  )}
                </Permission>
              </Row>
            </Row>

            <Row gap={8} sx={{ alignSelf: "flex-start", alignItems: "flex-start", mb: 6 }}>
              <Column sx={{ pr: 10 }}>
                <Text sx={{ fontSize: 0, textTransform: "uppercase", color: "base.4", fontWeight: "bold", mb: 1 }}>
                  Schedule
                </Text>
                <Schedule schedule={sequence.schedule} />
              </Column>
              <Column sx={{ pl: 3, pr: 10, borderLeft: "small" }}>
                <Text sx={{ fontSize: 0, textTransform: "uppercase", color: "base.4", fontWeight: "bold", mb: 1 }}>Slug</Text>
                <DisplaySlug currentSlug={sequence.slug} />
              </Column>
            </Row>

            <Grid gap={8} sx={{ width: "100%" }}>
              <Tabs setTab={(tab) => setTab(tab as Tab)} tab={tab} tabs={TABS} />

              {tab === Tab.RUNS && <SequenceRuns id={id} />}

              {tab === Tab.CONFIGURATION && (
                <Row sx={{ width: "100%", justifyContent: "space-between" }}>
                  <Column sx={{ width: "100%" }}>
                    <SequenceForm />
                  </Column>
                  <SidebarForm
                    hideInviteTeammate
                    hideSendMessage
                    buttons={
                      <Button
                        disabled={!(members?.length > 1)}
                        loading={updatingConfiguration}
                        sx={{ width: "100%" }}
                        onClick={handleSubmit(updateConfiguration)}
                      >
                        Save
                      </Button>
                    }
                    docsUrl={`${import.meta.env.VITE_DOCS_URL}/syncs/schedule-sync-ui/`}
                    name="scheduling syncs"
                  />
                </Row>
              )}

              {tab === Tab.SCHEDULE && (
                <Row sx={{ width: "100%", justifyContent: "space-between" }}>
                  <Column sx={{ width: "100%" }}>
                    <Controller
                      name="schedule"
                      render={({ field }) => (
                        <ScheduleManager resource="sequence" schedule={field.value} setSchedule={field.onChange} />
                      )}
                    />

                    <Message sx={{ mt: 12, maxWidth: "100%" }}>
                      <Text>
                        You can also trigger this sync via Airflow. The ID for this sync is: <strong>{id}</strong>.
                      </Text>
                      <Text sx={{ mt: 3 }}>
                        For more information view our{" "}
                        <Link newTab to={`${import.meta.env.VITE_DOCS_URL}/integrations/airflow`}>
                          Airflow Operator docs
                        </Link>
                        . If you need an API key you can create one <Link to="/settings/api-keys">here.</Link>
                      </Text>
                    </Message>
                  </Column>
                  <SidebarForm
                    hideInviteTeammate
                    hideSendMessage
                    buttons={
                      <Button
                        disabled={!isScheduleComplete(schedule)}
                        loading={updatingSchedule}
                        sx={{ width: "100%" }}
                        onClick={handleSubmit(updateSchedule)}
                      >
                        Save
                      </Button>
                    }
                    docsUrl={`${import.meta.env.VITE_DOCS_URL}/syncs/schedule-sync-ui/`}
                    invite="If you need help setting up this sequence"
                    name="scheduling syncs"
                  />
                </Row>
              )}
            </Grid>
          </Page>
        </FormProvider>
      </PermissionProvider>

      <DeleteConfirmationModal
        isOpen={deleting}
        label="sequence"
        onClose={() => {
          setDeleting(false);
        }}
        onDelete={async () => {
          if (!id) {
            return;
          }

          await deleteSequence({
            id,
          });

          analytics.track("Sequence Deleted", {
            sync_sequence_id: id,
          });

          navigate("/sequences");
        }}
      />
    </>
  );
};

const SequenceRuns: FC<Readonly<{ id: string }>> = ({ id }) => {
  const [selectedRun, setSelectedRun] = useState<any>();

  const PAGE_SIZE = 10;

  const { limit, offset, page, setPage } = useTableConfig<SyncSequenceRunsOrderBy>({
    limit: PAGE_SIZE,
  });

  const { data, isFetching: loading } = useSequenceRunsQuery(
    { id, limit: limit + 1, offset },
    {
      select: (data) => data,
      refetchInterval: 5000,
      notifyOnChangeProps: "tracked",
      keepPreviousData: true,
    },
  );
  const count = data?.sync_sequence_runs_aggregate?.aggregate?.count ?? 0;
  const runs = useMemo(() => {
    if (data?.sync_sequence_runs?.length === PAGE_SIZE + 1) {
      return data.sync_sequence_runs?.slice(0, PAGE_SIZE);
    }
    return data?.sync_sequence_runs;
  }, [data?.sync_sequence_runs]);

  const columns: TableColumn[] = useMemo(
    () => [
      {
        name: "Status",
        cell: ({ status }) => <SequenceStatusBadge status={status} />,
      },
      {
        name: "Started",
        key: "created_at",
        cell: (timestamp) => formatDatetime(timestamp),
      },
      {
        name: "Sync runs",
        key: "requests",
        cell: (requests) => {
          if (requests?.length) {
            const completed = requests.reduce((prev, curr) => prev + (curr.status === "done" ? 1 : 0), 0);
            const failed = requests.reduce((prev, curr) => prev + (curr.status === "failed" ? 1 : 0), 0);
            const pending = requests.reduce((prev, curr) => prev + (curr.status === "pending" ? 1 : 0), 0);
            const running = requests.reduce((prev, curr) => prev + (curr.status === "running" ? 1 : 0), 0);
            const cancelled = requests.reduce((prev, curr) => prev + (curr.status === "cancelled" ? 1 : 0), 0);
            const entries: string[] = [];
            if (completed) entries.push(`${completed} completed`);
            if (failed) entries.push(`${failed} failed`);
            if (running) entries.push(`${running} running`);
            if (pending) entries.push(`${pending} pending`);
            if (cancelled) entries.push(`${cancelled} cancelled`);
            return entries.join(", ");
          }
          return "Pending...";
        },
      },
    ],
    [],
  );

  return (
    <>
      <Column>
        <Table
          columns={columns}
          data={runs}
          loading={loading}
          placeholder={{
            title: "No runs",
            body: "Set a schedule or manually run the sequence.",
            error: "Runs failed to load, please try again.",
          }}
          onRowClick={setSelectedRun}
        />
        <Pagination count={count} label="runs" page={page} rowsPerPage={limit} setPage={setPage} />
      </Column>

      <Modal
        footer={
          <Button variant="secondary" onClick={() => setSelectedRun(undefined)}>
            Close
          </Button>
        }
        isOpen={Boolean(selectedRun)}
        title="Sequence run"
        onClose={() => setSelectedRun(undefined)}
      >
        {selectedRun?.requests?.map(({ sync: { id: syncId, segment, destination }, sync_request, status }, index) => {
          return (
            <>
              {index !== 0 && <Box sx={{ ml: 7, width: "2px", height: "32px", bg: "base.2" }} />}
              <Grid
                key={id}
                gap={6}
                sx={{
                  p: 4,
                  bg: "white",
                  borderRadius: 3,
                  alignItems: "center",
                  gridTemplateColumns: "max-content 100px 1fr 1fr 1fr 80px",
                }}
              >
                <Circle color="base.2" radius="26px" sx={{ mr: 8, color: "base.5" }}>
                  {index + 1}
                </Circle>
                {sync_request ? <SyncStatusBadge request={sync_request} /> : <SequenceStatusBadge status={status} />}
                <Text>{segment.name}</Text>
                <Row sx={{ alignItmes: "center" }}>
                  <Image
                    alt={destination?.definition?.name}
                    src={destination?.definition?.icon}
                    sx={{ width: "20px", maxHeight: "100%", objectFit: "contain", flexShrink: 0, mr: 2 }}
                  />
                  <Text>{destination?.name ?? "unknown"}</Text>
                </Row>
                {sync_request ? (
                  <>
                    <Text>{formatDatetime(sync_request.finished_at)}</Text>
                    <Link sx={{ ml: 8 }} to={`/syncs/${syncId}/runs/${sync_request.id}`}>
                      <ExternalLinkIcon color="base.4" />
                    </Link>
                  </>
                ) : (
                  <>
                    <Box />
                    <Box />
                  </>
                )}
              </Grid>
            </>
          );
        })}
      </Modal>
    </>
  );
};
