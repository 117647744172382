import { FC, useState } from "react";

import { Checkbox, CheckboxGroup, useToast } from "@hightouchio/ui";
import { isEqual } from "lodash";
import { Grid, Text } from "theme-ui";

import { SidebarForm } from "src/components/page";
import { usePermission } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import { Row } from "src/ui/box";
import { Heading } from "src/ui/heading";
import { Link } from "src/ui/link";
import { Message } from "src/ui/message";

import { useUpdateSyncMutation } from "../../graphql";
import { Button } from "../../ui/button";
import { Field } from "../../ui/field";
import { FeatureFull } from "../feature-gates";
import { Permission } from "../permission";

type Source = {
  id: string;
  plan_in_warehouse: boolean;
  plan_in_warehouse_config: Record<string, any>;
  warehouse_history_config: Record<string, any>;
};

type WarehouseHistoryConfig = {
  tablesToWrite: {
    changelog: boolean;
    snapshot: boolean;
    syncRuns: boolean;
  };
};

type WarehouseSyncLogsConfig = {
  config: WarehouseHistoryConfig;
  id: string | undefined;
  source: Source | undefined;
  model?: {
    visual_query_parent_id?: string;
  };
};

export const WarehouseSyncLogs: FC<Readonly<WarehouseSyncLogsConfig>> = ({ id, config, source, model }) => {
  const { toast } = useToast();
  const { user, workspace } = useUser();

  const [warehouseSyncLogsConfig, setWarehouseSyncLogsConfig] = useState<WarehouseHistoryConfig>(config);
  const sourceSyncLogConfig = getSourceSyncHistoryConfig(source, Boolean(model?.visual_query_parent_id));

  const [updatingWarehouseSyncLogsConfig, setUpdatingWarehouseSyncLogsConfig] = useState<boolean>(false);

  const { mutateAsync: updateSync } = useUpdateSyncMutation();

  const updateWarehouseSyncLogsConfig = async () => {
    if (!id) {
      return;
    }

    setUpdatingWarehouseSyncLogsConfig(true);
    await updateSync({
      id,
      object: {
        updated_by: user?.id != null ? String(user?.id) : undefined,
        warehouse_history_config: warehouseSyncLogsConfig,
      },
    });
    setUpdatingWarehouseSyncLogsConfig(false);

    toast({
      id: "update-sync-config",
      title: "Sync configuration was updated",
      variant: "success",
    });
  };

  const dirty = !isEqual(warehouseSyncLogsConfig?.tablesToWrite, config?.tablesToWrite);

  const isOverriding = (table: "snapshot" | "changelog" | "syncRuns") =>
    warehouseSyncLogsConfig?.tablesToWrite?.[table] !== undefined &&
    sourceSyncLogConfig &&
    warehouseSyncLogsConfig?.tablesToWrite?.[table] !== sourceSyncLogConfig.tablesToWrite?.[table];

  const updatePermission = usePermission();

  return (
    <FeatureFull
      enabled={workspace?.organization?.plan?.sku === "business_tier"}
      featureDetails={{
        pitch: "Detailed log of sync results directly available in your warehouse",
        description:
          "With warehouse sync logs, Hightouch will write information on sync results back into your data warehouse provider. You can use your own BI tool or SQL editor to run custom analysis or monitor the health of your pipelines.",
        bullets: [
          "Run any SQL query to analyze the results of your syncs",
          "Monitor for common sync errors, create custom alerts, and integrate into your existing dashboards",
          "Visualize the history of specific rows and track sync status",
          "Recommended for businesses that want to bring their own observability tooling",
        ],
        video: { src: "https://cdn.sanity.io/files/pwmfmi47/production/c81b1d3e32df55f57ca5cdec94543144baaebad4.mp4" },
      }}
      featureName="warehouse sync logs"
    >
      <Row sx={{ alignItems: "flex-start" }}>
        <Grid gap={6} mr={8}>
          <Heading>Warehouse sync logs</Heading>
          <Text>
            <Link to={`${import.meta.env.VITE_DOCS_URL}/syncs/warehouse-sync-logs`}>Warehouse sync logs</Link> writes
            information on the status of each row of your sync back into your warehouse. This is useful for analyzing sync
            failures and changes in your data over time.
            <br />
            <br />
            Specific use cases include{" "}
            <Link to={`${import.meta.env.VITE_DOCS_URL}/syncs/warehouse-sync-logs/#get-the-most-common-sync-error`}>
              analyzing common syncs errors
            </Link>
            ,{" "}
            <Link
              to={`${import.meta.env.VITE_DOCS_URL}/syncs/warehouse-sync-logs/#track-when-users-entered-and-exited-a-model`}
            >
              visualizing when users enter and exit audiences
            </Link>
            , and{" "}
            <Link to={`${import.meta.env.VITE_DOCS_URL}/syncs/warehouse-sync-logs/#get-the-current-rows-in-all-models`}>
              tracking the status of rows across syncs
            </Link>
            .
          </Text>
          {!source?.plan_in_warehouse && (
            <Message sx={{ maxWidth: "100%" }} variant="warning">
              This feature can only be enabled if the source is using Warehouse Planning. You can enable Warehouse Planning for
              this source <Link to={`/sources/${source?.id}`}>here</Link>.
            </Message>
          )}
          {source?.plan_in_warehouse && (
            <Field
              description="Choose what you would like to track in the warehouse. The data will be written after each sync run completes."
              label="Tables"
              size="large"
            >
              <CheckboxGroup>
                <Checkbox
                  description="Tracks the latest status of each row in the sync. This is useful for understanding the overall health of your syncs."
                  isChecked={
                    warehouseSyncLogsConfig?.tablesToWrite?.snapshot ?? sourceSyncLogConfig?.tablesToWrite?.snapshot ?? false
                  }
                  isDisabled={updatePermission.unauthorized}
                  label={`Snapshot${isOverriding("snapshot") ? " (overriding default)" : ""}`}
                  onChange={(event) => {
                    setWarehouseSyncLogsConfig({
                      tablesToWrite: {
                        ...(warehouseSyncLogsConfig?.tablesToWrite || {}),
                        snapshot: event.target.checked,
                      },
                    });
                  }}
                />

                <Checkbox
                  description="Tracks every operation performed by Hightouch. Includes the result of the operation, as well as any error messages from syncing."
                  isChecked={
                    warehouseSyncLogsConfig?.tablesToWrite?.changelog ?? sourceSyncLogConfig?.tablesToWrite?.changelog ?? false
                  }
                  isDisabled={updatePermission.unauthorized}
                  label={`Changelog${isOverriding("changelog") ? " (overriding default)" : ""}`}
                  onChange={(event) => {
                    setWarehouseSyncLogsConfig({
                      tablesToWrite: {
                        snapshot: warehouseSyncLogsConfig?.tablesToWrite?.snapshot,
                        changelog: event.target.checked,
                        syncRuns: warehouseSyncLogsConfig?.tablesToWrite?.syncRuns,
                      },
                    });
                  }}
                />

                <Checkbox
                  description="Contains a log of all the sync runs. The changelog and snapshot tables can be JOINed to this table for more information on when the sync occurred and how it was configured."
                  isChecked={
                    warehouseSyncLogsConfig?.tablesToWrite?.syncRuns ?? sourceSyncLogConfig?.tablesToWrite?.syncRuns ?? false
                  }
                  isDisabled={updatePermission.unauthorized}
                  label={`Sync runs${isOverriding("syncRuns") ? " (overriding default)" : ""}`}
                  onChange={(event) => {
                    setWarehouseSyncLogsConfig({
                      tablesToWrite: {
                        snapshot: warehouseSyncLogsConfig?.tablesToWrite?.snapshot,
                        changelog: warehouseSyncLogsConfig?.tablesToWrite?.changelog,
                        syncRuns: event.target.checked,
                      },
                    });
                  }}
                />
              </CheckboxGroup>
            </Field>
          )}
        </Grid>
        <SidebarForm
          hideInviteTeammate
          hideSendMessage
          buttons={
            source?.plan_in_warehouse
              ? [
                  <Permission key={0}>
                    <Button
                      disabled={!dirty}
                      loading={updatingWarehouseSyncLogsConfig}
                      sx={{ width: "100%" }}
                      onClick={() => updateWarehouseSyncLogsConfig()}
                    >
                      Save
                    </Button>
                  </Permission>,
                ]
              : undefined
          }
          docsUrl={`${import.meta.env.VITE_DOCS_URL}/syncs/warehouse-sync-logs/`}
          invite="If you need help setting up this sync"
          name="warehouse sync logs"
        />
      </Row>
    </FeatureFull>
  );
};

function getSourceSyncHistoryConfig(source: Source | undefined, isVisual: boolean): WarehouseHistoryConfig | undefined {
  if (!source?.warehouse_history_config) {
    return undefined;
  }
  const config: WarehouseHistoryConfig = {
    tablesToWrite: {
      changelog: false,
      syncRuns: false,
      snapshot: false,
    },
  };
  if (source?.warehouse_history_config?.changelog) {
    if (!source?.warehouse_history_config?.changelog?.audience || isVisual) {
      config.tablesToWrite.changelog = true;
    }
  }
  if (source?.warehouse_history_config?.snapshot) {
    if (!source?.warehouse_history_config?.snapshot?.audience || isVisual) {
      config.tablesToWrite.syncRuns = true;
    }
  }
  if (source?.warehouse_history_config?.syncRuns) {
    if (!source?.warehouse_history_config?.syncRuns?.audience || isVisual) {
      config.tablesToWrite.snapshot = true;
    }
  }

  return config;
}
