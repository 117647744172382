import { useState, FC } from "react";

import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Menu, MenuList, MenuDivider, MenuItem, useToast, MenuActionsButton } from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { isEqual } from "lodash";
import { Text } from "theme-ui";

import { AudienceQuery, useUpdateAudienceMutation } from "src/graphql";
import { AdditionalColumn, EventCondition, isTraitColumn, VisualQueryFilter } from "src/types/visual";
import { Column, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Link } from "src/ui/link";
import { Table } from "src/ui/table";

import { DeleteConfirmationModal } from "../modals/delete-confirmation-modal";
import { AudienceEventTraitForm } from "./audience-event-trait-form";
import { AudienceTraitForm } from "./audience-trait-form";

type Audience = NonNullable<AudienceQuery["segments_by_pk"]>;

type Props = {
  audience: Audience;
};

export const AudienceTraits: FC<Readonly<Props>> = ({ audience }) => {
  const filter = audience.visual_query_filter as VisualQueryFilter;
  const traits = filter?.additionalColumns;

  const { toast } = useToast();

  const { mutateAsync: updateAudience } = useUpdateAudienceMutation();

  const [additionalColumn, setAdditionalColumn] = useState<AdditionalColumn | null>(null);
  const [deleting, setDeleting] = useState(false);
  const [creating, setCreating] = useState(false);

  const updateAdditionalColumns = async (newAdditionalColumn: AdditionalColumn) => {
    if (creating) {
      await updateAudience({
        id: audience.id,
        input: {
          visual_query_filter: {
            ...(audience.visual_query_filter || {}),
            additionalColumns: [newAdditionalColumn, ...(audience.visual_query_filter?.additionalColumns || [])],
          },
        },
      });

      toast({
        id: "create-audience-trait",
        title: "Trait was created",
        variant: "success",
      });
    } else {
      const traitToUpdateIndex = audience.visual_query_filter?.additionalColumns?.findIndex((existingColumn) =>
        isEqual(additionalColumn, existingColumn),
      );

      if (traitToUpdateIndex === -1) {
        Sentry.captureException(new Error("Trait to update not found"));
        return;
      }

      const newAdditionalColumns = [
        ...(audience.visual_query_filter?.additionalColumns.slice(0, traitToUpdateIndex) ?? []),
        newAdditionalColumn,
        ...(audience.visual_query_filter?.additionalColumns.slice(traitToUpdateIndex + 1) ?? []),
      ];

      await updateAudience({
        id: audience.id,
        input: {
          visual_query_filter: {
            ...(audience.visual_query_filter || {}),
            additionalColumns: newAdditionalColumns,
          },
        },
      });

      toast({
        id: "update-audience-trait",
        title: "Trait was updated",
        variant: "success",
      });
    }
  };

  const deleteAdditionalColumn = async () => {
    const traitToDeleteIndex = audience.visual_query_filter?.additionalColumns?.findIndex((existingColumn) =>
      isEqual(additionalColumn, existingColumn),
    );

    if (traitToDeleteIndex === -1) {
      Sentry.captureException(new Error("Trait to delete not found"));
      return;
    }

    const newAdditionalColumns = [
      ...(audience.visual_query_filter?.additionalColumns.slice(0, traitToDeleteIndex) ?? []),
      ...(audience.visual_query_filter?.additionalColumns.slice(traitToDeleteIndex + 1) ?? []),
    ];

    await updateAudience({
      id: audience.id,
      input: {
        visual_query_filter: {
          ...(audience.visual_query_filter || {}),
          additionalColumns: newAdditionalColumns,
        },
      },
    });
  };

  const tableColumns = [
    {
      key: "alias",
      name: "Name",
    },
    {
      key: "column.column",
      name: "Type",
      cell: (column) => (
        <Text sx={{ textTransform: "capitalize" }}>
          {column.type === "trait"
            ? audience.parent?.traits.find((t) => t.id === column.traitDefinitionId)?.type
            : column.traitType}
        </Text>
      ),
    },
    {
      max: "36px",
      cell: (column) => {
        return (
          <Menu>
            <MenuActionsButton />

            <MenuList>
              <MenuItem
                icon={PencilIcon}
                onClick={() => {
                  setAdditionalColumn(column);
                }}
              >
                Edit
              </MenuItem>

              <MenuDivider />
              <MenuItem
                icon={TrashIcon}
                variant="danger"
                onClick={() => {
                  setAdditionalColumn(column);
                  setDeleting(true);
                }}
              >
                Delete
              </MenuItem>
            </MenuList>
          </Menu>
        );
      },
    },
  ];

  if (!audience.parent?.traits?.length) {
    return (
      <Column sx={{ p: 8, borderRadius: 1, border: "small", alignItems: "center" }}>
        <Text sx={{ fontWeight: "bold", fontSize: 3, mb: 4, color: "base.5" }}>
          You haven’t added any traits to{" "}
          <Link to={`/audiences/setup/parent-models/${audience.parent?.id}`}>{audience.parent?.name}</Link>
        </Text>
        <Text sx={{ mb: 6, color: "base.5", textAlign: "center", maxWidth: "60ch" }}>
          Traits allow you to define and sync specific data from this model
        </Text>
        <Link to={`/audiences/setup/parent-models/${audience.parent?.id}`}>
          <Button>+ Add trait</Button>
        </Link>
      </Column>
    );
  }

  const updating = additionalColumn && !deleting;

  return (
    <>
      {traits?.length ? (
        <>
          <Row sx={{ width: "100%", justifyContent: "flex-end", mb: 4 }}>
            <Button onClick={() => setCreating(true)}>+ Add trait</Button>
          </Row>
          <Table columns={tableColumns} data={traits} />
        </>
      ) : (
        <Column sx={{ p: 8, borderRadius: 1, border: "small", alignItems: "center" }}>
          <Text sx={{ fontWeight: "bold", fontSize: 3, mb: 4, color: "base.5" }}>You haven’t added any traits</Text>
          <Text sx={{ mb: 6, color: "base.5", textAlign: "center", maxWidth: "60ch" }}>
            Traits allow you to define and sync specific data from this model
          </Text>
          <Button onClick={() => setCreating(true)}>+ Add trait</Button>
        </Column>
      )}
      {(creating || updating) &&
        (additionalColumn?.column?.column?.type === "event_trait" ? (
          <AudienceEventTraitForm
            alias={additionalColumn.alias}
            condition={additionalColumn.column.column.filteredEvent as EventCondition}
            config={additionalColumn.column.column.traitConfig}
            parent={audience.parent}
            title="Edit event trait"
            type={additionalColumn.column.column.traitType}
            onClose={() => {
              setAdditionalColumn(null);
              setCreating(false);
            }}
            onSubmit={updateAdditionalColumns}
          />
        ) : (
          <AudienceTraitForm
            alias={additionalColumn?.alias}
            conditions={
              additionalColumn && isTraitColumn(additionalColumn.column.column)
                ? additionalColumn.column.column.conditions
                : undefined
            }
            parent={audience.parent}
            title={creating ? "Add trait" : "Edit trait"}
            trait={audience.parent.traits.find((t) =>
              additionalColumn && isTraitColumn(additionalColumn.column.column)
                ? t.id === additionalColumn.column.column.traitDefinitionId
                : false,
            )}
            traits={audience.parent.traits}
            onClose={() => {
              setAdditionalColumn(null);
              setCreating(false);
            }}
            onSubmit={updateAdditionalColumns}
          />
        ))}
      {deleting && (
        <DeleteConfirmationModal
          isOpen={deleting}
          label="trait"
          onClose={() => {
            setAdditionalColumn(null);
            setDeleting(false);
          }}
          onDelete={deleteAdditionalColumn}
        />
      )}
    </>
  );
};
