import { FC } from "react";

import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { Box, Column, Heading, Link, Row, Text } from "@hightouchio/ui";
import Helmet from "react-helmet";
import { Image } from "theme-ui";

import { AuthLayout } from "src/components/auth/auth-layout";
import { EmailButton, GoogleButton, MicrosoftButton } from "src/components/auth/buttons";
import { Terms } from "src/components/auth/terms";
import { useAuthState } from "src/components/auth/use-auth-state";
import { Logo } from "src/ui/brand";
import { BrandHeading } from "src/ui/brand/brand-heading";
import { useNavigate } from "src/utils/navigate";

export const Signup: FC = () => {
  const navigate = useNavigate();
  const { googleAuthUrl, microsoftAuthUrl } = useAuthState();

  return (
    <>
      <Helmet>
        <title>Sign up</title>
      </Helmet>

      <AuthLayout logo={false} pt={10}>
        <Column flex={1} maxWidth="960px" minHeight="500px" my="auto" width="100%">
          <Box alignSelf="flex-start" mb={16}>
            <Logo size="160px" variant="full" />
          </Box>

          <Row justify="space-between">
            <Column gap={16} maxW="400px" sx={{ h2: { fontSize: "40px" } }}>
              <BrandHeading fontWeight="bold">
                Join thousands of companies who{" "}
                <Box as="span" color="lightspeed">
                  activate their data
                </Box>{" "}
                using Hightouch
              </BrandHeading>

              <Column as="ul" gap={3} sx={{ svg: { fill: "grass" } }}>
                <Row align="center" as="li" gap={2}>
                  <CheckCircleIcon width="20px" />
                  <Text fontWeight="medium">Generous free tier (no credit card required)</Text>
                </Row>
                <Row align="center" as="li" gap={2}>
                  <CheckCircleIcon width="20px" />
                  <Text fontWeight="medium">Easy setup, plus 24/7 technical support</Text>
                </Row>
                <Row align="center" as="li" gap={2}>
                  <CheckCircleIcon width="20px" />
                  <Text fontWeight="medium">20+ data sources and 100+ destinations</Text>
                </Row>
              </Column>
            </Column>
            <Column gap={8} width="350px">
              <Column gap={4} sx={{ "> span": { fontSize: "16px" } }}>
                <Text fontWeight="semibold">Sign up with your work email</Text>
                <Column gap={2}>
                  <GoogleButton
                    action="Sign up"
                    onClick={() => {
                      window.location.href = googleAuthUrl;
                    }}
                  />
                  <MicrosoftButton
                    action="Sign up"
                    onClick={() => {
                      window.location.href = microsoftAuthUrl;
                    }}
                  />
                  <EmailButton
                    action="Sign up"
                    onClick={() => {
                      navigate("email", { slug: false });
                    }}
                  />
                </Column>
              </Column>
              <Box
                sx={{
                  a: {
                    color: "lightspeed",
                    fontWeight: "medium",
                    ":hover": { color: "#B2D82A" },
                    ":active": { color: "#8DA929" },
                  },
                }}
              >
                <Text fontWeight="medium">Already have an account?</Text> <Link href="/login">Sign in</Link>
              </Box>

              <Terms variant="dark" />
            </Column>
          </Row>
        </Column>

        <Column
          align="center"
          bg="white"
          color="forest"
          css={`
            @media (max-height: 700px) {
              display: none;
            }
          `}
          flex={1}
          gap={8}
          maxHeight="360px"
          px={8}
          py={12}
          sx={{ h2: { fontSize: "16px", color: "gray.600" } }}
          width="100vw"
        >
          <Heading size="md">Trusted by teams at</Heading>

          <Row align="center" gap={16} height="36px">
            <Icon src="https://cdn.sanity.io/images/pwmfmi47/production/c79ef630644336a446eff2ccdc7afded6cd54c02-240x72.svg" />
            <Icon src="https://cdn.sanity.io/images/pwmfmi47/production/fa94dc0889963d5ee32788ce2b199486d0c5a261-346x72.svg" />
            <Icon src="https://cdn.sanity.io/images/pwmfmi47/production/07791cf17a1edc8a7995ccab5c2501eb24f7eb44-900x531.png" />
            <Icon src="https://cdn.sanity.io/images/pwmfmi47/production/64ac3d884b1f663719ed83a72910b195ad24819e-189x72.svg" />
            <Icon src="https://cdn.sanity.io/images/pwmfmi47/production/f0bb1eeceb7f0085f184ecdb8c610dc58a1b8dd9-336x72.svg" />
            <Icon src="https://cdn.sanity.io/images/pwmfmi47/production/f2b9e1cbc5aa8bd511324a7487f5c0127fbc9d6a-328x72.svg" />
          </Row>
        </Column>
      </AuthLayout>
    </>
  );
};

const Icon = ({ src }) => (
  <Image src={src} sx={{ objectFit: "contain", height: "100%", width: "100%", maxWidth: "120px", filter: "brightness(0)" }} />
);
