import { FC } from "react";

import Helmet from "react-helmet";

import { SearchProvider } from "src/components/search/search-provider";
import { PageSpinner } from "src/ui/loading";
import { Wizard } from "src/ui/wizard";
import { useNavigate } from "src/utils/navigate";
import { useQueryString } from "src/utils/use-query-string";
import { useWizardStepper } from "src/utils/use-wizard-stepper";

import { useCreateDestinationWizard } from "./use-create-destination-wizard";

export const CreateDestination: FC = () => {
  return (
    <SearchProvider>
      <CreateDestinationWithSearch />
    </SearchProvider>
  );
};

const CreateDestinationWithSearch: FC = () => {
  const navigate = useNavigate();

  const [step, setStep] = useWizardStepper(0);

  const {
    data: { id: oauthDestinationId },
  } = useQueryString();

  if (oauthDestinationId && localStorage.getItem("onboardingOauth")) {
    localStorage.removeItem("onboardingOauth");
    navigate(`/onboarding?onboardingDestinationId=${oauthDestinationId}`);
  }

  const { createDestination, oAuthSuccess, steps, loading } = useCreateDestinationWizard({
    step,
    setStep,
    onConnectClick: () => {
      localStorage.removeItem("onboardingOauth");
    },
    onSubmit: ({ id }) => navigate(`/destinations/${id}`),
  });

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <>
      <Helmet>
        <title>New destination</title>
      </Helmet>

      <Wizard
        previousDisabled={oAuthSuccess}
        setStep={setStep}
        step={step}
        steps={steps}
        title="New destination"
        onCancel={() => {
          navigate("/destinations");
        }}
        onSubmit={createDestination}
      />
    </>
  );
};
