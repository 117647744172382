import { groupBy, isNumber, isObject } from "lodash";
import formatXml from "xml-formatter";

import { SyncFailedWithRejectedRowsError } from "src/types/sync-errors";
import { Badge } from "src/ui/badge";
import { Row } from "src/ui/box";

import { SyncAttemptFragment } from "../graphql/types";

export enum SyncStatus {
  DISABLED = "disabled",
  PENDING = "pending",
  SUCCESS = "success",
  QUERYING = "querying",
  REPORTING = "reporting",
  PREPARING = "preparing-plan",
  PROCESSING = "processing",
  ACTIVE = "active",
  INCOMPLETE = "incomplete",
  IN_PROGRESS = "inprogress",
  ABORTED = "aborted",
  CANCELLED = "cancelled",
  FAILED = "failed",
  WARNING = "warning",
  INTERRUPTED = "interrupted",
  ENQUEUED = "queued",

  /**
   * @deprecated Use SyncStatus.FAILED with a syncRequestErrorCode of PREVIOUS_SYNC_RUN_OBJECT_MISSING or WAREHOUSE_TABLE_MISSING.
   */
  UNPROCESSABLE = "unprocessable",
}

/**
 * Returns true if the specified SyncStatus is terminal, i.e. it will never
 * transition to another status.
 */
export const syncStatusIsTerminal = (status: SyncStatus): boolean => {
  switch (status) {
    case SyncStatus.DISABLED:
    case SyncStatus.SUCCESS:
    case SyncStatus.ABORTED:
    case SyncStatus.CANCELLED:
    case SyncStatus.FAILED:
      return true;
    default:
      return false;
  }
};

export const getSyncStatusColor = (status: SyncStatus) => {
  switch (status) {
    case SyncStatus.SUCCESS:
      return "green";
    case SyncStatus.WARNING:
      return "yellow";
    case SyncStatus.FAILED:
      return "red";
    case SyncStatus.DISABLED:
    case SyncStatus.CANCELLED:
    case SyncStatus.PENDING:
      return "base.3";
    default:
      return "blue";
  }
};

export const syncStatusForTag = (status: SyncStatus) => {
  switch (status) {
    case SyncStatus.SUCCESS:
      return "success";
    case SyncStatus.WARNING:
      return "warning";
    case SyncStatus.FAILED:
      return "error";
    case SyncStatus.DISABLED:
    case SyncStatus.CANCELLED:
    case SyncStatus.PENDING:
      return "inactive";
    default:
      return "processing";
  }
};

export const SyncStatusToText = {
  [SyncStatus.DISABLED]: "Disabled",
  [SyncStatus.PENDING]: "Pending",
  [SyncStatus.SUCCESS]: "Healthy",
  [SyncStatus.QUERYING]: "Querying",
  [SyncStatus.REPORTING]: "Reporting",
  [SyncStatus.PROCESSING]: "Processing",
  [SyncStatus.CANCELLED]: "Canceled",
  [SyncStatus.FAILED]: "Failed",
  [SyncStatus.WARNING]: "Warning",
  [SyncStatus.INTERRUPTED]: "Interrupted",
  [SyncStatus.ENQUEUED]: "Queued",
};

export const SyncStatusBadge = ({
  status: statusProp,
  request,
  tooltip = true,
}: {
  status?: any;
  request?: any;
  tooltip?: boolean;
}) => {
  const status = statusProp || request?.status_computed;

  if (!status) {
    return null;
  }

  switch (status) {
    case SyncStatus.DISABLED:
      return (
        <Badge tooltip={tooltip ? "This sync was disabled by the user." : undefined} variant="base">
          {SyncStatusToText[status]}
        </Badge>
      );
    case SyncStatus.WARNING:
      return (
        <Badge
          tooltip={tooltip ? "We processed all rows, but some had errors when syncing to the destination." : undefined}
          variant="yellow"
        >
          {SyncStatusToText[status]}
        </Badge>
      );
    case SyncStatus.FAILED:
      return (
        <Badge tooltip={tooltip ? "We were unable to process all rows due to a fatal error." : undefined} variant="red">
          {SyncStatusToText[status]}
        </Badge>
      );
    case SyncStatus.PROCESSING:
      return <ProcessingBadge request={request} />;
    case SyncStatus.CANCELLED:
      return (
        <Badge tooltip={tooltip ? "This sync was canceled by a user." : undefined} variant="base">
          {SyncStatusToText[status]}
        </Badge>
      );
    case SyncStatus.SUCCESS:
      return (
        <Badge tooltip={tooltip ? "All rows were synced to the destination." : undefined} variant="green">
          {SyncStatusToText[status]}
        </Badge>
      );
    case SyncStatus.PENDING:
      return (
        <Badge tooltip={tooltip ? "You haven't started a run yet." : undefined} variant="base">
          {SyncStatusToText[status]}
        </Badge>
      );
    case SyncStatus.QUERYING:
      return (
        <Badge tooltip={tooltip ? "We are running your model query." : undefined} variant="indigo">
          {SyncStatusToText[status]}
        </Badge>
      );
    case SyncStatus.INTERRUPTED:
      return (
        <Badge
          tooltip={tooltip ? "This sync was momentarily interrupted and will be automatically restarted." : undefined}
          variant="base"
        >
          {SyncStatusToText[status]}
        </Badge>
      );
    case SyncStatus.ENQUEUED:
      return (
        <Badge tooltip={tooltip ? "This sync will start soon." : undefined} variant="base">
          {SyncStatusToText[status]}
        </Badge>
      );
    case SyncStatus.REPORTING:
      if (request?.phase_to_status?.report?.error?.message) {
        return (
          <Badge
            tooltip={
              tooltip
                ? `Hightouch will automatically retry writing sync logs. The full error was: ${request?.phase_to_status.report.error.message}`
                : undefined
            }
            variant="yellow"
          >
            {SyncStatusToText[status]}
          </Badge>
        );
      }
      return (
        <Badge tooltip={tooltip ? "We are reporting post-run summary" : undefined} variant="indigo">
          {SyncStatusToText[status]}
        </Badge>
      );

    default:
      throw new Error("Invalid sync status: " + status);
  }
};

/** Don't include a percentage for syncs that used AllPlanner */
function getProcessingString(request: { plannerType?: string; completion_ratio: number }): string {
  if (request.plannerType === "all") {
    return "";
  }
  return `${request.completion_ratio ? Math.round(request.completion_ratio * 100) : "0"}%`;
}

interface SyncAttemptDiff {
  synced: {
    add: number;
    remove: number;
    change: number;
  };
  rejected: {
    add: number | null;
    remove: number | null;
    change: number | null;
  };
}

export const getSyncAttemptDiff = (
  attempt:
    | Pick<
        SyncAttemptFragment,
        "add_checkpoint" | "remove_checkpoint" | "change_checkpoint" | "add_rejected" | "remove_rejected" | "change_rejected"
      >
    | undefined,
): SyncAttemptDiff | undefined => {
  if (attempt) {
    const { add_checkpoint, remove_checkpoint, change_checkpoint, add_rejected, remove_rejected, change_rejected } = attempt;

    return {
      synced: {
        add: add_checkpoint - (add_rejected ?? 0),
        remove: remove_checkpoint - (remove_rejected ?? 0),
        change: change_checkpoint - (change_rejected ?? 0),
      },
      rejected: {
        add: add_rejected,
        remove: remove_rejected,
        change: change_rejected,
      },
    };
  }

  return undefined;
};

/**
 * getObjectName returns a human friendly name for a synced object.
 **/
export function getObjectName(objectVal: string | undefined): string | undefined {
  if (!objectVal) {
    return objectVal;
  }

  const salesforceMultiOptions = [
    { label: "Contact or Lead", value: "___hightouch-reserved-contact-or-lead" },
    { label: "Account or Lead", value: "___hightouch-reserved-account-or-lead" },
  ];

  // We use a special identifier for Salesforce multitypes (e.g. Contact or Lead).
  const sfMultiType = salesforceMultiOptions.find((mt) => mt.value === objectVal);
  if (sfMultiType != null) {
    return sfMultiType.label;
  }

  return objectVal;
}

export const DEPRECATED_ERROR = SyncFailedWithRejectedRowsError.MESSAGE;

// DLQ not supported before this
export const DLQ_RELEASE_TIMESTAMP = 1615260600; //Unix

export interface RequestInfo {
  requestType: string;
  data: unknown;
  status: string;
  method: string;
  meta: any;
  destination: string;
  requestBody: string;
  requestIsJson: boolean;
  requestIsXml: boolean;
  requestHeaders: {
    [name: string]: string | number;
  };
  responseBody: string;
  responseIsJson: boolean;
  responseIsXml: boolean;
  errored: boolean;
}

const BATCH_REQUEST_INFO_DEFAULT_STATUS = "Success";
const BATCH_REQUEST_INFO_DEFAULT_DESTINATION = "Unknown destination";
const BATCH_REQUEST_INFO_DEFAULT_METHOD = "Save";

export const isXml = (value: unknown): boolean => {
  if (typeof value !== "string") {
    return false;
  }
  try {
    formatXml(value);
    return true;
  } catch (err) {
    return false;
  }
};

export const processRequestInfo = (requestInfo, definition) => {
  let data;
  let request;
  let requestBody;
  let requestIsJson = false;
  let requestIsXml = false;
  let response;
  let meta;
  let responseBody;
  let responseIsJson = false;
  let responseIsXml = false;
  let errored = false;
  let status = BATCH_REQUEST_INFO_DEFAULT_STATUS;
  let method = BATCH_REQUEST_INFO_DEFAULT_METHOD;
  let destination = BATCH_REQUEST_INFO_DEFAULT_DESTINATION;
  let requestHeaders;

  try {
    data = requestInfo.data;

    request = requestInfo.requestType === "method-call" ? data.parameters : data.request?.body;
    requestIsJson = isObject(request);
    requestIsXml = isXml(request);
    requestBody = requestIsJson ? JSON.stringify(request, null, 2) : requestIsXml ? formatXml(request) : request;

    response = requestInfo.requestType === "method-call" ? data.result : data.response?.body;
    responseIsJson = isObject(response);
    responseIsXml = isXml(response);
    responseBody = responseIsJson
      ? JSON.stringify(response, null, 2)
      : responseIsXml
      ? formatXml(response)
      : response || JSON.stringify(data.error, null, 2);

    if (data.method) {
      method = data.method;
    }
    if (data.error) {
      errored = true;
    }

    if (data.response?.status) {
      status = data.response?.status;
    }
    if (data.request?.method) {
      method = data.request?.method;
    }
    if (data.request?.url) {
      destination = data.request?.url;
    }
    if (data.request?.headers) {
      requestHeaders = data.request?.headers;
    }

    if (data.meta) {
      meta = data.meta;
    }
  } catch (error) {
    data = requestInfo.data;
  }

  if (isNumber(status)) {
    errored = status >= 400;
    status = errored ? `${status} ERR` : `${status} OK`;
  }

  if (destination === BATCH_REQUEST_INFO_DEFAULT_DESTINATION && definition?.name) {
    destination = definition.name;
  }

  if (status === BATCH_REQUEST_INFO_DEFAULT_STATUS && errored) {
    status = "Error";
  }

  return {
    ...requestInfo,
    requestBody,
    requestIsJson,
    requestIsXml,
    requestHeaders,
    responseBody,
    responseIsJson,
    responseIsXml,
    meta,
    data,
    errored,
    status,
    method,
    destination,
  };
};

const ProcessingBadge = ({ request }) => {
  if (!request) {
    return (
      <Badge sx={{ bg: "white", position: "relative" }} variant="indigo">
        {SyncStatusToText[SyncStatus.PROCESSING]}
      </Badge>
    );
  }

  const percentString = getProcessingString(request);

  const percent = Math.round(request.completion_ratio * 100);

  return (
    <Badge
      sx={{ bg: "white", position: "relative" }}
      tooltip={`We are syncing rows to the destination${percentString ? ` (${percent}%)` : ""}`}
      variant="indigo"
    >
      {SyncStatusToText[SyncStatus.PROCESSING]}
      <Row
        sx={{
          height: "100%",
          width: "100%",
          transition: "transform 250ms",
          transform: `translateX(${percent - 100}%)`,
          position: "absolute",
          top: 0,
          left: 0,
          bg: "blues.0",
        }}
      />
    </Badge>
  );
};

/**
 * Syncs don't have names. We can instead identify them by their destination's name.
 * However, if there are multiple syncs with the same destination, we may need to disambiguate them.
 * In such a case, we append the sync ID to the destination name.
 */

export const disambiguateSyncs = (syncs) => {
  const syncsGroupedByDestinationName = groupBy(syncs, (sync) => sync?.destination?.name);

  return syncs.map((sync) => {
    const destinationHasMultipleSyncs = (syncsGroupedByDestinationName[sync?.destination?.name] || []).length > 1;

    return {
      ...sync,
      name: destinationHasMultipleSyncs ? `${sync?.destination?.name} (Sync #${sync.id})` : sync?.destination?.name,
    };
  });
};
