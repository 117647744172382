import { useState, FC } from "react";

import { isEqual } from "lodash";
import { Grid } from "theme-ui";
import { Schema } from "yup";

import { Container } from "src/ui/box";
import { FieldError } from "src/ui/field";

import airtable from "./airtable";
import googlesheets from "./google-sheets";
import metabase from "./metabase";
import mixpanel from "./mixpanel";
import mongodb from "./mongodb";
import s3 from "./s3";
import sftp from "./sftp";

type Source = {
  id: string;
  type: string;
  config: Record<string, any>;
};

export type CustomQuery = {
  type?: string;
  path?: string;
  projectId?: number;
  baseId?: string;
} & Record<string, unknown>;

export type Query = {
  QueryForm: FC<Readonly<CustomQueryFormProps>>;
  QueryView: FC<Readonly<CustomQueryViewProps>>;
  querySchema: Schema<CustomQuery, object>;
};

export type CustomQueryFormProps = {
  source: Source;
  query: CustomQuery | undefined;
  onChange: (query: CustomQuery) => void;
  error: Error | null;
  setError: (error: Error | null) => void;
};

export type CustomQueryViewProps = {
  source: Source;
  query: CustomQuery;
  error: Error | null;
  setError: (error: Error | null) => void;
};

const QUERIES: { [key: string]: Query } = {
  airtable,
  googlesheets,
  mixpanel,
  metabase,
  s3,
  sftp,
  mongodb,
};

export const CustomQueryForm: FC<
  Readonly<{
    source: Source;
    query?: CustomQuery;
    onChange: (query: CustomQuery) => void;
  }>
> = ({ source, query, onChange }) => {
  const [error, setError] = useState<Error | null>(null);
  const Form = QUERIES[source.type]?.QueryForm;

  return (
    <Container center={false} size="small">
      <Grid gap={8}>
        {Form && <Form error={error} query={query} setError={setError} source={source} onChange={onChange} />}
        <FieldError error={error} />
      </Grid>
    </Container>
  );
};

export const CustomQueryView: FC<Readonly<{ source: Source; query: CustomQuery }>> = ({ source, query }) => {
  const View = QUERIES[source.type]?.QueryView;
  const [error, setError] = useState<Error | null>(null);

  return (
    <Container center={false} size="small">
      <Grid gap={8}>
        {View && <View error={error} query={query} setError={setError} source={source} />}
        <FieldError error={error} />
      </Grid>
    </Container>
  );
};

export async function validateCustomQuery(source: Source, query: CustomQuery | undefined): Promise<void> {
  const definition = QUERIES[source.type];

  if (definition) {
    await definition.querySchema.validate(query, { abortEarly: false });
  }
}

export function isCustomQueryDirty(state: CustomQuery | undefined, stored: CustomQuery | undefined): boolean {
  return !isEqual(state, stored);
}
