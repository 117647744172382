import { FC } from "react";

import { ExploreSource } from "src/components/explore/explore";
import { ModelQuery } from "src/graphql";
import { Wizard } from "src/ui/wizard";

import { useCreateModelWizard } from "../models/use-create-model-wizard";

type Props = {
  model?: ModelQuery["segments_by_pk"];
  source?: ExploreSource | null;
  onCancel(): void;
  onSubmit({ id, type }: { id: string; type: string | undefined }): void;
};

export const OnboardingCreateModel: FC<Readonly<Props>> = ({ model, source, onCancel, onSubmit }) => {
  const { createModel, setStep, step, steps } = useCreateModelWizard({
    model,
    source,
    onSubmit: (args) => {
      onSubmit(args);
    },
  });

  return <Wizard setStep={setStep} step={step} steps={steps} title="New model" onCancel={onCancel} onSubmit={createModel} />;
};
