import { ArrowsRightLeftIcon, CalendarDaysIcon, Cog6ToothIcon, PlayPauseIcon } from "@heroicons/react/24/solid";
import { Code, Text } from "@hightouchio/ui";
import { capitalize } from "lodash";

import { commonActivityMappings } from "src/components/resource-activity/common-mappings";
import { normalizeName, ResourceActivityMapper } from "src/components/resource-activity/timeline";
import { scheduleTypeToLabel } from "src/components/schedule/types";

enum MappingType {
  Standard = "standard",
  Static = "static",
  Template = "template",
  Variable = "variable",
}

type Mapping = { to: string } & (
  | { type: MappingType.Static; value: string }
  | { type: MappingType.Standard; from: string | { column: { name: string } } }
  | { type: MappingType.Template; template: string }
  | { type: MappingType.Variable; variable: string }
);

function parseSyncMapping(column: Mapping): { type: MappingType; to: string; from: string } {
  switch (column.type) {
    case MappingType.Static:
      return {
        type: column.type,
        to: column.to,
        from: column.value,
      };
    case MappingType.Standard:
      return {
        type: column.type,
        to: column.to,
        from: typeof column.from === "string" ? column.from : column.from.column.name,
      };
    case MappingType.Variable:
      return {
        type: column.type,
        to: column.to,
        from: column.variable,
      };
    case MappingType.Template:
      return {
        type: column.type,
        to: column.to,
        from: column.template,
      };
  }
}

export const syncActivityMappers: ResourceActivityMapper[] = [
  ...commonActivityMappings,
  {
    accessor: "schedule_paused",
    parser: (activity, { parsedDiff }) => {
      if (parsedDiff.type !== "value") {
        return null;
      }

      return {
        message: `${activity.metadata.user_name} ${parsedDiff.value ? "paused" : "resumed"} sync scheduling`,
        icon: <PlayPauseIcon />,
      };
    },
  },
  // Handle top-level config keys
  {
    accessor: "config",
    parser: (activity, { parsedDiff }) => {
      if (parsedDiff.type !== "nested") {
        return null;
      }
      const nestedKeys = Object.keys(parsedDiff.nested);

      // return null if there are only mapping or external ID changes
      if (nestedKeys.length === 1 && (nestedKeys[0] === "mappings" || nestedKeys[0] === "externalIdMapping")) {
        return null;
      }

      return {
        message: `${activity.metadata.user_name} updated the configuration`,
        icon: <Cog6ToothIcon />,
        changes: nestedKeys
          .map((key) => {
            const parsed = parsedDiff.nested[key];
            if (!parsed) {
              return null;
            }
            if (parsed.type === "value") {
              // capitalize operation name
              switch (parsed.operation) {
                case "added":
                  return (
                    <Text size="sm">
                      Set <Code>{normalizeName(key)}</Code> to <Code>{String(parsed.value)}</Code>
                    </Text>
                  );
                case "removed":
                  return (
                    <Text size="sm">
                      Removed <Code>{normalizeName(key)}</Code>
                    </Text>
                  );
              }
              return (
                <Text size="sm">
                  {capitalize(parsed.operation)} <Code>{normalizeName(key)}</Code> to <Code>{String(parsed.value)}</Code>
                </Text>
              );
            }
            return null;
          })
          .filter(Boolean) as JSX.Element[],
      };
    },
  },
  {
    accessor: "config.mappings",
    parser: (activity, { parsedDiff }) => {
      if (parsedDiff.type !== "array") {
        return null;
      }
      return {
        message: `${activity.metadata.user_name} updated the sync mappings`,
        icon: <ArrowsRightLeftIcon />,
        changes: parsedDiff.array.map((parsed, idx) => {
          const parsedMapping = parseSyncMapping(parsed.value);
          if (parsed.operation === "removed") {
            return (
              <Text size="sm">
                Removed {parsedMapping.type} mapping from <Code>{parsedMapping.from}</Code> to <Code>{parsedMapping.to}</Code>
              </Text>
            );
          }
          if (parsed.operation === "added") {
            return (
              <Text size="sm">
                Added {parsedMapping.type} mapping from <Code>{parsedMapping.from}</Code> to <Code>{parsedMapping.to}</Code>
              </Text>
            );
          }

          return (
            <Text key={idx} size="sm">
              {capitalize(parsed.operation)} {parsedMapping.type} mapping from <Code>{parsedMapping.from}</Code> to{" "}
              <Code>{parsedMapping.to}</Code>
            </Text>
          );
        }),
      };
    },
  },
  {
    accessor: "config.externalIdMapping",
    parser: (activity, { parsedDiff, newValue }) => {
      if (parsedDiff.type !== "nested") {
        return null;
      }
      return {
        message: `${activity.metadata.user_name} updated the sync external ID mapping`,
        icon: <ArrowsRightLeftIcon />,
        changes: [
          <Text key="1" size="sm">
            Updated external ID mapping as <Code>{newValue.from}</Code> to <Code>{newValue.to}</Code>
          </Text>,
        ],
      };
    },
  },
  // Handle setting sync schedule to manual which makes the whole schedule object null
  {
    accessor: "schedule",
    parser: (activity, { parsedDiff }) => {
      if (parsedDiff.type === "value" && parsedDiff.value === null) {
        return {
          message: <Text>{activity.metadata.user_name} disabled automatic scheduling</Text>,
          icon: <CalendarDaysIcon />,
        };
      }
      return null;
    },
  },
  {
    accessor: "schedule.type",
    overrideDiffAccessor: { or: [{ var: "schedule.type" }, { var: "schedule.1.type" }] },
    parser: (activity, { parsedDiff }) => {
      if (parsedDiff.type !== "value") {
        return null;
      }
      return {
        message: (
          <Text>
            {activity.metadata.user_name} updated the schedule type to <Code>{scheduleTypeToLabel(parsedDiff.value)}</Code>
          </Text>
        ),
        icon: <CalendarDaysIcon />,
      };
    },
  },
  {
    accessor: "schedule.schedule.expression",
    parser: (activity, { parsedDiff }) => {
      if (parsedDiff.type !== "value" || parsedDiff.operation === "removed") {
        return null;
      }
      return {
        message: <Text>{activity.metadata.user_name} updated the cron expression</Text>,
        icon: <CalendarDaysIcon />,
        changes: [
          <Text key={1} size="sm">
            Set cron expression to <Code>{parsedDiff.value}</Code>
          </Text>,
        ],
      };
    },
  },
  {
    accessor: "schedule.schedule.interval",
    parser: (activity, { parsedDiff, newValue }) => {
      if (parsedDiff.type === "array" || (parsedDiff.type == "value" && parsedDiff.operation === "removed")) {
        return null;
      }
      let change;
      if (parsedDiff.type === "value") {
        change = (
          <Text key={1} size="sm">
            Set interval to every <Code>{parsedDiff.value.quantity}</Code> <Code>{parsedDiff.value.unit}(s)</Code>
          </Text>
        );
      } else {
        change = (
          <Text key={1} size="sm">
            Updated interval to every{" "}
            <Code>
              {newValue.quantity} {newValue.unit}(s)
            </Code>
          </Text>
        );
      }

      return {
        message: <Text>{activity.metadata.user_name} updated the sync interval</Text>,
        icon: <CalendarDaysIcon />,
        changes: [change],
      };
    },
  },
  {
    accessor: "schedule.schedule.expressions",
    parser: (activity, { parsedDiff }) => {
      if (parsedDiff.type !== "array") {
        return null;
      }
      return {
        message: <Text>{activity.metadata.user_name} updated the custom recurrence</Text>,
        icon: <CalendarDaysIcon />,
        // Changes are a bit harder to grok here, we can come back to this if it's really bad
        changes: [
          <Text key={1} size="sm">
            Updated recurrence days and or times
          </Text>,
        ],
      };
    },
  },
];
