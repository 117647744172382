import { useEffect, useState, FC } from "react";

import { useToast } from "@hightouchio/ui";
import { capitalize } from "lodash";
import Helmet from "react-helmet";
import { Image, Text } from "theme-ui";

import { RefreshIntervals } from "src/components/audiences/column-settings";
import { useObject } from "src/components/audiences/use-object";
import { Explore, ExploreSource } from "src/components/explore";
import { QueryTypeSelect } from "src/components/explore/query-type-select";
import { SourceSelect } from "src/components/sources/source-select";
import { useUser } from "src/contexts/user-context";
import {
  useCreateEventMutation,
  useCreateObjectMutation,
  useUpdateModelColumnsMutation,
  useUpdateObjectMutation,
} from "src/graphql";
import { Column, Row } from "src/ui/box";
import { Field } from "src/ui/field";
import { Heading } from "src/ui/heading";
import { Input } from "src/ui/input";
import { Select } from "src/ui/select";
import { Wizard } from "src/ui/wizard";
import { Step } from "src/ui/wizard/wizard";
import { QueryType, useModelRun, useModelState, useQueryState } from "src/utils/models";
import { useNavigate } from "src/utils/navigate";
import { ResourceType, useResourceSlug } from "src/utils/slug";

import labelExampleImage from "./label-example.png";
import labelsImage from "./labels.png";

export const CreateAudienceObject: FC = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { objectPath, objectNameSingular, isParentModel, isEvent } = useObject();
  const { getSlug } = useResourceSlug(ResourceType.Model);

  const [source, setSource] = useState<ExploreSource | undefined>();
  const [step, setStep] = useState(0);
  const { queryState, resetQueryState, setSQL, setTable, setDBTModel, isQueryDefined } = useQueryState();
  const { modelState, setName, setPrimaryKey, setTimestampColumn } = useModelState();
  const [type, setType] = useState<QueryType | undefined>();
  const [primaryLabel, setPrimaryLabel] = useState("");
  const [secondaryLabel, setSecondaryLabel] = useState("");
  const [creating, setCreating] = useState(false);
  const [hasQueryColumns, setHasQueryColumns] = useState(false);

  const { mutateAsync: createObject } = useCreateObjectMutation();
  const { mutateAsync: updateObject } = useUpdateObjectMutation();
  const { mutateAsync: createEvent } = useCreateEventMutation();
  const { mutateAsync: updateModelColumns } = useUpdateModelColumnsMutation();

  const {
    runQuery,
    cancelQuery,
    getSchema,
    rows,
    numRowsWithoutLimit,
    isResultTruncated,
    columns,
    loading: queryLoading,
    error: queryError,
    errorAtLine: queryErrorAtLine,
  } = useModelRun(type, undefined, {
    variables: { sourceId: source?.id, dbtModelId: queryState?.dbtModel?.id, ...queryState },
  });

  const create = async () => {
    setCreating(true);

    const slug = await getSlug(modelState?.name);

    const data = await createObject({
      input: {
        slug,
        is_schema: true,
        query_type: type,
        name: modelState?.name,
        primary_key: modelState?.primaryKey || undefined,
        connection_id: source?.id,
        created_by: user?.id != null ? String(user?.id) : undefined,
        query_dbt_model_id: queryState?.dbtModel?.id,
        query_looker_look_id: queryState?.lookerLook?.id,
        query_table_name: queryState?.table,
        query_raw_sql: queryState?.sql,
        columns: { data: columns },
        destination_instances: { data: [] },
        git_sync_metadata:
          type === "dbt"
            ? {
                git_sync_config_id: queryState?.dbtModel?.git_sync_config?.id,
                file_path: queryState?.dbtModel?.original_file_path,
                dbt_model_id: queryState?.dbtModel?.id,
              }
            : null,
      },
    });

    const id = data.insert_segments_one?.id;

    if (isEvent && id) {
      await createEvent({
        input: {
          model_id: id,
          timestamp_column: modelState?.timestampColumn,
        },
      });
    }

    if (isParentModel) {
      await updateObject({
        id,
        input: {
          visual_query_primary_label: primaryLabel,
          visual_query_secondary_label: secondaryLabel,
        },
      });

      // Enable suggestions with weekly refresh interval by default upon model creation.
      await updateModelColumns({
        id,
        input: {
          top_k_enabled: true,
          top_k_sync_interval: RefreshIntervals.Weekly,
        },
      });
    }

    setCreating(false);

    toast({
      id: `create-${objectPath}`,
      title: `${capitalize(modelState?.name ?? "object")} was created`,
      variant: "success",
    });

    let params = "";
    if (isEvent) {
      params = "?eventCreated=true";
    }

    navigate(`/audiences/setup/${objectPath}${params}`);
  };

  const steps: Step[] = [
    {
      title: "Select source",
      continue: "Click on a source to continue",
      header: <Heading>Select a data source</Heading>,
      render: () => (
        <SourceSelect
          queryType={QueryType.Visual}
          onSelect={(source) => {
            setSource(source);
            setStep((step) => step + 1);
          }}
        />
      ),
    },
    {
      title: "Define model",
      disabled:
        !isQueryDefined(type) || Boolean(queryError) || (source?.definition?.supportsResultSchema ? false : !hasQueryColumns),
      header: type ? null : <Heading>Select a modeling method</Heading>,
      onContinue: async () => {
        if (source?.definition?.supportsResultSchema && !hasQueryColumns) {
          await getSchema();
        }
        setStep((step) => step + 1);
      },
      continue: type ? "" : "Click on a modeling method to continue",
      render: () => (
        <>
          {source && (
            <>
              {type ? (
                <Explore
                  cancelQuery={cancelQuery}
                  columns={columns}
                  isQueryDefined={isQueryDefined}
                  isResultTruncated={Boolean(isResultTruncated)}
                  numRowsWithoutLimit={numRowsWithoutLimit}
                  rows={rows}
                  runQuery={runQuery}
                  source={source}
                  type={type}
                  {...queryState}
                  error={queryError}
                  errorAtLine={queryErrorAtLine}
                  loading={queryLoading}
                  rowsPerPage={15}
                  onDBTModelChange={setDBTModel}
                  onSQLChange={setSQL}
                  onTableChange={setTable}
                  onTypeChange={setType}
                />
              ) : (
                <QueryTypeSelect selected={type} source={source} onChange={setType} />
              )}
            </>
          )}
        </>
      ),
    },
    {
      title: `Finalize ${objectNameSingular}`,
      disabled:
        !modelState?.name || (isParentModel && (!primaryLabel || !secondaryLabel)) || (isEvent && !modelState?.timestampColumn),
      submitting: creating,
      header: <Heading>Finalize settings for this {objectNameSingular}</Heading>,
      render: () => {
        const columnOptions = columns?.map(({ name }) => ({ value: name, label: name }));
        return (
          <>
            <Row sx={{ width: "100%", pb: 8, gap: 8 }}>
              <Column sx={{ flex: 1, mr: 24 }}>
                <Heading sx={{ mb: 8 }} variant="h3">
                  {isParentModel ? "Name and primary key" : isEvent ? "Name and timestamp" : "Name"}
                </Heading>
                <Column sx={{ gap: 8 }}>
                  <Field label="Name">
                    <Input placeholder="Enter a name" value={modelState?.name} onChange={(name) => setName(name)} />
                  </Field>
                  {isParentModel && (
                    <>
                      <Field label="Primary key">
                        <Select
                          options={columnOptions}
                          placeholder="Select a primary key"
                          value={modelState?.primaryKey}
                          onChange={({ value }) => {
                            setPrimaryKey(value);
                          }}
                        />
                      </Field>
                    </>
                  )}
                  {isEvent && (
                    <Field label="Timestamp">
                      <Select
                        options={columnOptions}
                        placeholder="Select a timestamp"
                        value={modelState?.timestampColumn}
                        onChange={({ value }) => setTimestampColumn(value)}
                      />
                    </Field>
                  )}
                </Column>
              </Column>
              <Column sx={{ color: "base.7", maxWidth: "420px", gap: 4 }}>
                <Column>
                  <Text sx={{ fontWeight: "bold" }}>Naming your {objectNameSingular}</Text>
                  <Text>
                    {isParentModel
                      ? "Users of audiences will see names of parent models when deciding which dataset they need to build on top of."
                      : isEvent
                      ? "Users of audiences will see event names when filtering by events."
                      : "Names of related models will be shown when building relationships on parent models."}
                  </Text>
                </Column>
                <Column>
                  <Text sx={{ fontWeight: "bold" }}>Example {objectNameSingular} names:</Text>
                  <ul>
                    {isParentModel ? (
                      <>
                        <li>All users</li>
                        <li>Users in San Francisco</li>
                      </>
                    ) : isEvent ? (
                      <>
                        <li>User signed up</li>
                        <li>Product purchased</li>
                      </>
                    ) : (
                      <>
                        <li>Devices</li>
                        <li>Purchases</li>
                      </>
                    )}
                  </ul>
                </Column>
                {isParentModel && (
                  <>
                    <Column>
                      <Text sx={{ fontWeight: "bold" }}>Primary key</Text>
                      <Text>Choose a primary key that is unique and consistent across queries.</Text>
                    </Column>
                    <Column>
                      <Text sx={{ fontWeight: "bold" }}>Example primary keys:</Text>
                      <ul>
                        <li>id</li>
                        <li>email</li>
                      </ul>
                    </Column>
                  </>
                )}
                {isEvent && (
                  <>
                    <Column>
                      <Text sx={{ fontWeight: "bold" }}>Timestamp</Text>
                      <Text>Choose a column that represents the timestamp of the event.</Text>
                    </Column>
                    <Column>
                      <Text sx={{ fontWeight: "bold" }}>Example timestamps:</Text>
                      <ul>
                        <li>created_at</li>
                        <li>purchased_at</li>
                      </ul>
                    </Column>
                  </>
                )}
              </Column>
            </Row>
            {isParentModel && (
              <Row sx={{ width: "100%", pt: 8, borderTop: "small" }}>
                <Column sx={{ flex: 1, mr: 24 }}>
                  <Heading sx={{ mb: 8 }} variant="h3">
                    Content labels
                  </Heading>

                  <Column sx={{ gap: 8 }}>
                    <Field label="Primary label">
                      <Select
                        options={columnOptions}
                        placeholder="Select a primary label"
                        value={primaryLabel}
                        onChange={({ value }) => {
                          setPrimaryLabel(value);
                        }}
                      />
                    </Field>

                    <Field label="Secondary label">
                      <Select
                        options={columnOptions}
                        placeholder="Select a secondary label"
                        value={secondaryLabel}
                        onChange={({ value }) => {
                          setSecondaryLabel(value);
                        }}
                      />
                    </Field>
                  </Column>
                </Column>
                <Column sx={{ color: "base.7", maxWidth: "420px", gap: 4 }}>
                  <Column>
                    <Text sx={{ fontWeight: "bold" }}>Content labels</Text>
                    <Text>Content labels are used when previewing results from audiences that use this parent model.</Text>
                  </Column>
                  <Column sx={{ py: 4, px: 12, bg: "base.2", borderRadius: 1 }}>
                    <Image src={labelsImage} />
                  </Column>
                  <Text>
                    For example, setting the primary label to the ”full_name” column and the secondary label to the “email”
                    column would yield:
                  </Text>
                  <Column sx={{ py: 4, px: 12, bg: "base.2", borderRadius: 1 }}>
                    <Image src={labelExampleImage} />
                  </Column>
                </Column>
              </Row>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (source) {
      resetQueryState();
    }
  }, [source]);

  useEffect(() => {
    setHasQueryColumns(false);
  }, [queryState]);

  useEffect(() => {
    if (columns?.length && !queryError) {
      setHasQueryColumns(true);
    }
  }, [rows, columns]);

  return (
    <>
      <Helmet>
        <title>New {objectNameSingular}</title>
      </Helmet>

      <Wizard
        setStep={setStep}
        step={step}
        steps={steps}
        title={`New ${objectNameSingular}`}
        onCancel={() => {
          navigate(`/audiences/setup/${objectPath}`);
        }}
        onSubmit={create}
      />
    </>
  );
};
