import { FC } from "react";

import Helmet from "react-helmet";

import { AuthLayout } from "src/components/auth/auth-layout";
import { Warning } from "src/components/warning";

const WorkspaceNotFoundPage: FC = () => {
  return (
    <>
      <Helmet>
        <title>Workspace not found</title>
      </Helmet>

      <AuthLayout>
        <Warning
          linkText="Select a workspace"
          subtitle="The workspace you're looking for may not exist. Please check the url."
          title="Workspace not found"
          to="/workspaces"
        />
      </AuthLayout>
    </>
  );
};

export default WorkspaceNotFoundPage;
