import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { TagIcon, BoltIcon, BoltSlashIcon, TrashIcon, FolderOpenIcon } from "@heroicons/react/24/outline";
import { GlobeAmericasIcon } from "@heroicons/react/24/solid";
import {
  Box,
  Button,
  Column,
  Heading,
  Row,
  SearchInput,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuDivider,
  MenuItem,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import { isEmpty } from "lodash";
import pluralize from "pluralize";
import { Image, Text } from "theme-ui";

import searchPlaceholder from "src/assets/placeholders/search.svg";
import syncPlaceholder from "src/assets/placeholders/sync.svg";
import { DraftBadge } from "src/components/drafts/draft-badge";
import { DraftIcon } from "src/components/drafts/draft-icon";
import {
  createdByFilterConfig,
  destinationFilterConfig,
  Filters,
  labelFilterConfig,
  sourceFilterConfig,
  syncStatusFilterConfig,
  useFilters,
} from "src/components/folders/filters";
import { IndividualFolder } from "src/components/folders/folder";
import { Folders } from "src/components/folders/folder-list";
import { MoveFolder } from "src/components/folders/move-to-folder";
import { useFolderState } from "src/components/folders/use-folder-state";
import { EditLabels } from "src/components/labels/edit-labels";
import { Labels } from "src/components/labels/labels";
import { Page } from "src/components/layout";
import { PageAlert } from "src/components/page-alert";
import placeholderDestination from "src/components/permission/destination.svg";
import { PermissionedLinkButton } from "src/components/permissioned-button";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  MinimalSyncsQuery,
  ResourcePermissionGrant,
  SegmentsBoolExp,
  SyncsBoolExp,
  SyncsOrderBy,
  SyncsWithLabelsQuery,
  useAddLabelsToSyncsMutation,
  useDeleteSyncsMutation,
  useDraftsQuery,
  useMinimalSyncsQuery,
  useSyncFiltersQuery,
  useSyncsCountQuery,
  useSyncsWithLabelsQuery,
  useUpdateSyncsMutation,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import useHasPermission from "src/hooks/use-has-permission";
import useQueryState from "src/hooks/use-query-state";
import * as analytics from "src/lib/analytics";
import { Avatar } from "src/ui/avatar";
import { ObjectBadge } from "src/ui/badge";
import { ExternalLinkIcon, InfoIcon, LabelIcon } from "src/ui/icons";
// import { SearchInput } from "src/ui/input";
import { Link } from "src/ui/link";
import { Modal } from "src/ui/modal";
import { Popout } from "src/ui/popout";
import { Pagination, Table, TableColumn, useTableConfig } from "src/ui/table";
import { Placeholder } from "src/ui/table/placeholder";
import { useRowSelect } from "src/ui/table/use-row-select";
import { TextWithTooltip } from "src/ui/text";
import { useDestinations } from "src/utils/destinations";
import { useIncrementalQuery } from "src/utils/incremental-query";
import { QueryType } from "src/utils/models";
import { useNavigate } from "src/utils/navigate";
import { SyncStatusBadge } from "src/utils/syncs";
import { formatDate, formatDatetime } from "src/utils/time";
import { openUrl } from "src/utils/urls";

import { useLabels } from "../../components/labels/use-labels";

enum SortKeys {
  Status = "status",
  SegmentName = "segment.name",
  DestinationName = "destination.name",
  LastRun = "sync_requests_aggregate.max.created_at",
  CreatedAt = "created_at",
}

const getBulkDeleteSyncMessage = (error: Error): string => {
  return error.message.startsWith("Foreign key violation") && error.message.includes("sync_sequence")
    ? "One or more of the selected syncs cannot be deleted because they are used in sequences"
    : error.message;
};

export const Syncs: FC = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [search, setSearch] = useQueryState("search");
  const [confirmingDelete, setConfirmingDelete] = useState(false);
  const { selectedRows, onRowSelect } = useRowSelect();
  const [loading, setLoading] = useState(true);
  const [processingAction, setProcessingAction] = useState(false);
  const [addingLabels, setAddingLabels] = useState(false);
  const {
    selectedFolder,
    selectedFolderType,
    setSelectedFolder,
    setSelectedFolderType,
    setMovingToFolder,
    movingToFolder,
    header,
    refetchFolders,
    clearFolderType,
  } = useFolderState({ search, resourceType: "syncs" });
  const { hasPermission: userCanDelete } = useHasPermission([{ resource: "source", grants: [ResourcePermissionGrant.Delete] }]);
  const { hasPermission: userCanUpdate } = useHasPermission([{ resource: "source", grants: [ResourcePermissionGrant.Update] }]);

  const { limit, offset, orderBy, page, setPage, onSort } = useTableConfig<SyncsOrderBy>({
    defaultSortKey: "created_at",
    limit: 25,
    sortOptions: Object.values(SortKeys),
  });

  const getHasuraFolderTypeFilter = (): SegmentsBoolExp => {
    const folderIdClause = { _eq: selectedFolder?.id };
    switch (selectedFolderType) {
      case "models":
        return {
          query_type: { _neq: QueryType.Visual },
          is_schema: { _eq: false },
          folder_id: folderIdClause,
        };
      case "audiences":
        return { query_type: { _eq: QueryType.Visual }, folder_id: folderIdClause };
      default:
        return { folder_id: { _eq: null } };
    }
  };

  const { data: syncFilterData } = useSyncFiltersQuery();

  const {
    data: { definitions: destinationDefinitions },
    error: destinationsError,
  } = useDestinations();

  const allSyncs = useMemo(() => {
    return syncFilterData?.syncs?.map((sync) => ({
      ...sync,
      segment: {
        ...sync.segment,
        connection: {
          ...sync.segment?.connection,
        },
      },
    }));
  }, [syncFilterData]);

  const filterDefinitions = useMemo(() => {
    return {
      filters: {
        destinations: { options: destinationFilterConfig(allSyncs || []), title: "Destinations" },
        sources: { options: sourceFilterConfig(allSyncs || []), title: "Sources" },
        status: { options: syncStatusFilterConfig(allSyncs || []), title: "Status" },
        created_by: { options: createdByFilterConfig(allSyncs || []), title: "Created by" },
        labels: { options: labelFilterConfig(allSyncs || []), title: "Labels" },
      },
    };
  }, [allSyncs]);

  const { state: filterState, data: filterData, resetFilters } = useFilters(filterDefinitions);

  const hasuraFolderFilters = getHasuraFolderTypeFilter();

  const buildHasuraFilters = () => {
    const labelsFilters: SegmentsBoolExp["_or"] = filterState["labels"].selected.map((filter) => {
      const key = filter.id.split(":")[0];
      const value = filter.id.split(":")[1];
      const obj = {};
      obj[key!] = value;
      return {
        tags: { _contains: obj },
      };
    });

    const hasuraFilters: SyncsBoolExp =
      (allSyncs?.length || 0) > 0
        ? {
            _and: [
              {
                destination_id: !filterState["destinations"].isAllSelected
                  ? { _in: filterState["destinations"].selected.map((filter) => filter.id) }
                  : {},
                segment: {
                  connection_id: !filterState["sources"].isAllSelected
                    ? { _in: filterState.sources.selected.map((filter) => filter.id) }
                    : {},
                  ...hasuraFolderFilters,
                },
                status: !filterState["status"].isAllSelected
                  ? { _in: filterState["status"].selected.map((filter) => filter.id) }
                  : {},
              },
              !filterState["labels"].isAllSelected
                ? {
                    _or: labelsFilters,
                  }
                : {},
              !filterState["created_by"].isAllSelected
                ? {
                    _or: [
                      {
                        created_by: { _in: filterState.created_by.selected.map((f) => f.id) },
                      },
                      {
                        created_by: { _is_null: true },
                      },
                    ],
                  }
                : {},
            ],
          }
        : {};

    if (search) {
      const searchFilters: SyncsBoolExp[] = [
        { segment: { name: { _ilike: `%${search}%` } } },
        { destination: { name: { _ilike: `%${search}%` } } },
        { destination: { type: { _ilike: `%${search}%` } } },
      ];

      return { _and: [hasuraFilters, { _or: searchFilters }] };
    }

    return hasuraFilters;
  };

  const hasuraFilters = useMemo(() => {
    return buildHasuraFilters();
  }, [search, filterState, selectedFolder, selectedFolderType, filterState]);

  const fullSyncQuery = useSyncsWithLabelsQuery(
    {
      offset,
      limit,
      filters: hasuraFilters,
      orderBy,
    },
    {
      refetchInterval: 10000,
      notifyOnChangeProps: "tracked",
      keepPreviousData: true,
    },
  );

  const incrementalSyncs = useIncrementalQuery<MinimalSyncsQuery, SyncsWithLabelsQuery>(
    useMinimalSyncsQuery(
      {
        offset,
        limit,
        filters: hasuraFilters,
        orderBy,
      },
      {
        notifyOnChangeProps: "tracked",
        keepPreviousData: true,
      },
    ),
    fullSyncQuery,
  );

  const { data: allSyncsCount } = useSyncsCountQuery();

  const { data: modelSyncsCount } = useSyncsCountQuery({
    filters: {
      segment: {
        query_type: { _neq: QueryType.Visual },
        is_schema: { _eq: false },
      },
    },
  });

  const { data: audiencesCount } = useSyncsCountQuery({
    filters: {
      segment: {
        query_type: { _eq: QueryType.Visual },
      },
    },
  });

  const { data: drafts } = useDraftsQuery({
    resourceType: "sync",
    status: "pending",
  });

  const { labels } = useLabels();

  const { mutateAsync: bulkDeleteSyncs, isLoading: loadingBulkDelete } = useDeleteSyncsMutation();
  const { mutateAsync: updateSyncs } = useUpdateSyncsMutation();
  const { mutateAsync: addLabels, isLoading: loadingAddLabels } = useAddLabelsToSyncsMutation();

  const { data: entitlementsData, isLoading: _loadingEntitlements } = useEntitlements(true);
  const { overageLockout, destinationOverageText } = entitlementsData.overage;
  const overageText = destinationOverageText + " To create a sync, upgrade your plan.";
  const audiencesEnabled = entitlementsData.entitlements.audiences;

  const bulkUpdateStatus = async (enabled: boolean) => {
    setProcessingAction(true);

    return await updateSyncs(
      {
        ids: selectedRows.map(String),
        object: {
          schedule_paused: !enabled,
        },
      },
      {
        onSuccess: () => {
          toast({
            id: "bulk-update-syncs",
            title: `Selected syncs were ${enabled ? "enabled" : "disabled"}`,
            variant: "success",
          });

          onRowSelect([]);
          setProcessingAction(false);
          setLoading(true);
        },
        onError: (error) => {
          toast({
            id: "bulk-update-syncs",
            title: error.message,
            variant: "success",
          });

          Sentry.captureException(error);
        },
      },
    );
  };

  const bulkAddLabels = async (labels: Record<string, string>) => {
    const labelCount = Object.keys(labels).length;

    try {
      await addLabels({ ids: selectedRows.map(String), labels });
      setAddingLabels(false);

      toast({
        id: "bulk-add-labels",
        title: `Added ${labelCount} ${pluralize("label", labelCount)} to ${selectedRows.length} ${pluralize(
          "sync",
          selectedRows.length,
        )}`,
        variant: "success",
      });

      onRowSelect([]);
    } catch (error) {
      toast({
        id: "bulk-add-labels",
        title: "Couldn't update labels",
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  const bulkDelete = async () => {
    setProcessingAction(true);

    if (userCanDelete) {
      await bulkDeleteSyncs(
        { ids: selectedRows.map(String) },
        {
          onSuccess: () => {
            toast({
              id: "bulk-delete-syncs",
              title: "Selected syncs were deleted",
              variant: "success",
            });

            onRowSelect([]);
          },
          onError: (error) => {
            toast({
              id: "bulk-delete-syncs",
              title: getBulkDeleteSyncMessage(error),
              variant: "error",
            });

            Sentry.captureException(error);
          },
        },
      );

      setConfirmingDelete(false);
    } else {
      toast({
        id: "bulk-delete-syncs",
        title: "You don't have permissions to delete syncs",
        variant: "error",
      });
    }

    setProcessingAction(false);
  };

  const syncs = incrementalSyncs.data?.syncs;
  const syncsCount = incrementalSyncs.data?.syncs_aggregate?.aggregate?.count ?? 0;

  const selectedSegmentQueryTypes =
    syncs?.filter((sync) => selectedRows.includes(sync.id))?.map((sync) => sync.segment?.query_type) || [];

  const moveToFolderType = selectedFolderType || (selectedSegmentQueryTypes.includes("visual") ? "audiences" : "models");

  const selectedSyncsMixesModelsAndAudiences =
    selectedSegmentQueryTypes.some((type) => type === QueryType.Visual) &&
    selectedSegmentQueryTypes.some((type) => type !== QueryType.Visual);

  const columns = useMemo(
    (): TableColumn[] =>
      [
        {
          name: "Status",
          sortDirection: orderBy?.status,
          onClick: () => onSort(SortKeys.Status),
          min: "130px",
          max: "130px",
          cell: ({ id, status, sync_requests, draft: isInitialDraft }) => {
            if (isInitialDraft) {
              return <DraftBadge />;
            }

            const syncRequest = sync_requests?.[0];
            const request = syncRequest ? syncRequest : { status_computed: status };

            const draft = drafts?.drafts.find((d) => String(d.resource_id) === String(id));
            return (
              <Row sx={{ alignItems: "center" }}>
                <SyncStatusBadge request={request} status={status} />
                {draft && <DraftIcon draft={draft} sx={{ ml: 2 }} />}
              </Row>
            );
          },
        },
        {
          name: "Model",
          sortDirection: orderBy?.segment?.name,
          onClick: () => onSort(SortKeys.SegmentName),
          cell: ({ segment }) => (
            <TextWithTooltip disabled={!segment?.name} sx={{ fontWeight: "semi", maxWidth: "300px" }} text={segment?.name}>
              {segment?.name || "Private model"}
            </TextWithTooltip>
          ),
        },
        {
          name: "Folder",
          cell: ({ segment }) => {
            const { folder } = segment;
            if (folder) {
              return folder.name;
            }
            return "--";
          },
        },
        {
          name: "Destination",
          sortDirection: orderBy?.destination?.name,
          onClick: () => onSort(SortKeys.DestinationName),
          cell: ({ destination, labels }) => {
            const definition = destinationDefinitions?.find((d) => d.type === destination?.type);

            return (
              <Tooltip isDisabled={definition && destination} message="This destination is only visible to some users">
                <Row sx={{ alignItems: "center" }}>
                  <Image
                    alt={definition?.name ?? "Private destination"}
                    src={definition?.icon ?? placeholderDestination}
                    sx={{ width: "20px", maxHeight: "100%", objectFit: "contain", flexShrink: 0, mr: 2 }}
                  />
                  <TextWithTooltip
                    disabled={!destination?.name && !definition?.name}
                    sx={{
                      fontWeight: "semi",
                      maxWidth: "300px",
                    }}
                    text={destination?.name || definition?.name}
                  >
                    {destination?.name || definition?.name || "Private destination"}
                  </TextWithTooltip>
                  {labels &&
                    Object.keys(labels).map((key) => (
                      <ObjectBadge key={key} sx={{ ml: 2 }}>
                        {labels[key]}
                      </ObjectBadge>
                    ))}
                </Row>
              </Tooltip>
            );
          },
        },
        {
          name: "Last run",
          sortDirection: orderBy?.last_run_at,
          onClick: () => onSort(SortKeys.LastRun),
          max: "200px",
          cell: ({ id, last_run_at, sync_requests }) => {
            return (
              <Row align="center" gap={2}>
                {last_run_at && (
                  <>
                    <Text sx={{ fontWeight: "semi" }}>{formatDatetime(last_run_at)}</Text>
                    <Link to={sync_requests && `/syncs/${id}/runs/${sync_requests[0]?.id}`}>
                      <Box sx={{ color: "base.4", ":hover": { color: "secondary" } }}>
                        <ExternalLinkIcon size={14} />
                      </Box>
                    </Link>
                  </>
                )}
              </Row>
            );
          },
        },
        {
          name: "Created at",
          max: "max-content",
          sortDirection: orderBy?.created_at,
          onClick: () => onSort(SortKeys.CreatedAt),
          cell: ({ created_at: timestamp, created_by_user }) => {
            const name = created_by_user?.name;

            if (!name && !timestamp) {
              return <Text sx={{ fontWeight: "semi" }}>-</Text>;
            }

            if (!name) {
              return <Text sx={{ fontWeight: "semi" }}>{formatDate(timestamp)}</Text>;
            }

            return (
              <Row align="center" color="gray.600" gap={1}>
                <Text sx={{ fontWeight: "semi" }}>{formatDate(timestamp)}</Text>
                <Text>&nbsp;by</Text>
                <Avatar name={name} />
              </Row>
            );
          },
        },
        {
          key: "tags",
          cell: (tags) => {
            if (isEmpty(tags)) {
              return null;
            }

            return (
              <Popout
                content={() => <Labels labels={tags} sx={{ maxWidth: "200px" }} />}
                contentSx={{ p: 3, minWidth: "90px" }}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                }}
              >
                <LabelIcon size={16} sx={{ ":hover": { svg: { fill: "primary" } } }} />
              </Popout>
            );
          },
        },
      ].filter(Boolean),
    [destinationDefinitions, orderBy, onSort, drafts],
  );

  const onRowClick = useCallback(({ id }, event) => openUrl(`/syncs/${id}`, navigate, event), [navigate]);

  const placeholder = useMemo(
    () => ({
      image: searchPlaceholder,
      title: "No syncs found",
      error: "Syncs failed to load, please try again.",
    }),
    [],
  );

  const error = Boolean(incrementalSyncs.error || destinationsError);

  useEffect(() => {
    setPage(0);
  }, [hasuraFilters]);

  useEffect(() => {
    onRowSelect([]);
  }, [page]);

  // Both effects are required for handling loading state when using polling query
  useEffect(() => {
    setLoading(true);
    // Provide all dependencies of the query. Make sure dependencies are properly memoized
  }, [limit, offset, orderBy, hasuraFilters]);
  useEffect(() => {
    if (syncs || error) {
      setLoading(false);
    }
  }, [syncs, error]);

  return (
    <PermissionProvider permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Create] }]}>
      <Page
        sidebar={
          <>
            <Row px={5}>
              <SearchInput
                placeholder="Search all syncs..."
                value={search ?? ""}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </Row>
            <Column gap={1}>
              <IndividualFolder
                count={search ? syncs?.length : allSyncsCount?.syncs_aggregate?.aggregate?.count}
                depth={-1}
                icon={<GlobeAmericasIcon color="forest" />}
                isSelected={selectedFolderType === undefined}
                name={search ? "Search results" : "All syncs"}
                setSelectedFolder={setSelectedFolder}
                onClick={() => clearFolderType()}
              />
              <Folders
                audiencesCount={audiencesCount?.syncs_aggregate.aggregate?.count}
                audiencesRootName={audiencesEnabled ? "Audience syncs" : undefined}
                modelsCount={modelSyncsCount?.syncs_aggregate.aggregate?.count}
                modelsRootName="Model syncs"
                refetchFolders={refetchFolders}
                rootFolder={selectedFolderType}
                selectedFolder={selectedFolder}
                setRootFolder={setSelectedFolderType}
                setSelectedFolder={setSelectedFolder}
                viewType="syncs"
              />
            </Column>
            <Filters filters={filterData} resetFilters={resetFilters} />
          </>
        }
        title="Syncs"
      >
        <Row sx={{ alignItems: "center", justifyContent: "space-between", mb: 4, px: 4, gap: 4 }}>
          <Row ml={16} overflow="hidden" sx={{ h2: { overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" } }}>
            <Heading size="xl">{header}</Heading>
          </Row>
          <Row flexShrink={0} gap={3}>
            {selectedRows.length > 0 && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Text as="label" sx={{ display: "flex", alignItems: "center" }}>
                  <Text as="span" sx={{ color: "base.5", mr: 3 }}>{`${pluralize(
                    "sync",
                    selectedRows.length,
                    true,
                  )} selected`}</Text>

                  <Menu>
                    <MenuButton>Actions</MenuButton>

                    <MenuList>
                      {userCanUpdate && (
                        <>
                          <MenuItem
                            icon={FolderOpenIcon}
                            onClick={() => {
                              setMovingToFolder(true);
                            }}
                          >
                            Move to folder
                          </MenuItem>

                          <MenuItem
                            icon={TagIcon}
                            onClick={() => {
                              setAddingLabels(true);
                            }}
                          >
                            Add labels
                          </MenuItem>

                          <MenuItem
                            icon={BoltSlashIcon}
                            isDisabled={processingAction}
                            onClick={() => {
                              bulkUpdateStatus(false);
                            }}
                          >
                            Disable
                          </MenuItem>

                          <MenuItem
                            icon={BoltIcon}
                            isDisabled={processingAction}
                            onClick={() => {
                              bulkUpdateStatus(true);
                            }}
                          >
                            Enable
                          </MenuItem>
                        </>
                      )}

                      {userCanUpdate && userCanDelete && <MenuDivider />}

                      {userCanDelete && (
                        <MenuItem
                          icon={TrashIcon}
                          isDisabled={processingAction}
                          variant="danger"
                          onClick={() => {
                            setConfirmingDelete(true);
                          }}
                        >
                          Delete
                        </MenuItem>
                      )}
                    </MenuList>
                  </Menu>
                </Text>
              </Box>
            )}

            <PermissionedLinkButton
              href="/syncs/new"
              isDisabled={overageLockout}
              permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Create] }]}
              tooltip={overageLockout && overageText}
              variant="primary"
              onClick={() => {
                analytics.track("Add Sync Clicked");
              }}
            >
              Add sync
            </PermissionedLinkButton>
          </Row>
        </Row>
        <Table
          columns={columns}
          data={syncs}
          error={error}
          loading={loading}
          placeholder={placeholder}
          selectedRows={selectedRows}
          onRowClick={onRowClick}
          onSelect={onRowSelect}
        />
        <Pagination count={syncsCount} label="syncs" page={page} rowsPerPage={limit} setPage={setPage} sx={{ pr: 4 }} />

        <Modal
          bodySx={{ borderRadius: 2, pb: 5 }}
          footer={
            <>
              <Button onClick={() => setConfirmingDelete(false)}>Cancel</Button>
              <Button isLoading={loadingBulkDelete} variant="danger" onClick={bulkDelete}>
                Delete
              </Button>
            </>
          }
          header={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <InfoIcon sx={{ color: "red", mr: 3 }} />
              <Heading>
                Delete {pluralize("this", selectedRows.length, false)} {pluralize("Sync", selectedRows.length, false)}?
              </Heading>
            </Box>
          }
          isOpen={confirmingDelete}
          sx={{ borderRadius: 0, width: "600px" }}
          onClose={() => setConfirmingDelete(false)}
        >
          You will lose your sync {pluralize("configuration", selectedRows.length, false)}.
        </Modal>

        <EditLabels
          description="You can label syncs that have similar properties"
          existingLabelOptions={labels}
          hint="Example keys: team, project, region, env."
          isOpen={addingLabels}
          loading={loadingAddLabels}
          saveLabel={`Apply to ${selectedRows.length} ${pluralize("sync", selectedRows.length)}`}
          title="Add labels"
          onClose={() => setAddingLabels(false)}
          onSave={bulkAddLabels}
        />

        {movingToFolder && (
          <MoveFolder
            disabled={selectedSyncsMixesModelsAndAudiences}
            folder={null}
            folderType={moveToFolderType}
            modelIds={selectedRows.map((row) => {
              const sync = syncs?.find((sync) => sync.id === row);
              return sync?.segment?.id;
            })}
            viewType="syncs"
            onClose={() => {
              setMovingToFolder(false);
              fullSyncQuery.refetch();
              onRowSelect([]);
            }}
          />
        )}
      </Page>
    </PermissionProvider>
  );
};

const Loader = () => {
  const { resources } = useUser();

  if (resources?.sync) {
    return <Syncs />;
  }

  let props;

  if (!resources?.source) {
    props = pageAlertProps.source;
  } else if (!resources?.destination) {
    props = pageAlertProps.destination;
  } else if (!resources?.model) {
    props = pageAlertProps.model;
  }

  return (
    <Page fullWidth outsideTopbar={props ? <PageAlert {...props} /> : null} title="Syncs">
      <Heading mb={8} size="xl">
        Syncs
      </Heading>
      <Placeholder
        content={{
          image: syncPlaceholder,
          title: "No syncs in this workspace",
          body: "A sync defines how and when your data will be sent to a destination. Syncs are usually configured by mapping your data to specific fields in the downstream tool.",
          button: props ? null : (
            <PermissionedLinkButton
              href="/syncs/new"
              permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Create] }]}
              variant="primary"
            >
              Add sync
            </PermissionedLinkButton>
          ),
        }}
      />
    </Page>
  );
};

export default Loader;

const pageAlertProps = {
  model: {
    title: "First, you need to configure a model",
    description:
      "Before you can create your first sync, you'll need to define how your data source will be queried. Hightouch supports different modeling methods such as querying with SQL, selecting an existing table, or importing models from tools like Looker and dbt.",
    button: (
      <PermissionedLinkButton
        href="/models/new"
        permissions={[{ resource: "model", grants: [ResourcePermissionGrant.Create] }]}
        variant="primary"
      >
        Configure model
      </PermissionedLinkButton>
    ),
  },
  source: {
    title: "First, you need to configure a data source",
    description:
      "Hightouch must be connected to least one data source before you can create a sync. Your source can be a data warehouse, spreadsheet, or other data system.",
    button: (
      <PermissionedLinkButton
        href="/sources/new"
        permissions={[{ resource: "source", grants: [ResourcePermissionGrant.Create] }]}
        variant="primary"
      >
        Configure data source
      </PermissionedLinkButton>
    ),
  },
  destination: {
    title: "First, you need to configure a destination",
    description:
      "Hightouch must be connected to at least one destination before you can create a sync. Your destination can be chosen from our catalog of 100+ supported tools.",
    button: (
      <PermissionedLinkButton
        href="/destinations/new"
        permissions={[{ resource: "destination", grants: [ResourcePermissionGrant.Create] }]}
        variant="primary"
      >
        Configure destination
      </PermissionedLinkButton>
    ),
  },
};
