import { FC } from "react";

import { Box, Container, Divider, Flex, Image } from "theme-ui";

import { DiagramIllustrations, Popover, Tile, TileDestination } from "src/components/onboarding";
import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Heading } from "src/ui/heading";
import { ModelIcon, PlusSmallIcon } from "src/ui/icons";
import { Spinner } from "src/ui/loading";
import { SourceBadges, SourceIcon } from "src/utils/sources";

import { fontSizes } from "../../../../design";
import { colors } from "../../../../design/colors";
import { pulsatingBorder } from "./animation";
import { OnboardingData, OnboardingLoading } from "./onboarding-reducer";

type Props = {
  onboardingData: OnboardingData;
  onboardingLoading: OnboardingLoading;
  handleChangeClick: (type) => void;
  handleOnboarding: (type) => void;
};

export const DiagramOnboarding: FC<Props> = ({ onboardingData, onboardingLoading, handleOnboarding, handleChangeClick }) => {
  const sourceName = onboardingData.declareSource?.name;
  const destinationName = onboardingData.declareDestination?.name;
  const currentStep = onboardingData.currentStep;
  const emptySource = { definition: onboardingData.declareSource };

  return (
    <>
      <Heading
        sx={{
          my: 10,
          fontSize: fontSizes[5],
          fontWeight: 600,
        }}
        variant="h1"
      >
        Create your first sync
      </Heading>
      <Row sx={{ width: "100%" }}>
        <Container sx={{ maxWidth: "960px", p: 8 }}>
          <Flex
            sx={{
              justifyContent: "space-between",
              fontSize: "16px",
              fontWeight: 500,
              color: colors.base[7],
            }}
          >
            <Box
              sx={{
                border: `2px solid ${colors.base[3]}`,
                width: "40%",
                borderRadius: "8px",
              }}
            >
              <Box
                sx={{
                  borderRadius: "8px 8px 0 0",
                  animation: currentStep === 1 ? pulsatingBorder({ variant: "secondary" }) : undefined,
                }}
              >
                {onboardingLoading.source ? (
                  <TileSpinner height={80} />
                ) : (
                  <Tile
                    active={currentStep === 1}
                    icon={
                      <>
                        <SourceIcon source={(onboardingData.onboardingSource || emptySource) as any} sx={{ width: "36px" }} />
                        <SourceBadges
                          isSampleDataSource={(onboardingData.onboardingSource || emptySource)?.definition?.isSampleDataSource}
                        />
                      </>
                    }
                    popoverContent="connectSource"
                    popoverDisabled={currentStep > 1}
                    popoverIsOpen={currentStep === 1}
                    showChange={true}
                    subtitle={onboardingData?.onboardingSource ? "Source" : ""}
                    title={
                      onboardingData?.onboardingSource?.name ? onboardingData.onboardingSource.name : `Connect ${sourceName}`
                    }
                    onChangeClick={() => handleChangeClick("changeSource")}
                    onClick={() => handleOnboarding("connectSource")}
                  />
                )}
                <Divider sx={{ margin: 0 }} />
              </Box>
              {onboardingLoading.model ? (
                <TileSpinner height={80} />
              ) : (
                <Box
                  sx={{
                    borderRadius: "0 0 8px 8px",
                    animation: currentStep === 2 ? pulsatingBorder({ variant: "secondary" }) : undefined,
                  }}
                >
                  <Tile
                    active={currentStep === 2}
                    icon={
                      onboardingData?.onboardingModel ? (
                        <ModelIcon size={36} sx={{ svg: { fill: "forest" } }} />
                      ) : (
                        <PlusSmallIcon size={24} />
                      )
                    }
                    popoverAsTooltip={currentStep !== 2}
                    popoverContent={currentStep === 2 ? "setupModel" : "modelDisabled"}
                    popoverDisabled={currentStep > 2}
                    popoverIsOpen={currentStep === 2}
                    showChange={currentStep > 2}
                    subtitle={onboardingData?.onboardingModel ? "Model" : undefined}
                    title={onboardingData?.onboardingModel ? onboardingData?.onboardingModel?.name : "Set up model"}
                    onChangeClick={() => handleChangeClick("changeModel")}
                    onClick={() => handleOnboarding("setupModel")}
                  />
                </Box>
              )}
            </Box>
            <Box
              sx={{
                border: `2px solid ${colors.base[3]}`,
                width: "40%",
                borderRadius: "8px",
                animation: currentStep === 3 ? pulsatingBorder({ variant: "secondary" }) : undefined,
              }}
            >
              {onboardingLoading.destination || !destinationName ? (
                <TileSpinner height={162} />
              ) : (
                <TileDestination
                  active={currentStep === 3}
                  disable={false}
                  icon={<Image alt={destinationName} src={onboardingData?.declareDestination?.icon} width="36px" />}
                  popoverContent="connectDestination"
                  popoverDisabled={currentStep !== 3}
                  popoverIsOpen={currentStep === 3}
                  showChange={currentStep > 1}
                  subtitle={onboardingData?.onboardingDestination ? "Destination" : undefined}
                  title={onboardingData?.onboardingDestination?.name || `Connect ${destinationName}`}
                  onChangeClick={() => handleOnboarding("changeDestination")}
                  onClick={() => handleOnboarding("connectDestination")}
                />
              )}
            </Box>
          </Flex>
          <DiagramIllustrations />
          <Row>
            <Flex sx={{ width: "100%", justifyContent: "center", alignItems: "top", mt: 5 }}>
              <Popover
                asTooltip={currentStep !== 4}
                content={currentStep === 4 ? "configureSync" : "syncDisabled"}
                disabled={false}
                isOpen={currentStep === 4}
                placement="top"
              >
                <Button
                  disabled={currentStep !== 4}
                  size="large"
                  sx={{ animation: currentStep === 4 ? pulsatingBorder({ variant: "primary" }) : undefined }}
                  variant={currentStep === 4 ? "primary" : "secondary"}
                  onClick={() => handleOnboarding("createSync")}
                >
                  Configure sync
                </Button>
              </Popover>
            </Flex>
          </Row>
        </Container>
      </Row>
    </>
  );
};

const TileSpinner: FC<{ height: number }> = ({ height }) => (
  <Box
    sx={{
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      flex: "1",
      height: height,
      display: "flex",
    }}
  >
    <Spinner />
  </Box>
);
