import { FC, useState } from "react";

import { PlusIcon } from "@heroicons/react/24/solid";
import { Column, Row, Spinner, Text } from "@hightouchio/ui";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { ResourcePermissionGrant, useUpdateFoldersMutation } from "src/graphql";
import useHasPermission from "src/hooks/use-has-permission";
import { AudienceIcon, ModelIcon } from "src/ui/icons";

import { PermissionedButton } from "../permissioned-button";
import { AddFolder } from "./add-folder";
import { IndividualFolder } from "./folder";
import { Folder, FolderType, FolderViewType } from "./types";
import { useFolders } from "./use-folders";

interface FoldersProps {
  selectedFolder: Folder | null;
  setSelectedFolder: (folder: string | null) => void;
  rootFolder: FolderType | undefined;
  setRootFolder: (type: FolderType | undefined) => void;
  modelsCount?: number;
  audiencesCount?: number;
  viewType: FolderViewType;
  modelsRootName?: string;
  audiencesRootName?: string;
  refetchFolders: () => void;
}

export const Folders: FC<FoldersProps> = ({
  rootFolder,
  selectedFolder,
  setSelectedFolder,
  setRootFolder,
  modelsCount,
  audiencesCount,
  viewType,
  modelsRootName,
  audiencesRootName,
  refetchFolders,
}) => {
  const [addFolderOpen, setAddFolderOpen] = useState(false);

  return (
    <DndProvider backend={HTML5Backend}>
      <Column>
        <Row align="center" height={9} justify="space-between" px={5}>
          <Text fontWeight="semibold" size="sm" textTransform="uppercase">
            Folders
          </Text>
          <PermissionedButton
            icon={PlusIcon}
            permissions={[{ resource: "workspace", grants: [ResourcePermissionGrant.Update] }]}
            size="sm"
            onClick={() => setAddFolderOpen(true)}
          >
            New
          </PermissionedButton>
        </Row>

        {modelsRootName && (
          <RootFolder
            folderType="models"
            icon={<ModelIcon color={rootFolder === "models" ? "gray.900" : "gray.600"} size={18} />}
            name={modelsRootName}
            refetchFolders={refetchFolders}
            rootCount={modelsCount}
            rootFolder={rootFolder}
            selectedFolder={selectedFolder}
            setRootFolder={setRootFolder}
            setSelectedFolder={setSelectedFolder}
            viewType={viewType}
          />
        )}
        {audiencesRootName && (
          <RootFolder
            folderType="audiences"
            icon={<AudienceIcon color={rootFolder === "audiences" ? "gray.900" : "gray.600"} size={18} />}
            name={audiencesRootName}
            refetchFolders={refetchFolders}
            rootCount={audiencesCount}
            rootFolder={rootFolder}
            selectedFolder={selectedFolder}
            setRootFolder={setRootFolder}
            setSelectedFolder={setSelectedFolder}
            viewType={viewType}
          />
        )}
      </Column>
      {addFolderOpen && (
        <AddFolder
          folderType={rootFolder || "models"}
          toggleDisabled={viewType !== "syncs" || !audiencesRootName}
          viewType={viewType}
          onClose={(id) => {
            setAddFolderOpen(false);
            id && setSelectedFolder(id);
          }}
        />
      )}
    </DndProvider>
  );
};

const RootFolder: FC<{
  name: string;
  setSelectedFolder: (folder: string | null) => void;
  setRootFolder: (folder: FolderType | undefined) => void;
  rootFolder: FolderType | undefined;
  viewType: FolderViewType;
  selectedFolder: Folder | null;
  folderType: FolderType;
  refetchFolders: () => void;
  rootCount: number | undefined;
  icon: JSX.Element;
}> = ({
  name,
  rootFolder,
  setSelectedFolder,
  selectedFolder,
  folderType,
  viewType,
  setRootFolder,
  refetchFolders,
  rootCount,
  icon,
}) => {
  const [_isOpen, _setIsOpen] = useState<boolean>(false);
  const isSelected = folderType === rootFolder;
  const isChildSelected = Boolean(selectedFolder && selectedFolder.type === folderType);

  const { nestedFolders: folders, loadingFolders } = useFolders({
    folderType,
    viewType,
  });

  const isOpen = _isOpen || isChildSelected;
  const setIsOpen = (isOpen: boolean) => {
    if (isChildSelected && !isOpen) {
      setSelectedFolder(null);
    }
    _setIsOpen(isOpen);
  };

  return (
    <Column>
      <IndividualFolder
        count={rootCount}
        depth={-1}
        icon={icon}
        isOpen={isOpen}
        isSelected={isSelected && !selectedFolder}
        name={name}
        setIsOpen={setIsOpen}
        setSelectedFolder={setSelectedFolder}
        onClick={() => {
          setRootFolder(folderType);
          setSelectedFolder(null);
          setIsOpen(!isOpen);
        }}
      />
      {loadingFolders && isOpen && <Spinner ml={12} my={1} />}
      {!loadingFolders &&
        isOpen &&
        (!folders?.length ? (
          <Row ml={12} my={1} sx={{ span: { color: "gray.600" } }}>
            <Text>No folders</Text>
          </Row>
        ) : (
          folders.map((folder) => (
            <DraggableFolder
              key={folder.id}
              folder={folder}
              refetchFolders={refetchFolders}
              rootFolder={rootFolder}
              selectedFolder={selectedFolder}
              setSelectedFolder={(folder) => {
                setSelectedFolder(folder);
                setRootFolder(folderType);
              }}
            />
          ))
        ))}
    </Column>
  );
};

export const isChild = (folder: Folder, maybeChild: Folder): boolean => {
  return (
    maybeChild.id === folder.id || maybeChild.parentId === folder.id || folder.children.some((f) => isChild(f, maybeChild))
  );
};

const DraggableFolder = ({
  folder,
  setSelectedFolder,
  selectedFolder,
  refetchFolders,
  rootFolder,
  parentFolder,
}: {
  folder: Folder;
  setSelectedFolder: (folder: string | null) => void;
  selectedFolder: Folder | null;
  refetchFolders: () => void;
  rootFolder?: FolderType;
  parentFolder?: Folder | undefined;
}) => {
  const { hasPermission: userCanUpdate } = useHasPermission([
    { resource: "workspace", grants: [ResourcePermissionGrant.Update] },
  ]);
  const [_isOpen, _setIsOpen] = useState<boolean>(false);
  const isChildSelected = Boolean(selectedFolder && isChild(folder, selectedFolder) && folder.id !== selectedFolder.id);

  const isOpen = _isOpen || isChildSelected;
  const setIsOpen = (isOpen: boolean) => {
    if (isChildSelected && !isOpen) {
      setSelectedFolder(folder.id);
    }
    _setIsOpen(isOpen);
  };

  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: "folder",
      item: { folder },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
      }),
    }),
    [],
  );
  const { mutateAsync: updateFolder } = useUpdateFoldersMutation();
  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: "folder",
    drop: async (item: { folder: Folder }, monitor) => {
      const didDrop = monitor.didDrop();
      const oldParent = item.folder?.parentId;
      // Only process the drop once the highest level drop target has handled it.
      if (didDrop) {
        return;
      }

      // Don't do anything if the folder is dropped on its parent.
      if (folder.id === oldParent) {
        return;
      }

      userCanUpdate &&
        (await updateFolder({
          ids: [item.folder.id],
          object: {
            parent_id: folder.id,
          },
        }));
      refetchFolders();
    },
    canDrop: (item: { folder: Folder }) => {
      if (!userCanUpdate) {
        return false;
      }
      const newParent = folder.id;

      // Don't do anything if the folder is dropped on itself.
      if (newParent === item.folder.id) {
        return false;
      }

      // We can't assign a folder to a current child; that would create a circular reference.
      if (isChild(item.folder, folder)) {
        return false;
      }

      return true;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
  }));

  return (
    <div ref={drop}>
      <div ref={dragRef}>
        <Column key={folder.id} style={{ opacity }}>
          <IndividualFolder
            bg={isOver && canDrop ? "gray.200" : undefined}
            count={folder.count}
            depth={folder.depth - 1}
            folder={folder}
            isOpen={isOpen}
            isSelected={selectedFolder?.id == folder.id}
            name={folder.name}
            parentFolder={parentFolder}
            setIsOpen={setIsOpen}
            setSelectedFolder={setSelectedFolder}
            onClick={() => {
              setSelectedFolder(folder.id);
              setIsOpen(!isOpen);
            }}
          />
          {isOpen && (
            <Column sx={{ width: "100%" }}>
              {folder.children.map((childFolder) => (
                <DraggableFolder
                  key={childFolder.id}
                  folder={childFolder}
                  parentFolder={folder}
                  refetchFolders={refetchFolders}
                  rootFolder={rootFolder}
                  selectedFolder={selectedFolder}
                  setSelectedFolder={setSelectedFolder}
                />
              ))}
            </Column>
          )}
        </Column>
      </div>
    </div>
  );
};
