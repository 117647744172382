import { useEffect, useMemo, useState } from "react";

import { FolderType } from "src/components/folders/types";
import useQueryState from "src/hooks/use-query-state";

import { useFolders } from "./use-folders";

export const useFolderState = ({
  search,
  resourceType,
  folderType,
}: {
  search: string | null;
  resourceType: "models" | "syncs";
  folderType?: FolderType;
}) => {
  const [selectedFolderType, setSelectedFolderType] = useState<FolderType | undefined>(folderType);
  const [movingToFolder, setMovingToFolder] = useState(false);
  const [selectedFolderQuery, setSelectedFolderQuery] = useQueryState("folder");
  const { nestedFolders, refetchFolders, flattenedFolders } = useFolders({
    folderType: selectedFolderType,
    viewType: resourceType,
  });

  const selectedFolder = useMemo(() => {
    if (selectedFolderQuery) {
      return flattenedFolders?.find((folder) => folder.id === selectedFolderQuery) || null;
    }
    return null;
  }, [selectedFolderQuery, flattenedFolders]);

  useEffect(() => {
    if (selectedFolder) {
      setSelectedFolderType(selectedFolder.type as FolderType);
    }
  }, [selectedFolder]);

  const header = useMemo(() => {
    if (search) {
      return `Search results`;
    }
    if (!selectedFolderType) {
      return `All ${resourceType}`;
    }
    if (selectedFolder) {
      return selectedFolder.name;
    }
    if (selectedFolderType === "models") {
      if (resourceType === "models") {
        return "All models";
      }
      return "All model syncs";
    }
    if (selectedFolderType === "audiences") {
      if (resourceType === "models") {
        return "All audiences";
      }
      return "All audience syncs";
    }
    return "";
  }, [search, selectedFolder, selectedFolderType]);

  useEffect(() => {
    if (search) {
      setSelectedFolderType(undefined);
    }
  }, [search]);

  return {
    selectedFolder,
    setSelectedFolder: (folder: string | null) => {
      setSelectedFolderQuery(folder || "");
    },
    movingToFolder,
    setMovingToFolder,
    selectedFolderType,
    setSelectedFolderType,
    header,
    nestedFolders,
    refetchFolders,
    clearFolderType: () => {
      setSelectedFolderType(undefined);
      setSelectedFolderQuery("");
    },
  };
};
